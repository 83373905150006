export const getOption = ({ dataType, currency, current, accumulated }: any) => {
  const unit = dataType === 'cost' ? currency : '';

  const formatNumber = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const month = params[0].name;
        const currentParam = params.find((param: any) => param.seriesName === 'Current');
        const accumulatedParam = params.find((param: any) => param.seriesName === 'Accumulated');
        let tooltipContent = `<b>${month}</b><br/>`;
        if (currentParam) {
          const currentValue =
            dataType === 'cost'
              ? `${unit}${formatNumber(currentParam.value)}`
              : `${currentParam.value} ${currentParam.value > 1 ? 'hours' : 'hour'}`;
          tooltipContent += `${currentParam.seriesName}: ${currentValue}<br/>`;
        }
        if (accumulatedParam) {
          const accumulatedValue =
            dataType === 'cost'
              ? `${unit}${formatNumber(accumulatedParam.value)}`
              : `${accumulatedParam.value} ${accumulatedParam.value > 1 ? 'hours' : 'hour'}`;
          tooltipContent += `${accumulatedParam.seriesName}: ${accumulatedValue}`;
        }
        return tooltipContent;
      },
    },
    xAxis: {
      type: 'category',
      data: current?.map((item: any) => item.monthName),
      axisLabel: {
        formatter: (value: string) => value,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any) => {
          if (dataType === 'cost') {
            const formattedValue = value >= 1000 ? `${Math.floor(value / 1000)}k` : value;
            return `${unit}${formattedValue}`;
          } else {
            const hoursText = value > 1 ? 'hours' : 'hour';
            return `${value} ${hoursText}`;
          }
        },
      },
    },
    grid: {
      height: 210,
      left: 70,
      right: 20,
    },
    series: [
      {
        name: 'Current',
        type: 'bar',
        data: current?.map((item: any) => item.current),
        itemStyle: {
          color: '#4B0082',
        },
      },
      {
        name: 'Accumulated',
        type: 'line',
        data: accumulated?.map((item: any) => item.accumulated),
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: 'rgba(138,43,226,0.3)',
        },
      },
    ],
  };
};
