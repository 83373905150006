export const buttonTheme = {
  variants: {
    naked: {
      borders: 0,
    },
    'data-mind': {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      color: 'white',
      bgColor: '#7071f3',
      _hover: {
        _disabled: {
          bgColor: '#7071f3',
        },
      },
    },
    'data-mind-naked': {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      bgColor: 'transparent',
      color: '#7071f3',
    },
  },
};
