import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Alert, AlertIcon, Box, useToast } from '@chakra-ui/react';
import { ENVIRONMENTS, TOAST_STATUS } from 'src/const';
import { useTranslation } from 'react-i18next';

interface FallbackProps {
  error: Error;
  componentStack?: string;
  resetError?: () => void;
}

function ErrorDescription(props: { message: string | undefined; stack: string | undefined }) {
  return process.env.NODE_ENV === ENVIRONMENTS.Development ? (
    <>
      <Box>▶︎ {props.message}</Box>
      <Box>▶︎ {props.stack}</Box>
    </>
  ) : null;
}

const Fallback = ({ error }: FallbackProps) => {
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    toast({
      status: TOAST_STATUS.Error,
      title: t('general.errors.communication'),
      description: <ErrorDescription message={error?.message} stack={error?.stack} />,
    });

    Sentry.captureException(error);
  }, []);

  return (
    <Box p={2} w="full">
      <Alert status="error" variant="left-accent" borderRadius="lg" p={2}>
        <AlertIcon />
        <Box w="full" fontSize="0.875rem" fontWeight={600}>
          <Box>{t('general.errors.communication')}</Box>
          <ErrorDescription message={error?.message} stack={error?.stack} />
        </Box>
      </Alert>
    </Box>
  );
};

export default Fallback;
