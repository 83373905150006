import React, { useEffect } from 'react';
import { FormLayout } from 'src/pages/auth/components/FormLayout';
import { DMEmailConfirmedIcon } from 'src/components/Icons';
import { Button, HStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { analytics } from 'src/analytics';

export const PasswordChangedPage = () => {
  const navigate = useNavigate();
  const goBackToLogin = () => navigate(locations.public.login);

  useEffect(() => {
    analytics.page({
      name: 'Password changed',
      title: 'DataMind Dashboard 2.0 - Password changed',
    });
  }, []);

  return (
    <FormLayout
      title="auth.resetPassword.changed.heading"
      subtitle="auth.resetPassword.changed.description"
      icon={<DMEmailConfirmedIcon fontSize="4rem" color="health.healthy" mb={6} />}
    >
      <HStack
        justifyContent={{
          base: 'center',
          xl: 'flex-end',
        }}
      >
        <Button variant="data-mind" size="lg" borderRadius="full" onClick={goBackToLogin}>
          <FormattedText label="auth.resetPassword.changed.submit" />
        </Button>
      </HStack>
    </FormLayout>
  );
};
