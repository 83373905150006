import { HEALTH_STATUS, NOT_MONITORED_DAYS, SENSOR_STATUS, SENSOR_TYPE } from 'src/const';
import { AssetSensorsHealthStatus } from 'src/types/assets';

const cutoffDate = new Date();
cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

const isNotMonitored = (asset: any, siteLastVerified: any) => {
  return (
    siteLastVerified &&
    (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate)
  );
};

const isNotMonitoredComponent = (siteLastVerified?: any, asset?: any) => {
  return (
    siteLastVerified &&
    (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate ||
      asset.health_status?.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE)
  );
};


export const getAmountByStatus = (items: any) => {
  return {
    all: items.length,
    [HEALTH_STATUS.CRITICAL]: items.filter((item: any) => item.health_status === HEALTH_STATUS.CRITICAL).length,
    [HEALTH_STATUS.ALARM]: items.filter((item: any) => item.health_status === HEALTH_STATUS.ALARM).length,
    [HEALTH_STATUS.MONITOR]: items.filter((item: any) => item.health_status === HEALTH_STATUS.MONITOR).length,
    [HEALTH_STATUS.HEALTHY]: items.filter((item: any) => item.health_status === HEALTH_STATUS.HEALTHY).length,
    [HEALTH_STATUS.NOT_AVAILABLE]: items.filter((item: any) => item.health_status === HEALTH_STATUS.NOT_AVAILABLE)
      .length,
  };
};

export const getAmountByCamera = (items: any) => {
  return {
    all: items.filter((item: any) =>
      item.sensors_health_status.some((sensor: any) => sensor.sensor_type.toLowerCase() === SENSOR_TYPE.Camera),
    ).length,
  };
};

export const getComponentAmountByCamera = (components: any) => {
  return {
    all: components.filter((component: any) =>
      component.diagnostics.some((diagnostic: any) =>
        diagnostic.sources_info.some((source: any) => source.source_type.toLowerCase() ===  SENSOR_TYPE.Camera)
      )
    ).length,
  };
};

export const getTotalAssetsWithStatusChanged = (assets: any, siteLastVerified?: any) => {
  return {
    all: assets.length,
    changed: assets.filter((asset: any) => asset.health_status_changed === true).length,
    not_monitored: assets.filter((item: any) => isNotMonitored(item, siteLastVerified)).length,
  };
};

export const getTotalComponentWithStatusChanged = (components: any, siteLastVerified?:any, asset?: any) => {
  return {
    all: components.length,
    changed: components.filter((component: any) => component.health_status_changed === true).length,
    not_monitored: components.filter((component: any) => isNotMonitoredComponent(siteLastVerified, asset)).length,
  };
};

export const getSensorsOverallStatus = (sensors: AssetSensorsHealthStatus[]) => {
  if (sensors) {
    for (const sensor of sensors) {
      if (sensor.health_status === SENSOR_STATUS.OFFLINE) {
        return true;
      }
    }
  }

  return false;
};

export enum SENSORS_OVERALL_STATUS {
  Healthy = 'healthy',
  Critical = 'critical',
  Warning = 'warning',
}

export const getSensorsOverallStatusUpdated = (sensors: AssetSensorsHealthStatus[]) => {
  const offlineSensors = [];
  const onlineSensors = [];

  if (sensors) {
    for (const sensor of sensors) {
      if (sensor.health_status === SENSOR_STATUS.OFFLINE) {
        offlineSensors.push(sensor);
      }
      if (sensor.health_status === SENSOR_STATUS.ONLINE) {
        onlineSensors.push(sensor);
      }
    }
  }

  if (offlineSensors.length === 0 && onlineSensors.length > 0) {
    return SENSORS_OVERALL_STATUS.Healthy;
  }

  if (offlineSensors.length >= 1 && onlineSensors.length > 0) {
    return SENSORS_OVERALL_STATUS.Warning;
  }

  return SENSORS_OVERALL_STATUS.Critical;
};

export const sortSensors = (sensors: AssetSensorsHealthStatus[]) => {
  return sensors
    ? [...sensors].sort((a, b) => {
        if (a.last_uptime > b.last_uptime) return -1;
        if (a.last_uptime < b.last_uptime) return 1;
        if (a.sensor_name < b.sensor_name) return -1;
        if (a.sensor_name > b.sensor_name) return 1;
        return 0;
      })
    : [];
};

export const getComponentDiagnostics = (data: any, selectedComponent: any) => {
  return data.map((item: any) => {
    return [
      item[0],
      [
        ...item[1].assets
          .map((asset: any) => {
            return asset.components
              .filter((component: any) => {
                if (selectedComponent) {
                  if (component.component_id === selectedComponent.component_id) {
                    return component;
                  }
                } else {
                  return component;
                }
              })
              .map((component: any) => {
                return component.diagnostics
                  .map((diagnostic: any) => {
                    return {
                      component_name: component.component_name,
                      component_id: component.component_id,
                      component_health_status: component.health_status,
                      ...diagnostic,
                    };
                  })
                  .flat();
              })
              .flat();
          })
          .flat(),
      ],
    ];
  });
};
