import React, { useEffect } from 'react';
import { HEALTH_STATUS, INITIAL_FILTERS } from 'src/const';
import { FilterButton } from 'src/components/filter-bar/components/FilterButton';
import { Flex } from '@chakra-ui/react';

type StatusFilterProps = {
  assetsAmountByStatus?: Record<string, number>;
  selectedStatuses: Record<string, boolean>;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
  setSelectedSensors: (state: string) => void;
};

export const StatusFilter = ({
  assetsAmountByStatus: assetsAmountByStatus,
  selectedStatuses,
  setSelectedStatuses,
  setSelectedSensors,
}: StatusFilterProps) => {
  const onFilterButtonClick = (type: string) => {
    setSelectedSensors('all');
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      [type]: true,
    });
  };

  useEffect(() => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
  }, []);

  return assetsAmountByStatus ? (
    <Flex flexDirection={{ base: 'column', xl: 'row' }}>
      <FilterButton
        active={selectedStatuses.all}
        amount={assetsAmountByStatus.all}
        label="Show all"
        onClick={() => onFilterButtonClick('all')}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.CRITICAL]}
        amount={assetsAmountByStatus[HEALTH_STATUS.CRITICAL]}
        label={HEALTH_STATUS.CRITICAL}
        status={HEALTH_STATUS.CRITICAL}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.CRITICAL)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.ALARM]}
        amount={assetsAmountByStatus[HEALTH_STATUS.ALARM]}
        label={HEALTH_STATUS.ALARM}
        status={HEALTH_STATUS.ALARM}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.ALARM)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.MONITOR]}
        amount={assetsAmountByStatus[HEALTH_STATUS.MONITOR]}
        label={HEALTH_STATUS.MONITOR}
        status={HEALTH_STATUS.MONITOR}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.MONITOR)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.HEALTHY]}
        amount={assetsAmountByStatus[HEALTH_STATUS.HEALTHY]}
        label={HEALTH_STATUS.HEALTHY}
        status={HEALTH_STATUS.HEALTHY}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.HEALTHY)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.NOT_AVAILABLE]}
        amount={assetsAmountByStatus[HEALTH_STATUS.NOT_AVAILABLE]}
        label="Not Monitored"
        status={HEALTH_STATUS.NOT_AVAILABLE}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.NOT_AVAILABLE)}
      />
    </Flex>
  ) : null;
};
