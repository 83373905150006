import React from 'react';
import { Box, HStack, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DMArrowRightIcon, DMBackArrowIcon, DMUsersIcon } from 'src/components/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { UserProfilePage } from 'src/pages/settings/pages/user-management/components/UserProfilePage';
import { UserFaultsPermissions } from 'src/pages/settings/pages/user-management/components/UserFaultsPermissions';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';
import { UserAlertMethod } from 'src/pages/settings/pages/user-management/components/UserAlertMethod';
import { notificationsFeatureFlagSelector } from 'src/app/store';
import { colors } from 'src/theme/foundations/colors';

export const UserPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const user = useSelector((state) => selectCachedUserDataById(state, userId!));
  const notificationsFeatureFlag = useSelector(notificationsFeatureFlagSelector);
  const goBack = () => navigate(-1);

  return (
    <Box width="100%">
      <HStack h="4rem" bgColor="white" px="4rem" spacing={4}>
        <IconButton
          variant="outline"
          aria-label="Back"
          size="sm"
          fontSize="20px"
          icon={<DMBackArrowIcon />}
          borderRadius="full"
          onClick={goBack}
        />
        <Box>
          <DMArrowRightIcon fontSize="1.5rem" />
        </Box>
        <Box>{`${user?.first_name} ${user?.last_name}`}</Box>
      </HStack>

      <Tabs variant="data-mind" h="full" borderRadius="16px" mt={4}>
        <TabList bgColor={colors.DMGray} borderRadius="16px" w="32.5%">
          <Tab px={8}>User profile</Tab>
          <Tab isDisabled px={8}>
            Fault permissions
          </Tab>
          {notificationsFeatureFlag ? <Tab>Alert method</Tab> : null}
        </TabList>

        <TabPanels>
          <TabPanel h="full" p={1} w="full" pt={4}>
            <UserProfilePage />
          </TabPanel>
          <TabPanel h="full" p="2rem">
            <UserFaultsPermissions />
          </TabPanel>
          {notificationsFeatureFlag ? (
            <TabPanel h="full" p={1} w="99%" pt={4}>
              <UserAlertMethod />
            </TabPanel>
          ) : null}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
