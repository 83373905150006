import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Box } from '@chakra-ui/react';

interface CounterProps {
  initialTime: number;
  onReset?: () => void;
}

const Counter = forwardRef(({ initialTime, onReset }: CounterProps, ref) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const resetTimer = useCallback(() => {
    setTimeLeft(initialTime);
    if (onReset) {
      onReset();
    }
  }, [initialTime, onReset]);

  useImperativeHandle(ref, () => ({
    resetTimer,
  }));

  return (
    <Box w="full" textAlign="center" pb={4} pt={2} fontSize="sm">
      {timeLeft > 0 ? (
        <Box color="DMPrimaryColor">Verification code expires in: {formatTime(timeLeft)}</Box>
      ) : (
        <Box color="health.critical">Verification code has expired.</Box>
      )}
    </Box>
  );
});

Counter.displayName = 'Counter';

export default Counter;
