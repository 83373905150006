import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import React from 'react';

export const ShowAllComponentSidebarItem = (props: { onClick: () => void; component: any; selectedComponent: any }) => {
  return (
    <Box onClick={props.onClick}>
      <HStack
        px={4}
        py={3}
        spacing={0}
        bgColor="#fff"
        borderRadius="lg"
        border={props.selectedComponent === null ? '2px solid #7071f3' : '2px solid #fff'}
        cursor="pointer"
        justifyContent="space-between"
        _hover={{
          border:
            props.selectedComponent === null
              ? '2px solid rgba(112, 113, 243, 1)'
              : '2px solid rgba(112, 113, 243, 0.4)',
        }}
      >
        <HStack fontSize="1rem" fontWeight={500} lineHeight={1.1} justifyContent="space-between" width="100%">
          <Box>Show all components</Box>
          <Box color="#12FFE2" background="#01152B" p={2} borderRadius="8px">
            {props.component.length}
          </Box>
        </HStack>
      </HStack>
      <Divider />
    </Box>
  );
};
