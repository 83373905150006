import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, HStack, PinInput, PinInputField, useToast, VStack } from '@chakra-ui/react';
import { locations } from 'src/app/locations';
import { FormattedText } from 'src/components/FormattedText';
import { useSendCodeMutation, useValidateCodeMutation } from 'src/app/api/authApi';
import { OTP_COUNTER_INITIAL_TIME, TOAST_STATUS } from 'src/const';
import Counter from 'src/pages/auth/components/Counter';
import { useLazyGetUsersQuery } from 'src/app/api/userApi';
import { analytics } from 'src/analytics';
import { useAuth } from 'src/providers/AuthProvider';
import * as Sentry from '@sentry/react';

export const CodeVerificationForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const counterRef = useRef<{ resetTimer: () => void }>(null);
  const [searchParams] = useSearchParams();
  const [validateCode, { isLoading: isValidationCode }] = useValidateCodeMutation();
  const [fetchUsers, { isLoading: isLoadingUsers }] = useLazyGetUsersQuery();
  const [sendVerificationCode, { isLoading: isSendingCode }] = useSendCodeMutation();
  const [codeValue, setCodeValue] = useState<string | undefined>('');
  const [selectedMethod, setSelectedMethod] = useState<string>('email');
  const [errorMessage, setErrorMessage] = useState();
  const [errorStatus, setErrorStatus] = useState();

  const handleCounterReset = () => {
    if (counterRef.current) {
      counterRef.current.resetTimer();
    }
  };

  const onSubmit = async () => {
    if (codeValue?.length === 6) {
      validateCode({
        method: selectedMethod,
        code: codeValue,
      })
        .unwrap()
        .then(async () => {
          toast({
            status: TOAST_STATUS.Success,
            title: 'OTP Verified Successfully',
            description: 'Your verification code has been successfully verified. You can proceed with the next steps.',
          });

          if (user) {
            await fetchUsers({ uids: user.uid })
              .unwrap()
              .then(async (data) => {
                const userData = data[0];
                if (userData) {
                  analytics.identify(userData?.email, {
                    name: userData?.displayName,
                    firstName: userData?.first_name,
                    lastName: userData?.last_name,
                    email: userData?.email,
                    id: userData?.user_id,
                    role: userData?.role,
                  });
                }

                navigate(locations.protected.sites.index);
              });
          }
        })
        .catch((e) => {
          if (e?.data?.msg) {
            Sentry.captureException(e);
            setErrorMessage(e?.data?.msg);
          }
          if (e?.data?.verification_status) {
            Sentry.captureException(e);
            setErrorStatus(e?.data?.verification_status);
          }
        });
    }
  };

  const onResend = async () => {
    await sendVerificationCode({ method: selectedMethod }).unwrap();
    handleCounterReset();

    setErrorStatus(undefined);
    setErrorMessage(undefined);

    setCodeValue('');

    toast({
      status: TOAST_STATUS.Success,
      title: 'Your OTP code has been sent!',
      description: 'You’re just one step away from completing the process.',
    });
  };

  const onChange = (value: string) => {
    setCodeValue(value);
  };

  useEffect(() => {
    setSelectedMethod(searchParams.get('method') as string);
  }, []);

  return (
    <Box>
      <VStack spacing={8}>
        <HStack pb={2} spacing={4}>
          <PinInput size="lg" placeholder="*" onChange={onChange} value={codeValue}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
      </VStack>

      {errorStatus === 'expired' ? null : <Counter ref={counterRef} initialTime={OTP_COUNTER_INITIAL_TIME} />}

      {errorMessage ? (
        <Box w="full" textAlign="center" pb={0} pt={0} fontSize="sm">
          <Box color="health.critical">{errorMessage}</Box>
        </Box>
      ) : null}

      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mt={8} justifyContent="space-between">
        <Box w="full" textAlign="center">
          <Button variant="data-mind-naked" onClick={onResend} isLoading={isSendingCode}>
            <FormattedText label="auth.2fa.resend" />
          </Button>
        </Box>

        <Box w="full" textAlign="center" mb={[5, 5, 0]}>
          <Button
            w={['80%', '80%', 'full']}
            type="submit"
            variant="data-mind"
            size="lg"
            borderRadius="full"
            onClick={onSubmit}
            isDisabled={codeValue ? codeValue?.length < 6 : false}
            isLoading={isValidationCode || isLoadingUsers}
          >
            <FormattedText label="auth.2fa.verify" />
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
