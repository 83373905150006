import React from 'react';
import { NotificationsPreferencesAsset, NotificationsPreferencesEventType } from 'src/data/appPreferences';

export const ApplicationPreferencesList = (props: {
  eventType: NotificationsPreferencesEventType;
  callback: (asset: NotificationsPreferencesAsset, index: number) => React.JSX.Element;
}) => {
  const sortBySiteAsset = (a: NotificationsPreferencesAsset, b: NotificationsPreferencesAsset) => {
    if (a.site_name > b.site_name) return 1;
    if (a.site_name < b.site_name) return -1;
    if (a.asset_ui_name > b.asset_ui_name) return 1;
    if (a.asset_ui_name < b.asset_ui_name) return -1;

    return 0;
  };

  return <>{props.eventType?.assets.slice().sort(sortBySiteAsset)?.map(props.callback)}</>;
};
