import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { useSticky } from 'src/hooks/useSticky';
import { css } from '@emotion/react';
import { colors } from 'src/theme/foundations/colors';

interface SitesHeaderProps {
  activeTabIndex?: number;
}

const SitesHeader = ({ activeTabIndex }: SitesHeaderProps) => {
  const isAllAssetsTab = activeTabIndex === 0;
  const [sticky] = useSticky();

  const style = {
    ...sitesHeaderBaseStyle,
    ...(sticky ? sitesHeaderStickyStyle : null),
  };

  return (
    <HStack alignItems="flex-start" justifyContent="space-between" textTransform="capitalize" css={style}>
      <Box pl={6} fontWeight={500} color="#55687D">
        Site name
      </Box>
      <HStack
        alignItems="flex-start"
        textAlign="left"
        fontSize="0.875rem"
        justifyContent="space-between"
        w="67%"
        pr={2}
      >
        <Box color="#55687D" fontWeight={500}>
          Fixed Assets
        </Box>
        {isAllAssetsTab ? (
          <Box color="#55687D" fontWeight={500}>
            Mobile Assets
          </Box>
        ) : null}

        <Box
          w="4.4rem"
          color="white"
          borderRadius="8px"
          background={colors.health.critical}
          textAlign="center"
          fontSize="12px"
        >
          {HEALTH_STATUS.CRITICAL}
        </Box>
        <Box
          w="4.4rem"
          color="black"
          borderRadius="8px"
          background={colors.health.alarm}
          textAlign="center"
          fontSize="12px"
        >
          {HEALTH_STATUS.ALARM}
        </Box>
        <Box
          w="4.4rem"
          color="black"
          borderRadius="8px"
          background={colors.health.monitor}
          textAlign="center"
          fontSize="12px"
        >
          {HEALTH_STATUS.MONITOR}
        </Box>
        <Box
          w="4.4rem"
          color="white"
          borderRadius="8px"
          background={colors.health.healthy}
          textAlign="center"
          fontSize="12px"
        >
          {HEALTH_STATUS.HEALTHY}
        </Box>
        <Box
          color="white"
          borderRadius="8px"
          background={colors.health.notAvailable}
          textAlign="center"
          pl={2}
          pr={2}
          fontSize="12px"
        >
          Not Monitored
        </Box>
      </HStack>
    </HStack>
  );
};

export default SitesHeader;

const sitesHeaderBaseStyle = css`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1rem;
  background-color: #e6e8ec;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const sitesHeaderStickyStyle = css`
  position: fixed;
  top: 7.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 26.25rem 0.5rem 8.5rem;
  background-color: #eaebee;
`;
