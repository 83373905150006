import React from 'react';
import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { HEALTH_STATES, HEALTH_STATUS, SENSOR_TYPE } from 'src/const';
import { FusionEvidenceModal } from 'src/pages/machines/modals/FusionEvidenceModal';
import { isAfter, parseISO } from 'date-fns';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { css } from '@emotion/react';
import { colors } from 'src/theme/foundations/colors';

export const EvidenceRow = ({
  state,
  item,
  cameraSource,
  onFusionTrendsModalClose,
  componentId,
  diagnosticCreatedAt,
  faultType,
}: any) => {
  const diagnosticDate = diagnosticCreatedAt ? parseISO(diagnosticCreatedAt) : new Date();

  if (
    item.source_type.toLowerCase() === SENSOR_TYPE.Camera &&
    isAfter(new Date(diagnosticDate), new Date('2024-04-07'))
  ) {
    return null;
  }

  return (
    <Box fontSize="0.875rem" py={3} px={4} w="full">
      <HStack justifyContent="space-between">
        <HStack>
          <Icon as={state.icon} color={`health.${item.health_status}`} />
          <Box fontWeight={500}>{item.source_type}</Box>
        </HStack>
        <FusionEvidenceModal
          source={item}
          cameraSource={cameraSource}
          onFusionTrendsModalClose={onFusionTrendsModalClose}
          componentId={componentId}
          diagnosticCreatedAt={diagnosticCreatedAt}
          faultType={faultType}
        />
      </HStack>
    </Box>
  );
};

export const FusionEvidenceTable = ({
  sourcesInfo,
  healthStatus,
  componentId,
  onFusionTrendsModalClose,
  diagnosticCreatedAt,
  faultType,
}: any) => {
  const cameraSource = sourcesInfo.find((item: any) => item.source_type.toLowerCase() === SENSOR_TYPE.Camera);

  const renderEvidenceByStatusOrder = (status: string, onFusionTrendsModalClose: () => void) => {
    return sourcesInfo.map((item: any, index: number) => {
      const state = HEALTH_STATES[item.health_status];

      return (
        item.health_status === status && (
          <EvidenceRow
            key={index}
            state={state}
            item={item}
            cameraSource={cameraSource}
            healthStatus={healthStatus}
            onFusionTrendsModalClose={onFusionTrendsModalClose}
            componentId={componentId}
            diagnosticCreatedAt={diagnosticCreatedAt}
            faultType={faultType}
          />
        )
      );
    });
  };

  return (
    <Box borderRightRadius={{ base: 0, xl: 'md' }} p={3} bgColor="#fff" borderLeft="4px solid #E6E8EC">
      <HStack
        h="2.5rem"
        w="full"
        minW={{ base: 'full', xl: '18rem' }}
        px={4}
        borderRadius="full"
        color="white"
        fontWeight="600"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        css={{
          ...(healthStatus === HEALTH_STATUS.CRITICAL ? criticalStyle : null),
          ...(healthStatus === HEALTH_STATUS.ALARM ? alarmStyle : null),
          ...(healthStatus === HEALTH_STATUS.MONITOR ? monitorStyle : null),
          ...(healthStatus === HEALTH_STATUS.HEALTHY ? healthyStyle : null),
          ...(healthStatus === HEALTH_STATUS.NOT_AVAILABLE ? notAvailableStyle : null),
        }}
      >
        <Box>Fusion & Evidence</Box>
        <HealthStatusIndicator type={healthStatus} color="white" />
      </HStack>
      <Box>
        <VStack spacing={0}>
          {renderEvidenceByStatusOrder(HEALTH_STATUS.CRITICAL, onFusionTrendsModalClose)}
          {renderEvidenceByStatusOrder(HEALTH_STATUS.ALARM, onFusionTrendsModalClose)}
          {renderEvidenceByStatusOrder(HEALTH_STATUS.MONITOR, onFusionTrendsModalClose)}
          {renderEvidenceByStatusOrder(HEALTH_STATUS.HEALTHY, onFusionTrendsModalClose)}
          {renderEvidenceByStatusOrder(HEALTH_STATUS.NOT_AVAILABLE, onFusionTrendsModalClose)}
        </VStack>
      </Box>
    </Box>
  );
};

const criticalStyle = css`
  background-color: ${colors.health.critical};
`;

const alarmStyle = css`
  background-color: ${colors.health.alarm};
`;

const monitorStyle = css`
  background-color: ${colors.health.monitor};
`;

const healthyStyle = css`
  background-color: ${colors.health.healthy};
`;

const notAvailableStyle = css`
  background-color: ${colors.health.notAvailable};
`;
