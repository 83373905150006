import React from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, useToast, VStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/providers/AuthProvider';
import { FieldValues, useForm } from 'react-hook-form';
import { locations } from 'src/app/locations';
import { AuthError } from 'firebase/auth';
import { TOAST_STATUS } from 'src/const';
import { mapAuthCodeToMessage } from 'src/utils';

type ResetPasswordFormData = {
  email: string;
};

export const ResetPasswordForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ResetPasswordFormData>();

  const onSubmit = async (values: FieldValues) => {
    const { email } = values;

    if (isValid) {
      try {
        await resetPassword(email.trim());
        navigate(locations.public.sent);
      } catch (error: unknown) {
        const authError = error as AuthError;

        toast({
          status: TOAST_STATUS.Error,
          title: mapAuthCodeToMessage(authError.code),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormControl variant="floating" isInvalid={!!errors.email}>
          <Input
            type="text"
            variant="data-mind-auth"
            placeholder=" "
            {...register('email', {
              required: 'Email is required',
            })}
          />
          <FormErrorMessage fontWeight="medium">{errors.email && errors.email.message}</FormErrorMessage>
          <FormLabel>
            <FormattedText label="auth.login.form.email.label" />
          </FormLabel>
        </FormControl>
      </VStack>

      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mt={8} justifyContent="space-between">
        <Box w="full" textAlign="center">
          <Button variant="data-mind-naked" onClick={() => navigate(locations.public.login)}>
            <FormattedText label="auth.resetPassword.email.form.backLabel" />
          </Button>
        </Box>

        <Box w="full" textAlign="center" mb={[5, 5, 0]}>
          <Button
            w={['80%', '80%', 'full']}
            type="submit"
            variant="data-mind"
            size="lg"
            borderRadius="full"
            isLoading={isSubmitting}
          >
            <FormattedText label="auth.resetPassword.email.form.submit" />
          </Button>
        </Box>
      </Flex>
    </form>
  );
};
