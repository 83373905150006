import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { SENSOR_TYPE, SENSORS_FUSION_SELECTION_FORMAT } from 'src/const';
import { SensorRow } from 'src/features/sensor-fusion/SensorRow';
import { findDifferences } from 'src/features/sensor-fusion/utils';

const SensorFusionCardPlaceholder = ({ message }: { message: string }) => {
  return (
    <Box fontSize="0.875rem" fontWeight={600} color="rgba(2, 29, 61, 0.6)" px={4} pb={4}>
      {message}
    </Box>
  );
};

interface SensorFusionCardProps {
  selectedData: any;
  pinnedData?: any;
  isPinned?: boolean;
  onUnpinSelection?: () => void;
}

export const SensorFusionCard = ({ selectedData, pinnedData, isPinned, onUnpinSelection }: SensorFusionCardProps) => {
  const selectedValues = selectedData?.map((item: any) => ({
    id: item.data.id,
    value: item.y.toFixed(2),
  }));
  const pinnedValues = pinnedData?.map((item: any) => ({
    id: item.data.id,
    value: item.y.toFixed(2),
  }));

  const diffValues = selectedValues && pinnedValues ? findDifferences(selectedValues, pinnedValues) : undefined;

  const topSensors = selectedData?.filter((item: any) =>
    [SENSOR_TYPE.Vibration, SENSOR_TYPE.Vibrations].includes(item.data.sensorType.toLowerCase())
  );

  const middleSensors = selectedData?.filter((item: any) =>
    ['oil-temperature', 'oil temperature', 'pressure', 'temperature', 'temprature'].includes(
      item.data.sensorType.toLowerCase()
    )
  );

  const bottomSensors = selectedData?.filter((item: any) =>
    ['oil-condition', 'oil condition', 'oil-condition', 'oil-quality', 'speed', 'current'].includes(
      item.data.sensorType.toLowerCase()
    )
  );

  return (
    <VStack
      bgColor="white"
      borderRadius="2xl"
      w="full"
      alignItems="flex-start"
      border={isPinned ? '2px dashed black' : '2px solid black'}
    >
      <HStack w="full" justifyContent="space-between" p={4} pt={selectedData ? 4 : 2} pb={0} alignItems="center">
        {selectedData ? (
          <Box fontSize="1rem" fontWeight={600}>
            {format(new Date(selectedData[0].x), SENSORS_FUSION_SELECTION_FORMAT)}
          </Box>
        ) : null}

        {isPinned && selectedData ? (
          <Button
            variant="link"
            colorScheme="DMRadioColorScheme"
            fontSize="0.875rem"
            fontWeight={500}
            onClick={onUnpinSelection}
          >
            Unpin
          </Button>
        ) : null}
      </HStack>

      {selectedData ? (
        <Box fontSize="0.875rem" fontWeight={500} color="rgba(2, 29, 61, 0.6)" px={4}>
          {isPinned ? 'Right-click the graph on a compared date' : 'Left-click the graph on a selected date'}
        </Box>
      ) : null}

      {selectedData ? (
        <VStack w="full" alignItems="flex-start" spacing={0}>
          {diffValues?.length ? (
            <HStack w="full" justifyContent="flex-end" fontSize="0.75rem" py={0.5}>
              <HStack fontSize="0.75rem" fontWeight={600} w="7rem" pr={4} spacing={0} justifyContent="space-between">
                <Box w="3rem" textAlign="right">
                  Val
                </Box>
                <Box w="3rem" color="DMPrimaryColor" textAlign="right">
                  Diff
                </Box>
              </HStack>
            </HStack>
          ) : null}

          {topSensors ? (
            <Box w="full" pb={3} pt={1} px={4}>
              {topSensors.map((point: any, index: number) => (
                <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
              ))}
            </Box>
          ) : null}

          {middleSensors.length ? (
            <Box w="full" bgColor="rgba(2, 29, 61, 0.03)" py={3} px={4}>
              {middleSensors.map((point: any, index: number) => (
                <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
              ))}
            </Box>
          ) : null}

          {bottomSensors ? (
            <Box w="full" py={3} px={4}>
              {bottomSensors.map((point: any, index: number) => (
                <SensorRow isPinned={isPinned} key={index} point={point} diffValues={diffValues} />
              ))}
            </Box>
          ) : null}
        </VStack>
      ) : (
        <>
          {isPinned ? (
            <SensorFusionCardPlaceholder message="Right-click the graph on a date to compare with the selected date" />
          ) : (
            <SensorFusionCardPlaceholder message="Left-click the graph on the selected date to show values" />
          )}
        </>
      )}
    </VStack>
  );
};
