import React from 'react';
import { Box } from '@chakra-ui/react';

export const SummaryHeader = () => {
  return (
    <Box textAlign="center" mb={{ base: 8, xl: 6 }}>
      <Box fontSize="24px" fontWeight={500} mb={1}>
        Summary
      </Box>
    </Box>
  );
};
