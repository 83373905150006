import { Annotations, Layout, LayoutAxis, Shape } from 'plotly.js';
import { MAX_POSSIBLE_DATE_ISO, MIN_POSSIBLE_DATE_ISO, plotlyColors } from 'src/const';
import { HealthStatus } from 'src/types/assets';
import { BeltMisalignmentLevel } from 'src/app/types/sensors';

const baseShape: Partial<Shape> & { showlegend: boolean } = {
  type: 'rect',
  x0: MIN_POSSIBLE_DATE_ISO,
  x1: MAX_POSSIBLE_DATE_ISO,
  layer: 'below',
  showlegend: false,
};

const xaxis: Partial<LayoutAxis> & { rangebreaks?: any } = {
  tickfont: {
    size: 10,
    family: 'Montserrat',
  },
  showgrid: true,
  zeroline: true,
  showline: true,
  mirror: 'ticks',
  gridcolor: 'rgba(2, 29, 61, 0.08)',
  gridwidth: 1,
  linecolor: 'rgba(2, 29, 61, 0.0)',
  linewidth: 1,
  type: 'date',
};

const layout: Partial<Layout> = {
  xaxis,
  yaxis: {
    tickfont: {
      size: 12,
      family: 'Montserrat',
    },
    title: {
      text: 'Distance from belt in mm',
      font: {
        size: 12,
        family: 'Montserrat',
      },
    },
    gridcolor: 'rgba(2, 29, 61, 0.08)',
    gridwidth: 1,
    linecolor: 'rgba(2, 29, 61, 0.0)',
    linewidth: 1,
    mirror: 'ticks',
    showgrid: true,
    zeroline: false,
    showline: true,
    autorange: true,
  },
  margin: {
    t: 32,
    b: 32,
    l: 64,
    r: 16,
  },
  autosize: true,
  showlegend: false,
  legend: { orientation: 'h', xanchor: 'center', x: 0.5, itemwidth: 10 },
};

export const getAnnotations = (text: string): Partial<Annotations>[] => {
  return [
    {
      xref: 'paper',
      yref: 'paper',
      x: -0.001,
      xanchor: 'left',
      y: 1.005,
      yanchor: 'bottom',
      text,
      showarrow: false,
      font: {
        color: '#021d3d',
        family: 'Montserrat',
        size: 13,
      },
      borderpad: 4,
      bgcolor: 'rgba(2, 29, 61, 0.02)',
      bordercolor: 'rgba(2, 29, 61, 0.1)',
      borderwidth: 1,
    },
  ];
};

export const getLeftRollersLayout = (
  dateRange: Array<string>,
  rangeBreaks: Array<any>,
  levels: Record<HealthStatus, BeltMisalignmentLevel>,
  yAxisRange: Array<number>,
  tickValues: Array<number>,
  tickValuesText: Array<string>,
  bufferValue: number
) => ({
  ...layout,
  colorway: plotlyColors,
  xaxis: {
    ...layout.xaxis,
    range: [
      new Date(new Date(dateRange[0]).getTime() - 2 * 3600000).toISOString(),
      new Date(new Date(dateRange[1]).getTime() + 2 * 3600000).toISOString(),
    ],
    rangebreaks: rangeBreaks.map((item: any) => {
      return {
        bounds: [item.start, item.end],
      };
    }),
  },
  yaxis: {
    ...layout.yaxis,
    tickvals: tickValues.map((value: number) => value.toFixed(0)).filter((_, index) => index % 2),
    ticktext: tickValuesText.map((value: string) => parseFloat(value).toFixed(0)).filter((_, index) => index % 2),
  },
  shapes: [
    {
      ...baseShape,
      y0: -Number(levels.healthy.start),
      y1: -yAxisRange[1] - bufferValue,
      fillcolor: 'rgba(88, 166, 92, 0.2)',
      line: {
        color: 'rgba(88, 166, 92, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Healthy threshold',
    },
    {
      ...baseShape,
      y0: -Number(levels.monitor.start),
      y1: -Number(levels.monitor.end),
      fillcolor: 'rgba(241, 191, 66, 0.2)',
      line: {
        color: 'rgba(241, 191, 66, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Monitor threshold',
    },
    {
      ...baseShape,
      y0: -Number(levels.alarm.start),
      y1: -Number(levels.alarm.end),
      fillcolor: 'rgba(255, 137, 27, 0.2)',
      line: {
        color: 'rgba(255, 137, 27, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Alarm threshold',
    },
    {
      ...baseShape,
      y0: yAxisRange[0] + bufferValue,
      y1: -Number(levels.critical.end),
      fillcolor: 'rgba(222, 111, 98, 0.2)',
      line: {
        color: 'rgba(222, 111, 98, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Critical threshold',
    },
  ],
});

export const getRightRollersLayout = (
  dateRange: Array<string>,
  rangeBreaks: Array<any>,
  levels: Record<HealthStatus, BeltMisalignmentLevel>,
  yAxisRange: Array<number>,
  tickValues: Array<number>,
  tickValuesText: Array<string>,
  bufferValue: number
) => ({
  ...layout,
  colorway: plotlyColors.slice().reverse(),
  xaxis: {
    ...layout.xaxis,
    range: [
      new Date(new Date(dateRange[0]).getTime() - 2 * 3600000).toISOString(),
      new Date(new Date(dateRange[1]).getTime() + 2 * 3600000).toISOString(),
    ],
    rangebreaks: rangeBreaks.map((item: any) => {
      return {
        bounds: [item.start, item.end],
      };
    }),
  },
  yaxis: {
    ...layout.yaxis,
    tickvals: tickValues.map((value: number) => value.toFixed(0)).filter((_, index) => index % 2),
    ticktext: tickValuesText.map((value: string) => parseFloat(value).toFixed(0)).filter((_, index) => index % 2),
  },
  shapes: [
    {
      ...baseShape,
      y0: levels.healthy.start,
      y1: yAxisRange[1] + bufferValue,
      fillcolor: 'rgba(88, 166, 92, 0.25)',
      line: {
        color: 'rgba(88, 166, 92, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Healthy threshold',
    },
    {
      ...baseShape,
      y0: levels.monitor.start,
      y1: levels.monitor.end,
      fillcolor: 'rgba(241, 191, 66, 0.25)',
      line: {
        color: 'rgba(241, 191, 66, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Monitor threshold',
    },
    {
      ...baseShape,
      y0: levels.alarm.end,
      y1: levels.alarm.start,
      fillcolor: 'rgba(255, 137, 27, 0.25)',
      line: {
        color: 'rgba(255, 137, 27, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Alarm threshold',
    },
    {
      ...baseShape,
      y0: levels.critical.end,
      y1: yAxisRange[0] - bufferValue,
      fillcolor: 'rgba(222, 111, 98, 0.25)',
      line: {
        color: 'rgba(222, 111, 98, 0.4)',
        width: 1,
      },
      name: 'Belt Drift, Critical threshold',
    },
  ],
});
