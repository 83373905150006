import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery,
  tagTypes: ['Notifications', 'AppPreferences', 'NotificationsDM'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getNotifications: builder.query<any, any>({
      query: () => ({ url: 'notifications' }),
      providesTags: () => [{ type: 'Notifications' }],
    }),

    getNotificationsFlag: builder.query<any, any>({
      query: () => ({ url: 'notifications/enable' }),
    }),

    setNotificationFlag: builder.mutation<any, any>({
      query({ enable }) {
        const searchParams = new URLSearchParams();

        if (enable) {
          searchParams.append('enable', enable.toString());
        }

        return {
          url: `notifications/enable?${searchParams.toString()}`,
          method: 'PUT',
        };
      },
    }),

    clearNotifications: builder.mutation<any, any>({
      query(body) {
        return {
          url: `notifications/clear`,
          method: 'PUT',
          body,
        };
      },
    }),

    readNotifications: builder.mutation<any, any>({
      query(body) {
        return {
          url: `notifications/read?read=true`,
          method: 'PUT',
          body,
        };
      },
    }),

    getAppPreferences: builder.query<any, any>({
      query: () => ({ url: 'users/apps/preferences' }),
      providesTags: () => [{ type: 'AppPreferences' }],
      keepUnusedDataFor: 0,
    }),

    updateAppPreferences: builder.mutation<any, any>({
      query: (body) => {
        return { url: 'users/apps/preferences', method: 'PUT', body };
      },
    }),

    getNotificationsDM: builder.query<any, any>({
      query: ({ uids }) => {
        const searchParams = new URLSearchParams();

        if (uids) {
          searchParams.append('target_user_ids', uids);
        }

        return { url: `users/apps/delivery_methods?${searchParams.toString()}` };
      },
      providesTags: () => [{ type: 'NotificationsDM' }],
      keepUnusedDataFor: 0,
    }),

    updateNotificationsDM: builder.mutation<any, any>({
      query: ({ payload }) => {
        return { url: `users/apps/delivery_methods`, method: 'PUT', body: payload };
      },
    }),
  }),
});

export const {
  useLazyGetNotificationsQuery,
  useLazyGetAppPreferencesQuery,
  useLazyGetNotificationsDMQuery,
  useClearNotificationsMutation,
  useReadNotificationsMutation,
  useUpdateNotificationsDMMutation,
  useUpdateAppPreferencesMutation,
  useGetNotificationsFlagQuery,
  useSetNotificationFlagMutation,
} = notificationApi;
