export enum BI {
  Cost = 'cost',
  Hours = 'hours',
  Time = 'time',
  DownTime = 'downtime',
}

export const BIDefaults = {
  DEFAULT_PERIOD: '1 year',
  DEFAULT_DATA_TYPE: 'Time',
};
