import { BeltMisalignmentDataResponse, RockSizeDataEntity, RockSizeDataResponse } from 'src/app/types/sensors';
import { plotlyColors } from 'src/const';

export const LEFT_ROLLER_NAMES = ['Bottom left roller', 'Top left roller', 'Center left roller'];
export const RIGHT_ROLLER_NAMES = ['Bottom right roller', 'Top right roller', 'Center right roller'];

export const sensorsRmsResponseTransformer = (response: any) => {
  return response.map((traceData: any) => {
    return {
      ...traceData,
      measurements: traceData.measurements.map((measurement: any) => {
        return {
          ...measurement,
          type: 'scattergl',
          sensorType: traceData.sensor_type,
          mode: 'lines',
          hoverinfo: 'none',
          units: traceData.y_units,
        };
      }),
    };
  });
};

export const spectralAccelerationResponseTransformer = (response: any) => {
  return response.map((traceData: any) => {
    return {
      ...traceData,
      measurements: traceData.measurements.map((measurement: any, index: number) => {
        return {
          ...measurement,
          line: {
            color: plotlyColors[index],
          },
          legendgroup: `group${index}`,
          type: 'scattergl',
          mode: 'lines',
          xaxis: 'x',
          yaxis: 'y',
          hovertemplate:
            `<b>${traceData.name}</b>` +
            `<br><b>Measurement time</b>: ${measurement.name}` +
            '<br><b>%{x}Hz</b>: %{y}<extra></extra>',
        };
      }),
    };
  });
};

export const sensorsPiTagsResponseTransformer = (response: any) => {
  return response.map((traceData: any, index: number) => {
    return {
      ...traceData,
      measurements: traceData.measurements.map((measurement: any) => {
        return {
          ...measurement,
          name: traceData.name,
          line: {
            color: plotlyColors[index],
          },
          sensorType: traceData.sensor_type,
          yaxis: `y${index + 1}`,
          type: 'scattergl',
          mode: 'lines',
          hoverinfo: 'none',
        };
      }),
    };
  });
};

export const spectralVelocityResponseTransformer = (response: any) => {
  return response.map((traceData: any) => {
    return {
      ...traceData,
      measurements: traceData.measurements.map((measurement: any, index: number) => {
        return {
          ...measurement,
          line: {
            color: plotlyColors[index],
          },
          legendgroup: `group${index}`,
          showlegend: false,
          type: 'scattergl',
          mode: 'lines',
          xaxis: 'x',
          yaxis: 'y2',
          hovertemplate:
            `<b>${traceData.name}</b>` +
            `<br><b>Measurement time</b>: ${measurement.name}` +
            '<br><b>%{x}Hz</b>: %{y}<extra></extra>',
        };
      }),
    };
  });
};

export const rockSizeResponseTransformer = (response: RockSizeDataResponse) => {
  return response.map((entity: RockSizeDataEntity, index: number) => {
    return {
      ...entity,
      measurements: {
        ...entity.measurements,
        x: entity.measurements.x.map((value: string) => {
          return new Date(value).toISOString();
        }),
        line: {
          color: plotlyColors[index],
        },
        type: 'scatter',
        mode: 'lines',
        name: entity.measurements.name ? `${entity.measurements.name}th percentile` : '',
        hovertemplate: `<b>${entity.name}</b>` + '<br>%{x|%d.%m.%Y %H:%M}' + '<br><b>%{y:.1f}</b><extra></extra>',
      },
    };
  });
};

export const beltMisalignmentResponseTransformer = (response: BeltMisalignmentDataResponse = []) => {
  const transformedResponse = response.map((entity: any, index: number) => {
    const xValues = entity.measurements.x.map((value: string) => new Date(value).toISOString());
    const yValues = LEFT_ROLLER_NAMES.includes(entity.name)
      ? entity.measurements.y.map((value: number | null) => (value === null ? value : -value))
      : [...entity.measurements.y];

    return {
      ...entity,
      max_value: Math.max(...entity.measurements.y),
      min_value: Math.min(...entity.measurements.y),
      measurements: {
        ...entity.measurements,
        customdata: entity.measurements.y,
        x: xValues,
        y: yValues,
        line: {
          color: plotlyColors[index],
        },
        type: 'scatter',
        mode: 'lines',
        name: entity.name,
        hovertemplate:
          `<b>${entity.name}</b>` +
          '<br>%{x|%d.%m.%Y %H:%M}' +
          '<br><b>Average value: </b>%{customdata:.1f}<extra></extra>',
      },
    };
  });

  return transformedResponse;
};
