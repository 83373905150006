export const locations = {
  base: '/',
  public: {
    login: '/login',
    resetPassword: '/reset-password',
    sent: '/reset-password/sent',
    change: '/reset-password/change',
    changed: '/reset-password/changed',
    secondFactorMethod: '/2fa/method',
    secondFactorVerification: '/2fa/verification',
  },
  protected: {
    sites: {
      index: '/sites',
      machines: '/sites/:siteId/machines',
      failures: '/sites/:siteId/machines/:machineId/failures',
      components: '/sites/:siteId/machines/:machineId/components',
      specificComponent: '/sites/:siteId/machines/:machineId/components/:componentId',
    },
    settings: {
      index: '/settings',
      account: '/settings/account',
      userManagement: '/settings/users',
      createUser: '/settings/users/create',
      user: '/settings/users/:userId',
      preferences: '/settings/preferences',
      applicationPreferences: '/settings/application-preferences',
    },
  },
  fallback: '*',
};
