import React from 'react';
import { Box, HStack, Image } from '@chakra-ui/react';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { format } from 'date-fns';
import { REGULAR_DATE_FORMAT } from 'src/const';

interface EvidenceSidebarItemProps {
  diagnostic: any;
  isSelected: boolean;
  onClick: () => void;
}

const EvidenceSidebarItem = ({ diagnostic, onClick, isSelected }: EvidenceSidebarItemProps) => {
  const { health_status: healthStatus, original_diagnostic_created_at } = diagnostic;
  const createdAt = format(new Date(original_diagnostic_created_at), REGULAR_DATE_FORMAT);

  return (
    <HStack
      bgColor="white"
      w="full"
      p={1}
      borderRadius="lg"
      spacing={2}
      border={`2px solid ${isSelected ? '#7071F3' : 'white'}`}
      _hover={{
        cursor: 'pointer',
        borderColor: '#7071F3',
      }}
      transition="border-color 0.2s ease-in-out"
      onClick={onClick}
    >
      <Box>
        <Image src={evidencePlayIcon} h="52px" />
      </Box>
      <Box fontSize="0.75rem">
        <Box fontWeight={600} textTransform="capitalize" color={`health.${healthStatus}`}>
          {healthStatus}
        </Box>
        <Box fontWeight={500}>{createdAt}</Box>
      </Box>
    </HStack>
  );
};

export default EvidenceSidebarItem;
