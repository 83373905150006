import React from 'react';
import background from 'src/assets/videos/background.mp4';

export const VideoBackground = () => {
  return (
    <video autoPlay loop muted className="background-video">
      <source src={background} type="video/mp4" />
    </video>
  );
};
