import React, { useEffect } from 'react';
import { UserProfileForm } from 'src/pages/settings/pages/user-management/components/UserProfileForm';
import { VStack } from '@chakra-ui/react';
import { analytics } from 'src/analytics';

const CreateUserPage = () => {
  useEffect(() => {
    analytics.page({
      name: 'Create User',
      title: 'DataMind Dashboard 2.0 - Create User',
    });
  }, []);

  return (
    <VStack alignItems="flex-start" pt={20} spacing={4}>
      <UserProfileForm />
    </VStack>
  );
};

export default CreateUserPage;
