import React from 'react';
import { NotificationsPreferencesAsset } from 'src/data/appPreferences';
import { Box, HStack } from '@chakra-ui/react';

export const ApplicationPreferencesRow = (props: {
  asset: NotificationsPreferencesAsset;
  callback: (parameter: any, index: number) => React.JSX.Element;
}) => {
  return (
    <HStack
      p={4}
      boxShadow="sm"
      bgColor="white"
      borderRadius="md"
      w="full"
      cursor="pointer"
      _hover={{
        boxShadow: 'md',
      }}
      fontSize="0.875rem"
    >
      <Box minW="16rem">
        <Box fontSize="0.7rem">{props.asset.site_name}</Box>
        <Box fontWeight={700}>{props.asset.asset_ui_name}</Box>
      </Box>
      <HStack justifyContent="flex-start" alignItems="flex-start" w="full" spacing={4}>
        {props.asset?.parameters?.map(props.callback)}
      </HStack>
    </HStack>
  );
};
