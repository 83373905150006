import React from 'react';
import { Box, Center, HStack, Spinner } from '@chakra-ui/react';
import { ComponentFusionTrendChartHead } from 'src/pages/machines/components/ComponentFusionTrendChartHead';
import SelectedDateHeader, { Chart } from 'src/pages/machines/components/Chart';
import DatePicker from 'react-datepicker';
import { FUSION_TRENDS_TYPE, REGULAR_DATE_FORMAT } from 'src/const';
import { DMArrowDownIcon } from 'src/components/Icons';

interface ComponentDiagnosticsProps {
  asset?: any;
  selectedComponent?: any;
  filteredDiagnostics: any;
  activeChartPoint: any;
  onPointClick: any;
  fromDate: any;
  toDate: any;
  setFromDate: any;
  setToDate: any;
  isLoading: boolean;
}

export const ComponentDiagnostics = ({
  asset,
  selectedComponent,
  filteredDiagnostics,
  activeChartPoint,
  onPointClick,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  isLoading,
}: ComponentDiagnosticsProps) => {
  const { asset_ui_name: assetName } = asset || {};

  return (
    <Box
      position="sticky"
      top={{ base: '9.8rem', xl: '9.9375rem', '1.5xl': '9.9375rem' }}
      bgColor="#fff"
      borderBottomRadius="2xl"
      pt={0}
      pb={0}
      mb={4}
    >
      <Box borderBottomRadius="2xl" w="full">
        <Box bgColor="#E6E8EC" borderRadius="2xl" w="full" p={2}>
          <HStack justifyContent="space-between" w="full">
            <ComponentFusionTrendChartHead selectedComponent={selectedComponent} assetName={assetName}>
              <SelectedDateHeader tooltip={activeChartPoint} />
            </ComponentFusionTrendChartHead>
            <HStack fontWeight={600} fontSize="0.875rem" pr={3} spacing={5}>
              <HStack spacing={1} mb={1}>
                <Box>From</Box>
                <HStack spacing={0}>
                  <DatePicker
                    maxDate={new Date()}
                    selected={fromDate}
                    onChange={(date: Date) => setFromDate(date)}
                    dateFormat={REGULAR_DATE_FORMAT}
                  />
                  <DMArrowDownIcon />
                </HStack>
              </HStack>
              <HStack spacing={1} mb={1}>
                <Box>To</Box>
                <HStack spacing={0}>
                  <DatePicker
                    maxDate={new Date()}
                    selected={toDate}
                    onChange={(date: Date) => setToDate(date)}
                    dateFormat={REGULAR_DATE_FORMAT}
                  />
                  <DMArrowDownIcon />
                </HStack>
              </HStack>
            </HStack>
          </HStack>

          <Box bgColor="#ffffff" borderRadius="lg" w="full" p={2}>
            {isLoading ? (
              <Center py="48px">
                <Spinner size="lg" />
              </Center>
            ) : (
              <Box w="full">
                {filteredDiagnostics.length ? (
                  <Chart
                    type={selectedComponent ? FUSION_TRENDS_TYPE.Component : FUSION_TRENDS_TYPE.Machine}
                    diagnostics={filteredDiagnostics}
                    selectedComponent={selectedComponent}
                    onPointClick={onPointClick}
                    activeChartPoint={activeChartPoint}
                  />
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
