import React, { useEffect } from 'react';
import { Box, Center, Heading, Hide, HStack, Show, VStack } from '@chakra-ui/react';
import { AppLogo } from 'src/components/Logo';
import { FormattedText } from 'src/components/FormattedText';
import { analytics } from 'src/analytics';
import { CodeVerificationForm } from 'src/pages/auth/components/CodeVerificationForm';
import { useLazyGetStatusQuery } from 'src/app/api/authApi';
import { useSearchParams } from 'react-router-dom';

export const CodeVerificationPage = () => {
  const [searchParams] = useSearchParams();
  const [fetchAuthStatus, { data: authStatus }] = useLazyGetStatusQuery();

  useEffect(() => {
    analytics.page({
      name: 'Code Verification',
      title: 'DataMind Dashboard 2.0 - Code Verification',
    });
    fetchAuthStatus({});
  }, []);

  return (
    <VStack h="full" justifyContent="center" px={[4, 4, 4, 4, 4, 0]}>
      <Box
        maxW="26.25rem"
        bgColor={{ base: 'white', xl: 'transparent' }}
        borderRadius={{ base: 'lg', xl: 0 }}
        px={{ base: 6, xl: 0 }}
        py={{ base: 12, xl: 0 }}
      >
        <Show below="xl">
          <Center w="full" mb={8}>
            <AppLogo size="184px" />
          </Center>
        </Show>

        <HStack spacing={3}>
          <Heading
            fontSize="1.5rem"
            fontWeight={600}
            textAlign={{ base: 'center', xl: 'left' }}
            w={{ base: 'full', xl: 'auto' }}
          >
            <Show below="xl">
              <FormattedText label="auth.login.headingMobile" />
            </Show>

            <Hide below="xl">
              <FormattedText label="auth.login.heading" />
            </Hide>
          </Heading>

          <Hide below="xl">
            <Box ml={-1} mt="-2px">
              <AppLogo size="164px" />
            </Box>
          </Hide>
        </HStack>

        <Box pb={10} pt={{ base: 2, xl: 6 }} textAlign={{ base: 'center', xl: 'left' }}>
          {authStatus ? (
            <VStack w="full" alignItems="left" spacing={6}>
              <Box>
                <FormattedText
                  label="auth.2fa.description"
                  values={{
                    method: searchParams.get('method') === 'email' ? 'email' : 'phone',
                    methodValue:
                      searchParams.get('method') === 'email' ? authStatus.methods.email : authStatus.methods.sms,
                  }}
                />
              </Box>
              <Box>
                <FormattedText label="auth.2fa.codeIssueMessage" />
              </Box>
            </VStack>
          ) : null}
        </Box>

        <CodeVerificationForm />
      </Box>
    </VStack>
  );
};
