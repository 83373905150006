import { Alert, AlertDescription, Box, HStack, VStack, Tooltip, Text, Image } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { Asset } from 'src/types/assets';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT, SENSORS_DATA_TYPE, NOT_MONITORED_DAYS } from 'src/const';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import { css } from '@emotion/react';
import { locations } from 'src/app/locations';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { colors } from 'src/theme/foundations/colors';

type MachineItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  siteLastVerified?: string;
};

export const MachineItem = ({ item, isRequireAttention, siteLastVerified }: MachineItemProps) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const onMachineItemClick = () => {
    navigate(generatePath(locations.protected.sites.components, { siteId, machineId: item.asset_id }));
  };

  const hasCamera: boolean = item.sensors_health_status.some(
    (sensor: any) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
  );

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored =
    siteLastVerified &&
    (new Date(item.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate);

  return (
    <VStack
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      height="100%"
      p={4}
      spacing={2.5}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={onMachineItemClick}
    >
      <HStack w="full" justifyContent="space-between">
        <Box>
          <Text fontSize="14px" fontWeight={600}>
            {item.asset_ui_name}
          </Text>
          <Text fontSize="0.75rem" color="gray.500">
            Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
          </Text>
        </Box>
        <VStack spacing={1} alignItems="flex-end">
          {isNotMonitored ? (
            <Box
              color="white"
              fontWeight={600}
              borderRadius="8px"
              background="#01152B"
              textAlign="center"
              pl={2}
              pr={2}
              fontSize="10px"
            >
              Not Monitored
            </Box>
          ) : null}
        </VStack>
      </HStack>

      <HStack w="full" justifyContent="space-between" alignItems="center">
        <HStack spacing={2} alignItems="center">
          <HealthStatusIndicator type={item.health_status} />
          {item.health_status_changed && (
            <Tooltip label="Health status changed" borderRadius="md">
              <Text fontSize="10px" color="#01152B" fontWeight={600} whiteSpace="nowrap" pt={0.5}>
                Status Change
              </Text>
            </Tooltip>
          )}
        </HStack>
        <HStack>
          {hasCamera && (
            <Tooltip label="Camera" borderRadius="md">
              <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
            </Tooltip>
          )}
          <MachineSensorsModal
            sensors={item.sensors_health_status}
            title={item.asset_ui_name}
            siteId={siteId!}
            assetId={item.asset_id}
          />
        </HStack>
      </HStack>

      {isRequireAttention && (
        <Alert
          status="error"
          borderRadius="xl"
          px={4}
          py={2}
          w="full"
          css={{
            ...(item.health_status === HEALTH_STATUS.CRITICAL ? criticalStyle : null),
            ...(item.health_status === HEALTH_STATUS.ALARM ? alarmStyle : null),
          }}
        >
          <AlertDescription fontSize="0.875rem">{item.most_severe_diagnostics[0].fault_type}</AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};

const criticalStyle = css`
  background-color: rgba(255, 67, 55, 0.12);
  border: 1px solid ${colors.health.critical};
`;

const alarmStyle = css`
  background-color: rgba(255, 137, 27, 0.12);
  border: 1px solid ${colors.health.alarm};
`;
