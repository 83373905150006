import React from 'react';
import { Box, FormControl, FormErrorMessage, Input, Switch, useColorModeValue } from '@chakra-ui/react';
import { accessObjectByString } from 'src/utils';
import { colors } from 'src/theme/foundations/colors';
import { getSwitchStyles } from 'src/theme/components/toggle';
export const PreferencesInputGeneric = ({
  parameterType,
  value,
  name,
  register,
  required,
  errors,
  validationSchema,
  isDisabled,
}: any) => {
  const error = accessObjectByString(errors, name);
  switch (parameterType) {
    case 'number':
    case 'float':
    case 'int':
      return (
        <FormControl isInvalid={!!error}>
          <Input
            defaultValue={value}
            id={name}
            name={name}
            size="sm"
            isInvalid={!!error}
            required={required}
            textAlign="left"
            placeholder="Enter a number"
            isDisabled={isDisabled}
            {...register(name, { ...validationSchema, pattern: /^-?\d*\.?\d*$/ })}
            sx={{
              borderBottom: '1px solid gray',
              borderLeft: 'none',
              borderRight: 'none',
              borderTop: 'none',
            }}
          />

          {accessObjectByString(errors, name)?.type === 'required' && (
            <FormErrorMessage fontSize="0.7rem">{error?.message}</FormErrorMessage>
          )}
          {accessObjectByString(errors, name)?.type === 'pattern' && (
            <FormErrorMessage fontSize="0.7rem">Enter a valid number</FormErrorMessage>
          )}
        </FormControl>
      );
    case 'string':
    case 'str':
      return (
        <FormControl isInvalid={!!error}>
          <Input
            defaultValue={value}
            id={name}
            name={name}
            size="sm"
            isInvalid={!!error}
            required={required}
            placeholder="Enter a string"
            isDisabled={isDisabled}
            {...register(name, { ...validationSchema, pattern: /^[a-zA-Z0-9_]+$/ })}
          />

          {accessObjectByString(errors, name)?.type === 'required' && (
            <FormErrorMessage fontSize="0.7rem">{error?.message}</FormErrorMessage>
          )}
          {accessObjectByString(errors, name)?.type === 'pattern' && (
            <FormErrorMessage fontSize="0.7rem">Enter a valid string</FormErrorMessage>
          )}
        </FormControl>
      );
    case 'boolean':
    case 'bool':
      return (
        <Box w="full" textAlign="left">
          <Switch
            defaultChecked={Boolean(value)}
            size="sm"
            id={name}
            name={name}
            isDisabled={isDisabled}
            {...register(name, {})}
            sx={getSwitchStyles(isDisabled)}
          />
        </Box>
      );
    default:
      return null;
  }
};
