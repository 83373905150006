import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

export const initializeSentry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['debug'],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    debug: false,
  });
};
