import { inputTheme as Input } from 'src/theme/components/input';
import { buttonTheme as Button } from 'src/theme/components/button';
import { formTheme as Form } from 'src/theme/components/form';
import { tabsTheme as Tabs } from 'src/theme/components/tabs';

export const components = {
  Input,
  Button,
  Form,
  Tabs,
};
