import React from 'react';
import { Box, Divider, HStack } from '@chakra-ui/react';

interface MachineCurrentStatusLegendProps {
  data: Array<any>;
}

export const MachineCurrentStatusLegend = ({ data }: MachineCurrentStatusLegendProps) => {
  return (
    <Box w="full" mr={4}>
      {data
        .slice()
        .map((item: any, index: number) => (
          <Box key={index}>
            <HStack justifyContent="space-between" w="full" px={2}>
              <Box textTransform="capitalize" fontSize="0.75rem" fontWeight={500} lineHeight={10}>
                {item.name === 'na' ? 'Not Monitored' : item.name}
              </Box>
              <Box fontWeight={600} color={item.itemStyle.color}>
                {item.value}
              </Box>
            </HStack>
            {index === data.length - 1 ? null : <Divider borderColor="#B1B9C3" />}
          </Box>
        ))}
    </Box>
  );
};
