import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Select, SelectProps } from '@chakra-ui/react';

interface ExportSelectProps extends SelectProps {
  placeholder?: string;
  values: string[] | undefined;
  register?: any;
  errors?: any;
}

export const ExportSelect = ({ values, placeholder, register, errors, ...rest }: ExportSelectProps) => {
  return (
    <FormControl variant="floating" isInvalid={!!errors.role}>
      <Select
        w="full"
        border={0}
        lineHeight={4.3}
        height="3.5rem"
        bg="white"
        borderBottom="0.125rem solid rgba(112, 113, 243, 0.12)"
        borderBottomRadius={0}
        fontWeight="medium"
        {...register('role', {
          required: 'User role is required',
        })}
        {...rest}
      >
        {values
          ? values.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </option>
            ))
          : null}
      </Select>
      <FormLabel>{placeholder}</FormLabel>
      <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
    </FormControl>
  );
};
