import React from 'react';
import { Box, HStack, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { DMCircleInfo } from 'src/components/Icons';

interface ChartLegendProps {
  measurements: any;
}

export const ChartLegend = ({ measurements }: ChartLegendProps) => {
  return (
    <HStack w="full" justifyContent="center" spacing={2} alignItems="center" py={2}>
      <HStack spacing={4} fontSize="0.75rem">
        {Array.isArray(measurements) ? (
          measurements.map((measurement: any, index: number) => (
            <HStack key={index}>
              <Box w="12px" h="3px" bgColor={measurement.line.color}></Box>
              <Box>{measurement.name}</Box>
            </HStack>
          ))
        ) : (
          <HStack>
            <Box w="12px" h="3px" bgColor={measurements.line.color}></Box>
            <Box>{measurements.name}</Box>
          </HStack>
        )}
      </HStack>

      <Box>
        <Popover trigger="hover">
          <PopoverTrigger>
            <DMCircleInfo fontSize="1.5rem" cursor="pointer" />
          </PopoverTrigger>
          <PopoverContent w="auto">
            <PopoverArrow />
            <HStack py={2} px={4} fontSize="0.75rem">
              <HStack>
                <Box w="6px" h="6px" bgColor="rgba(222, 111, 98, 0.9)" borderRadius="full"></Box>
                <Box>Critical</Box>
              </HStack>
              <HStack>
                <Box w="6px" h="6px" bgColor="rgba(255, 137, 27, 0.9)" borderRadius="full"></Box>
                <Box>Alarm</Box>
              </HStack>
              <HStack>
                <Box w="6px" h="6px" bgColor="rgba(241, 191, 66, 0.9)" borderRadius="full"></Box>
                <Box>Monitor</Box>
              </HStack>
              <HStack>
                <Box w="6px" h="6px" bgColor="rgba(88, 166, 92, 0.9)" borderRadius="full"></Box>
                <Box>Healthy</Box>
              </HStack>
            </HStack>
          </PopoverContent>
        </Popover>
      </Box>
    </HStack>
  );
};
