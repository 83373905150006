import {
  generatePath,
  Link as RouterLink,
  ParamParseKey,
  PathMatch,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Asset } from 'src/types/assets';
import { Box, HStack, IconButton, Link, Text } from '@chakra-ui/react';
import { DMArrowRightIcon, DMBackArrowIcon, DMSettingsIcon, DMSitesIcon } from 'src/components/Icons';
import { MachinesDropdown } from 'src/components/MachinesDropdown';
import React from 'react';
import { locations } from 'src/app/locations';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';

export function LocationBreadcrumbs(props: {
  matchSitesLocation: PathMatch<ParamParseKey<'/sites'>> | null;
  notMobileResolution: boolean;
  matchFailuresLocation: PathMatch<ParamParseKey<'/sites/:siteId/machines/:machineId/failures'>> | null;
  siteName: string | undefined;
  failureComponentsLocationActivated: PathMatch<ParamParseKey<'/sites/:siteId/machines/:machineId/components'>> | null;
  machineId: string | undefined;
  current: string | undefined;
  machines: Asset[];
  assetHealthStatus: string | undefined;
  latestDiagnosticDate: string | undefined;
  machineSensorModal?: React.ReactNode;
  tabIndex?: number;
  matchSettingsPage?: PathMatch<ParamParseKey<'/settings'>> | null;
}) {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const matchMachinesLocation = useMatch(locations.protected.sites.machines);
  const matchFailuresLocation = useMatch(locations.protected.sites.failures);
  const matchComponentsLocation = useMatch(locations.protected.sites.components);
  const isFailureComponentsLocationActivated = matchFailuresLocation || matchComponentsLocation;
  const matchCreateUserSettingsPage = useMatch(locations.protected.settings.createUser);
  const matchUserSettingsPage = useMatch(locations.protected.settings.user);

  const { userId: userIdParam } = useParams();
  const user = useSelector((state) => {
    return selectCachedUserDataById(state, userIdParam!);
  });

  const goBack = () => {
    if (matchMachinesLocation) {
      navigate(locations.protected.sites.index);
    }

    if (isFailureComponentsLocationActivated) {
      navigate(generatePath(locations.protected.sites.machines, { siteId }));
    }
  };

  return (
    <HStack spacing={2} w="full">
      {matchMachinesLocation ? (
        <IconButton
          variant="outline"
          aria-label="Back"
          size="sm"
          fontSize="20px"
          icon={<DMBackArrowIcon />}
          borderRadius="full"
          onClick={goBack}
        />
      ) : null}

      <HStack fontSize="1rem" fontWeight={500}>
        {props.matchSettingsPage ? (
          <>
            <DMSettingsIcon fontSize="1.5rem" />
            <Text>Settings</Text>
          </>
        ) : (
          <>
            <DMSitesIcon fontSize="1.5rem" />
            {siteId ? (
              <Link as={RouterLink} to={generatePath(locations.protected.sites.machines, { siteId: siteId })}>
                <Text noOfLines={1}>{props.siteName}</Text>
              </Link>
            ) : (
              <Text noOfLines={1}>{props.siteName}</Text>
            )}
          </>
        )}
        {matchCreateUserSettingsPage || matchUserSettingsPage ? (
          <>
            <DMArrowRightIcon fontSize="1.5rem" />
            {matchCreateUserSettingsPage ? (
              <Text>Add new user</Text>
            ) : matchUserSettingsPage ? (
              <Text>
                {user?.first_name} {user?.last_name}
              </Text>
            ) : null}
          </>
        ) : null}
      </HStack>

      <Box>
        {props.failureComponentsLocationActivated ? (
          <MachinesDropdown
            machineId={props.machineId}
            current={props.current}
            machines={props.machines}
            assetHealthStatus={props.assetHealthStatus}
            latestDiagnosticDate={props.latestDiagnosticDate}
            tabIndex={props.tabIndex}
          />
        ) : null}
      </Box>

      <Box>{props.machineSensorModal}</Box>
    </HStack>
  );
}
