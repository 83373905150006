import React from 'react';
import { Box } from '@chakra-ui/react';
import { DMArrowRightIcon } from 'src/components/Icons';
import { format } from 'date-fns';

export const ActiveMonthLink = ({ activeMonth }: { activeMonth: string | null }) => {
  return activeMonth ? (
    <>
      <DMArrowRightIcon fontSize="1.2rem" />
      <Box fontWeight={600} fontSize="0.875rem">
        {format(new Date(activeMonth), 'LLL yyyy')}
      </Box>
    </>
  ) : null;
};
