import { AssetsHealthStates } from 'src/types/sites';
import { colors } from 'src/theme/foundations/colors';

export type SummaryDataType = {
  id: AssetsHealthStates;
  label: string;
  value: number;
  color: string;
};

export const summary: SummaryDataType[] = [
  {
    id: 'critical',
    label: 'Critical',
    value: 0,
    color: colors.health.critical,
  },
  {
    id: 'alarm',
    label: 'Alarm',
    value: 0,
    color: colors.health.alarm,
  },
  {
    id: 'monitor',
    label: 'Monitor',
    value: 0,
    color: colors.health.monitor,
  },
  {
    id: 'healthy',
    label: 'Healthy',
    value: 0,
    color: colors.health.healthy,
  },
  {
    id: 'na',
    label: 'Not Monitored',
    value: 0,
    color: colors.health.notAvailable,
  },
];
