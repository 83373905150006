import React from 'react';
import { Box, Flex, HStack, VStack, Text, Hide } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { generatePath } from 'react-router-dom';
import { locations } from 'src/app/locations';

type SitesRowProps = {
  item: any;
  activeTabIndex?: number;
};

export const SitesRow = ({ item, activeTabIndex }: SitesRowProps) => {
  const isAllAssetsTab = activeTabIndex === 0;
  const navigate = useNavigate();
  const totalAssetsAmount = Object.entries(item.assets_health_state).reduce(
    (total: number, item: Array<any>) => total + item[1],
    0
  );

  const onClick = () => {
    navigate(generatePath(locations.protected.sites.machines, { siteId: item.site_id }));
  };

  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      boxShadow="sm"
      bgColor="white"
      p={{ base: 4, md: 4 }}
      w="full"
      gap={{ base: 3, md: 0 }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyContent="space-between"
      onClick={onClick}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      height="56px"
    >
      <HStack w={{ base: 'full', md: 'auto' }} justifyContent={{ base: 'space-between', md: 'flex-start' }}>
        <VStack alignItems="flex-start" spacing={{ base: 1, md: 0 }}>
          <Box fontWeight={500} color="#01152B" fontSize="14px">
            {item.site_name}
          </Box>
          <Box fontSize="0.75rem" color="#55687D" fontWeight={500}>
            Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
          </Box>
        </VStack>
      </HStack>

      <HStack w="66%" alignItems="flex-start" textAlign="center" justifyContent="space-between">
        <Hide below="xl">
          <Box pr={4} w="4.4rem" textAlign="center">
            <Text fontSize="14px" fontWeight={500}>
              {totalAssetsAmount}
            </Text>
          </Box>
        </Hide>
        {isAllAssetsTab ? (
          <Hide below="xl">
            <Box pl={2} w="4.4rem" textAlign="center">
              <Text fontSize="14px" fontWeight={500}>
                0
              </Text>
            </Box>
          </Hide>
        ) : null}

        <Box w="4.4rem" textAlign="center" pl={isAllAssetsTab ? 10 : undefined}>
          <Text fontSize="14px" fontWeight={500}>
            {item.assets_health_state.critical}
          </Text>
        </Box>

        <Box pl={isAllAssetsTab ? 10 : 2} w="4.4rem" textAlign="center">
          <Text fontSize="14px" fontWeight={500}>
            {item.assets_health_state.alarm}
          </Text>
        </Box>

        <Box pl={isAllAssetsTab ? 10 : 4} w="4.4rem" textAlign="center" mr={5}>
          <Text fontSize="14px" fontWeight={500}>
            {item.assets_health_state.monitor}
          </Text>
        </Box>

        <Box pr={3} w="4.4rem" textAlign="center" mr={6}>
          <Text fontSize="14px" fontWeight={500}>
            {item.assets_health_state.healthy}
          </Text>
        </Box>

        <Box pr={3} w="4.4rem" textAlign="center">
          <Text fontSize="14px" fontWeight={500}>
            {item.assets_health_state.na}
          </Text>
        </Box>
      </HStack>
    </Flex>
  );
};
