import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { SensorFusionCard } from 'src/features/sensor-fusion/SensorFusionCard';

interface SensorFusionLegendProps {
  currentSelection: any;
  pinSelection: any;
  onUnpinSelection: () => void;
}

export const SensorFusionLegend = ({ currentSelection, pinSelection, onUnpinSelection }: SensorFusionLegendProps) => {
  return (
    <VStack
      alignItems="flex-start"
      bgColor="#E6E8EC"
      px={2}
      borderRadius="2xl"
      maxW="22rem"
      minW="22rem"
      height="calc(100vh - 208px)"
      borderY="8px solid #E6E8EC"
    >
      <Box py={2} px={4} fontWeight={600} fontSize="0.875rem" lineHeight="1.5rem" bgColor="#E6E8EC">
        Graph Legend
      </Box>
      <VStack overflowY="auto">
        <SensorFusionCard selectedData={currentSelection} pinnedData={pinSelection} />
        <SensorFusionCard selectedData={pinSelection} isPinned onUnpinSelection={onUnpinSelection} />
      </VStack>
    </VStack>
  );
};
