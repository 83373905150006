import React from 'react';
import { Box, BoxProps, HStack, Icon } from '@chakra-ui/react';
import { HEALTH_STATES } from 'src/const';
import { FormattedText } from 'src/components/FormattedText';

interface HealthStatusIndicatorProps extends BoxProps {
  type: string;
  showDescription?: boolean;
  color?: string;
}

export const HealthStatusIndicator = ({
  type,
  color,
  showDescription = false,
  ...rest
}: HealthStatusIndicatorProps) => {
  const state = HEALTH_STATES[type];

  return (
    <Box {...rest}>
      <HStack color={color || `health.${type}`} spacing={1.5}>
        <Box fontSize="0.75rem" fontWeight={600} textTransform="capitalize">
          <FormattedText label={state.title} />
        </Box>
      </HStack>

      {showDescription ? (
        <Box textStyle="body2" pt={1}>
          <FormattedText label={state.description} />
        </Box>
      ) : null}
    </Box>
  );
};
