import React from 'react';
import { Box, Center, Heading, VStack } from '@chakra-ui/react';
import { AppLogo } from 'src/components/Logo';

export function NoMatchPage() {
  return (
    <Center h="100vh">
      <VStack spacing={24}>
        <Box>
          <AppLogo size="250px" />
        </Box>

        <VStack>
          <Box>
            <Heading size="3xl">404</Heading>
          </Box>
          <Box>
            <Heading size="lg">Page Not Found</Heading>
          </Box>
          <Box fontSize={24}>It looks like the page you&apos;re looking for doesn&apos;t exist.</Box>
        </VStack>
      </VStack>
    </Center>
  );
}
