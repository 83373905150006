import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { MMMM_DD_YYYY_FORMAT } from 'src/const';

export const MachineCurrentStatusTitle = ({ currentDate }: { currentDate?: string }) => {
  return (
    <VStack align="start" pl={4} pt={4} fontSize="0.75rem" gap={0}>
      <Box fontWeight={600}>Current status</Box>
      {currentDate && (
        <Box fontWeight={500} color="#55687D">
          {format(new Date(currentDate), MMMM_DD_YYYY_FORMAT)}
        </Box>
      )}
    </VStack>
  );
};
