import { ToastProviderProps } from '@chakra-ui/react';

export const toastOptions: ToastProviderProps = {
  defaultOptions: {
    duration: 3000,
    isClosable: true,
    position: 'bottom-left',
    variant: 'left-accent',
  },
};
