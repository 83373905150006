import React from 'react';
import { DMEnergyIcon } from 'src/components/Icons';
import { Box, HStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';

type EnergySavingProps = {
  value?: string | number;
};

export const EnergySaving = ({ value = '48:34' }: EnergySavingProps) => {
  return (
    <HStack>
      <DMEnergyIcon fontSize="1.5rem" />
      <Box fontSize="0.75rem" fontWeight={500}>
        <FormattedText label="general.filterBar.energySaving" />
      </Box>
      <Box fontSize="0.875rem" fontWeight={600}>
        {value} hr
      </Box>
    </HStack>
  );
};
