import React from 'react';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Tag, Text, VStack, Tooltip, Image } from '@chakra-ui/react';
import { DMArrowRightIcon, DMMachineIcon } from 'src/components/Icons';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { StackProps } from '@chakra-ui/layout/dist/stack/stack';
import { Asset } from 'src/types/assets';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { HEALTH_STATUS, NOT_MONITORED_DAYS, REGULAR_DATE_FORMAT, SENSORS_DATA_TYPE } from 'src/const';
import { format } from 'date-fns';
import { colors } from 'src/theme/foundations/colors';
import { getSiteById } from 'src/app/queries';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { truncateAssetName } from 'src/utils';

interface MachinesDropdownProps extends StackProps {
  current?: string;
  machineId?: string;
  machines: Asset[];
  assetHealthStatus?: string;
  latestDiagnosticDate?: string;
  tabIndex?: number;
}

const DropdownHealthAndDate = ({
  assetHealthStatus,
  latestDiagnosticDate,
  isOpen,
}: {
  assetHealthStatus: string | undefined;
  latestDiagnosticDate: string | undefined;
  isOpen: boolean;
}) => (
  <Tag
    bgColor={isOpen ? colors.DMWhite : `health.${assetHealthStatus}`}
    borderRadius="full"
    fontSize="12px"
    fontWeight={600}
    color={isOpen ? colors.DMPrimaryColor : colors.DMWhite}
    textTransform="capitalize"
  >
    {assetHealthStatus?.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE ? 'Not Monitored' : assetHealthStatus}{' '}
    {latestDiagnosticDate ? format(new Date(latestDiagnosticDate), REGULAR_DATE_FORMAT) : null}
  </Tag>
);

const LatestDiagnosticTag = ({ assetHealthStatus }: { assetHealthStatus: string | undefined }) => (
  <Box
    fontSize="12px"
    fontWeight={600}
    color={`health.${assetHealthStatus === HEALTH_STATUS.NOT_AVAILABLE ? 'notAvailable' : assetHealthStatus}`}
    textTransform="capitalize"
    pt={1}
    pb={1}
  >
    {assetHealthStatus?.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE ? 'Not Monitored' : assetHealthStatus}
  </Box>
);

const AssetStatus = ({ asset, hasCamera }: { asset: Asset; hasCamera: boolean }) => (
  <HStack justifyContent="space-between" w="full">
    <HStack spacing={2}>
      <LatestDiagnosticTag assetHealthStatus={asset.health_status} />
      {asset.health_status_changed && (
        <Tooltip label="Health status changed" borderRadius="md">
          <Text fontSize="10px" color="#01152B" fontWeight={600} whiteSpace="nowrap" pt={0.5}>
            Status Change
          </Text>
        </Tooltip>
      )}
    </HStack>
    {hasCamera && (
      <Tooltip label="Camera" borderRadius="md">
        <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
      </Tooltip>
    )}
  </HStack>
);

const AssetName = ({ assetName, isNotMonitored }: { assetName: string; isNotMonitored: any }) => (
  <HStack justifyContent="space-between" w="full">
    <Box
      fontSize="0.875rem"
      fontWeight={500}
      maxWidth="200px"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {truncateAssetName(assetName, isNotMonitored)}
    </Box>
    {isNotMonitored && (
      <Box
        color="white"
        fontWeight={600}
        borderRadius="8px"
        background="#01152B"
        textAlign="center"
        pl={2}
        pr={2}
        fontSize="10px"
      >
        Not Monitored
      </Box>
    )}
  </HStack>
);

const MachineMenuItem = ({
  asset,
  machineId,
  siteLastVerified,
  goToMachine,
  isLastItem,
}: {
  asset: Asset;
  machineId?: string;
  siteLastVerified: string;
  goToMachine: (machineId: string) => void;
  isLastItem: boolean;
}) => {
  const hasCamera = asset.sensors_health_status.some(
    (sensor) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
  );

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored =
    siteLastVerified &&
    (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate);

  return (
    <MenuItem
      key={asset.asset_id}
      onClick={() => goToMachine(asset.asset_id)}
      bg={asset.asset_name === machineId ? 'gray.100' : 'transparent'}
      _hover={{ bg: asset.asset_name === machineId ? 'gray.200' : 'gray.200' }}
      borderBottom={isLastItem ? '' : '1px solid #B1B9C3'}
      w="300px"
    >
      <VStack align="start" spacing={1} w="full" width="full">
        <AssetName assetName={asset.asset_ui_name} isNotMonitored={isNotMonitored} />
        <AssetStatus asset={asset} hasCamera={hasCamera} />
      </VStack>
    </MenuItem>
  );
};

export const MachinesDropdown = ({
  current,
  machineId,
  machines = [],
  assetHealthStatus,
  latestDiagnosticDate,
  tabIndex,
  ...rest
}: MachinesDropdownProps) => {
  const { siteId } = useParams<string>();
  const { site } = getSiteById(siteId);
  const siteLastVerified = site?.latest_diagnostic_created_at;

  const navigate = useNavigate();

  const goToMachine = (machineId: string) =>
    navigate(generatePath(locations.protected.sites.components, { siteId, machineId }));

  return (
    <HStack {...rest}>
      <Box>
        <DMArrowRightIcon fontSize="1.5rem" color="#55687D" />
      </Box>

      <Menu isLazy>
        {({ isOpen }) => (
          <>
            <MenuButton
              w="full"
              bg={isOpen ? colors.DMPrimaryColor : 'transparent'}
              color={isOpen ? colors.DMWhite : colors.black}
              p={1}
              borderRadius="8px"
            >
              <HStack w="full" spacing={0} alignItems="center" pl={1} pr={1}>
                <DMMachineIcon fontSize="1.5rem" />
                <Box fontSize="1rem" lineHeight={1.1} fontWeight={500} position="relative" ml={1}>
                  <Text noOfLines={1}>{current}</Text>
                </Box>
                {isOpen ? (
                  <ChevronUpIcon fontSize="1.5rem" ml={1} mr={1} />
                ) : (
                  <ChevronDownIcon fontSize="1.5rem" ml={1} mr={1} />
                )}
                <DropdownHealthAndDate
                  assetHealthStatus={assetHealthStatus}
                  latestDiagnosticDate={latestDiagnosticDate}
                  isOpen={isOpen}
                />
              </HStack>
            </MenuButton>

            <MenuList css={{ maxHeight: '24.5rem', overflowY: 'auto' }}>
              {[...machines]
                .sort((a: Asset, b: Asset) => a.asset_ui_name.localeCompare(b.asset_ui_name))
                .map((asset: any, index: number) => (
                  <MachineMenuItem
                    key={asset.asset_id}
                    asset={asset}
                    machineId={machineId}
                    siteLastVerified={siteLastVerified}
                    goToMachine={goToMachine}
                    isLastItem={index === machines.length - 1}
                  />
                ))}
            </MenuList>
          </>
        )}
      </Menu>
    </HStack>
  );
};
