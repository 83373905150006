import React from 'react';
import { Box } from '@chakra-ui/react';

export const MachineStatusChartTitle = ({
  activeMonth,
  onClick,
}: {
  activeMonth: string | null;
  onClick: () => void;
}) => {
  const hoverStyle = activeMonth
    ? {
        cursor: 'pointer',
        textDecoration: 'underline',
      }
    : undefined;

  return (
    <Box fontWeight={600} fontSize="0.875rem" onClick={onClick} _hover={hoverStyle} py={1}>
      {activeMonth ? 'Daily overview of site health' : 'Monthly overview of site health'}
    </Box>
  );
};
