import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBoolean,
} from '@chakra-ui/react';
import { DMArrowDownIcon, DMArrowUpIcon, DMPlusCircleIcon } from 'src/components/Icons';
import { ComponentPreviewModal } from 'src/pages/machines/modals/ComponentPreviewModal';
import { StackProps } from '@chakra-ui/layout/dist/stack/stack';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { FusionEvidenceTable } from 'src/pages/machines/components/failure-row/components/FusionEvidenceTable';
import { ActionsList } from 'src/pages/machines/components/failure-row/components/ActionsList';
import { CausesList } from 'src/pages/machines/components/failure-row/components/CausesList';
import { FUSION_TRENDS_TYPE, HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { MachineFusionTrend } from 'src/pages/machines/modals/MachineFusionTrend';
import { format } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from 'src/components/Fallback';

interface MachineFailureRowProps extends StackProps {
  item: any;
  isHiddenButtons?: boolean;
  noShadow?: boolean;
  isComponentView?: boolean;
  onFusionTrendsModalClose?: () => void;
}

export const CausesActions = (props: {
  healthStatus: HEALTH_STATUS;
  possibleCauses: any;
  diagnosticId: any;
  prescriptiveActions: any;
}) => {
  return (
    <Flex
      flexDirection={{ base: 'column', xl: 'row' }}
      h="100%"
      w="full"
      p={{ base: 0, xl: 4 }}
      alignItems="flex-start"
      justifyContent="stretch"
      gap={8}
    >
      {props.healthStatus === HEALTH_STATUS.HEALTHY || props.healthStatus === HEALTH_STATUS.NOT_AVAILABLE ? null : (
        <>
          <CausesList possibleCauses={props.possibleCauses} diagnosticId={props.diagnosticId} />
          <ActionsList prescriptiveActions={props.prescriptiveActions} diagnosticId={props.diagnosticId} />
        </>
      )}
    </Flex>
  );
};

export const FailureHeader = (props: {
  isOpen: boolean;
  setFlag: { on: () => void; off: () => void; toggle: () => void };
  componentName: any;
  faultType: any;
  hiddenButtons: boolean | undefined;
  componentId: any;
  healthStatus: any;
  isComponentView?: boolean;
  createdAt: string;
}) => {
  return (
    <HStack
      minH={{ base: '4rem', xl: '4rem' }}
      w="full"
      bgColor="#F0F2F7"
      borderRadius="lg"
      py={{ base: 2, xl: 3 }}
      px={{ base: 2, xl: 4 }}
      justifyContent="space-between"
    >
      <HStack spacing={{ base: 2, xl: 4 }} onClick={props.setFlag.toggle} w="full">
        <Show below="xl">
          <IconButton aria-label="toggle" variant="unstyled">
            {props.isOpen ? <DMArrowUpIcon fontSize="1.5rem" /> : <DMArrowDownIcon fontSize="1.5rem" />}
          </IconButton>
        </Show>
        <Hide>
          <ComponentPreviewModal />
        </Hide>

        {props.isComponentView ? (
          <Box>
            <Box fontWeight={600} color="black" fontSize="14px">
              {props.componentName}
            </Box>
            <Box fontWeight={600} color={`health.${props.healthStatus}`} fontSize="14px">
              {props.faultType}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box fontWeight={600}>{props.componentName}</Box>
            <Box fontSize="0.875rem" color={`health.${props.healthStatus}`}>
              {props.faultType}
            </Box>
          </Box>
        )}
      </HStack>

      {props.hiddenButtons ? null : (
        <HStack>
          <Hide below="xl">
            {props.isComponentView ? null : (
              <ButtonNaked
                label="Create work notification"
                leftIcon={<DMPlusCircleIcon fontSize="1.5rem" />}
                isDisabled
              />
            )}
          </Hide>

          <Hide below="xl">
            {props.isComponentView ? null : (
              <ErrorBoundary FallbackComponent={Fallback}>
                <MachineFusionTrend
                  title="Component fusion trend"
                  type={FUSION_TRENDS_TYPE.Component}
                  componentId={props.componentId}
                  componentName={props.componentName}
                />
              </ErrorBoundary>
            )}
          </Hide>

          <Hide below="xl">
            {props.isComponentView ? (
              <Box fontSize="0.875rem" minW="12rem">
                Last recorded: {format(new Date(props.createdAt), REGULAR_DATE_FORMAT)}
              </Box>
            ) : null}
          </Hide>
        </HStack>
      )}
    </HStack>
  );
};

export const MachineFailureRow = ({
  item,
  isHiddenButtons,
  noShadow,
  isComponentView = false,
  onFusionTrendsModalClose,
  ...rest
}: MachineFailureRowProps) => {
  const [isOpen, setFlag] = useBoolean();
  return (
    <HStack
      bgColor="white"
      borderRadius="lg"
      boxShadow={noShadow ? undefined : 'sm'}
      w="full"
      alignItems="stretch"
      spacing={0}
      {...rest}
    >
      <Box w="full">
        <Box p={4} pb={0}>
          <FailureHeader
            isOpen={isOpen}
            setFlag={setFlag}
            componentName={item.component_name}
            faultType={item.fault_type}
            healthStatus={item.health_status}
            hiddenButtons={isHiddenButtons}
            componentId={item.component_id}
            isComponentView={isComponentView}
            createdAt={item.diagnostic_created_at}
          />
        </Box>

        <Show below="xl">
          {isOpen ? (
            <>
              <Divider />
              <Tabs isFitted variant="data-mind" h="full">
                <TabList>
                  <Tab>Cause & action</Tab>
                  <Tab>Fusion & evidence</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel p={4}>
                    <CausesActions
                      healthStatus={item.health_status}
                      possibleCauses={item.possible_causes}
                      diagnosticId={item.diagnostic_id}
                      prescriptiveActions={item.prescriptive_actions}
                    />
                  </TabPanel>
                  <TabPanel p={0}>
                    <FusionEvidenceTable healthStatus={item.health_status} sourcesInfo={item.sources_info} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          ) : null}
        </Show>

        <Hide below="xl">
          <CausesActions
            healthStatus={item.health_status}
            possibleCauses={item.possible_causes}
            diagnosticId={item.diagnostic_id}
            prescriptiveActions={item.prescriptive_actions}
          />
        </Hide>
      </Box>

      <Hide below="xl">
        <FusionEvidenceTable
          healthStatus={item.health_status}
          sourcesInfo={item.sources_info}
          componentId={item.component_id}
          onFusionTrendsModalClose={onFusionTrendsModalClose}
          diagnosticCreatedAt={item.diagnostic_created_at}
          faultType={item.fault_type}
        />
      </Hide>
    </HStack>
  );
};
