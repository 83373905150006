import { Box, VStack } from '@chakra-ui/react';
import React from 'react';

export const ComponentsSidebarContent = (props: {
  components: any;
  element: (component: any, index: number) => JSX.Element;
}) => {
  return (
    <VStack spacing={2} px={2} alignItems="stretch">
      {props.components && props.components.map(props.element)}
    </VStack>
  );
};
