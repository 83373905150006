import React from 'react';
import { HEALTH_STATUS } from 'src/const';
import { Box, VStack } from '@chakra-ui/react';
import { HealthStatusIndicator } from 'src/components/StateIndicator';

export const LegendContent = () => {
  return (
    <Box>
      <VStack alignItems="flex-start" spacing={4}>
        <HealthStatusIndicator type={HEALTH_STATUS.CRITICAL} showDescription />
        <HealthStatusIndicator type={HEALTH_STATUS.ALARM} showDescription />
        <HealthStatusIndicator type={HEALTH_STATUS.MONITOR} showDescription />
        <HealthStatusIndicator type={HEALTH_STATUS.HEALTHY} showDescription />
        <HealthStatusIndicator type={HEALTH_STATUS.NOT_AVAILABLE} showDescription />
      </VStack>
    </Box>
  );
};
