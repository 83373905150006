import { Box, Divider, HStack, VStack, Tooltip, Image, Text } from '@chakra-ui/react';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import React from 'react';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { SENSORS_DATA_TYPE, NOT_MONITORED_DAYS, HEALTH_STATUS } from 'src/const';

export const ComponentSidebarItem = (props: {
  onClick: () => void;
  component: any;
  isComponentHasCameraSensors?: boolean;
  componentExists?: boolean;
  siteLastVerified?: string;
  assetHealthStatus?: string;
  assetLatestDiagnosticDate?: any;
}) => {
  const hasCameraSource = props.component.diagnostics.some((diagnostic: any) =>
    diagnostic.sources_info.some((source: any) => source.source_type.toLowerCase() === SENSORS_DATA_TYPE.Camera)
  );

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isSiteLastVerifiedOlderThanCutoff = props.siteLastVerified
    ? new Date(props.siteLastVerified) < cutoffDate
    : false;

  const isNotMonitored =
    props.siteLastVerified &&
    (new Date(props.assetLatestDiagnosticDate) < new Date(props.siteLastVerified) ||
      new Date(props.siteLastVerified) < cutoffDate);

  return (
    <Box onClick={props.onClick}>
      <HStack
        px={4}
        py={3}
        spacing={4}
        bgColor="#fff"
        borderRadius="lg"
        border={props.component.selected ? '2px solid #7071f3' : '2px solid #fff'}
        cursor="pointer"
        justifyContent="space-between"
        _hover={{
          border: props.component.selected ? '2px solid rgba(112, 113, 243, 1)' : '2px solid rgba(112, 113, 243, 0.4)',
        }}
      >
        <VStack alignItems="flex-start" spacing={1} w="full">
          <HStack fontSize="14px" fontWeight={500} mb={1} lineHeight={1.1} w="full" justifyContent="space-between">
            <Box>{props.component.component_name}</Box>
            {(!props.componentExists ||
              isSiteLastVerifiedOlderThanCutoff ||
              props.assetHealthStatus?.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE ||
              isNotMonitored) && (
              <Box
                color="white"
                fontWeight={600}
                borderRadius="8px"
                background="#01152B"
                textAlign="center"
                fontSize="10px"
                px={2}
                py={1}
                whiteSpace="nowrap"
              >
                Not Monitored
              </Box>
            )}
          </HStack>
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between">
            <HStack spacing={2} alignItems="center">
              {props.component.health_status && <HealthStatusIndicator type={props.component.health_status} />}
              {props.component.health_status_changed && (
                <Tooltip label="Health status changed" borderRadius="md">
                  <Text fontSize="10px" color="#01152B" fontWeight={600} whiteSpace="nowrap">
                    Status Change
                  </Text>
                </Tooltip>
              )}
            </HStack>
            {hasCameraSource && (
              <Tooltip label="Camera" borderRadius="md">
                <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
              </Tooltip>
            )}
          </HStack>
        </VStack>
      </HStack>
      <Divider />
    </Box>
  );
};
