import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Show,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMInfoCircleIcon } from 'src/components/Icons';
import { LegendContent } from 'src/components/legend/components/LegendContent';
import { FormattedText } from 'src/components/FormattedText';
import { analytics } from 'src/analytics';

export const LegendPopover = () => {
  const navigate = useNavigate();

  const onCloseHandler = () => navigate(-1);
  const onIconButtonClick = () => analytics.track('Legend popover opened', {});

  return (
    <Popover placement="right" onClose={onCloseHandler}>
      <PopoverTrigger>
        <NavLink to="#legend">
          <HStack spacing={0}>
            <Tooltip label="Health Trend Legend" placement="right" borderRadius="md">
              <IconButton
                aria-label="Info"
                size="lg"
                variant="unstyled"
                onClick={onIconButtonClick}
                icon={<DMInfoCircleIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
              />
            </Tooltip>
            <Show below="sm">
              <Text>Health Trend Legend</Text>
            </Show>
          </HStack>
        </NavLink>
      </PopoverTrigger>

      <PopoverContent w="458px" color="#000" bottom={4} left={3}>
        <PopoverCloseButton top={6} right={6} />
        <PopoverBody p={6}>
          <Box textStyle="subtitleBold" mb={6} textTransform="capitalize">
            <FormattedText label="general.legend.title" />
          </Box>

          <LegendContent />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
