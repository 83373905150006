import React, { Suspense, useEffect, useState } from 'react';
import { Box, Button, Center, HStack, Select, Spinner, Switch, useToast, VStack, Hide } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { useLazyGetPreferencesQuery, useUpdatePreferencesMutation } from 'src/app/api/userApi';
import { MANAGEMENT_ROLE, TOAST_STATUS } from 'src/const';
import { useDispatch, useSelector } from 'react-redux';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { analytics } from 'src/analytics';
import { useAuth } from 'src/providers/AuthProvider';
import { selectCachedUserDataById } from 'src/app/queries';
import { setNotificationFeatureFlag } from 'src/app/slices/applicationSlice';
import { notificationsFeatureFlagSelector } from 'src/app/store';
import { colors } from 'src/theme/foundations/colors';
import { getSwitchStyles } from 'src/theme/components/toggle';
import { Radio, RadioGroup } from '@chakra-ui/react';

export const PreferencesPage = () => {
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));
  const toast = useToast();
  const dispatch = useDispatch();

  const notificationsFeatureFlagValue = useSelector(notificationsFeatureFlagSelector);

  const [fetchUserPreferences, { data: userPreferences, isLoading, isFetching }] = useLazyGetPreferencesQuery();
  const [updatePreferences, { isLoading: isUpdating }] = useUpdatePreferencesMutation();

  const [minimumPublishStatusState, setMinimumPublishStatusState] = useState<any>();
  const [enableDisableState, setEnableDisableState] = useState<any>();
  const [frequencyState, setFrequencyState] = useState<any>();

  const [publishStatus, setPublishStatus] = useState<string | number>();
  const [notificationsStatus, setNotificationsStatus] = useState<boolean>(notificationsFeatureFlagValue);

  useEffect(() => {
    setPublishStatus(userPreferences?.minimum_publish_status);
  }, [userPreferences]);

  const onSubmit = async () => {
    try {
      await updatePreferences({
        minimum_publish_status: publishStatus,
        notifications_enabled: notificationsStatus,
      }).unwrap();

      dispatch(diagnosticApi.util.resetApiState());
      dispatch(setNotificationFeatureFlag(notificationsStatus));

      toast({
        status: TOAST_STATUS.Success,
        title: `Preferences have been updated`,
      });
    } catch (e) {
      toast({
        status: TOAST_STATUS.Error,
        title: 'Failed to update preferences',
      });
    }
  };

  const onMinimumPublishStatusStateChange = (e: any) => {
    const value = e.target.value;
    setMinimumPublishStatusState(value);
  };

  const onEnableDisableStatusChange = (e: any) => {
    const value = e.target.checked;
    setEnableDisableState(value);
  };

  const onFrequencyStateChange = (value: any) => {
    setFrequencyState(value);
  };

  useEffect(() => {
    fetchUserPreferences({});
    analytics.page({
      name: 'Preferences',
      title: 'DataMind Dashboard 2.0 - Preferences',
    });
  }, []);

  useEffect(() => {
    if (userPreferences) {
      setFrequencyState(userPreferences?.two_fa_duration_days);
    }
  }, [userPreferences]);

  return (
    <VStack alignItems="flex-start" spacing={2} pt={20}>
      <Suspense>
        {isLoading ? (
          <Center py={16} w="full">
            <Spinner size="lg" />
          </Center>
        ) : (
          <>
            {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
              <VStack bg={colors.DMGray} w="99%" h="full" alignItems="flex-start" borderRadius="8px" p={4}>
                <Box fontWeight={600} bg={colors.DMGray} borderRadius="8px" w="99%">
                  <FormattedText label="page.settings.preferences.heading" />
                </Box>

                {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
                  <HStack w="full" borderRadius="8px">
                    <Box minW={52}>Minimum publish status: </Box>
                    <Box>
                      <Select
                        isDisabled={isUpdating}
                        value={minimumPublishStatusState || userPreferences?.minimum_publish_status}
                        onChange={onMinimumPublishStatusStateChange}
                        bgColor="white"
                        minW="12rem"
                      >
                        <option value="0">Not published</option>
                        <option value="10">Published</option>
                      </Select>
                    </Box>
                  </HStack>
                ) : null}
              </VStack>
            ) : null}
            {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
              <VStack bg={colors.DMGray} w="99%" h="full" alignItems="flex-start" borderRadius="8px" p={4} mt="16px">
                <Box fontWeight={600} bg={colors.DMGray} borderRadius="8px" w="99%">
                  <FormattedText label="page.settings.preferences.notifications.heading" />
                </Box>

                {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
                  <HStack w="full" borderRadius="8px">
                    <Box minW={52}>Alerts for organization: </Box>
                    <Switch
                      isDisabled={isUpdating}
                      isChecked={notificationsStatus}
                      onChange={(event) => {
                        const value = event.target.checked;
                        setNotificationsStatus(value);
                      }}
                      sx={getSwitchStyles(isLoading)}
                    />
                  </HStack>
                ) : null}
              </VStack>
            ) : null}
          </>
        )}
        <Hide>
          <VStack bg="DMGray" w="99%" h="full" alignItems="flex-start" borderRadius="8px" p={4} mt="16px">
            <Box fontWeight={600} bg={colors.DMGray} borderRadius="8px" w="99%">
              <FormattedText label="page.settings.preferences.2FA.heading" />
            </Box>
            {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) && (
              <HStack w="full" borderRadius="8px">
                <Box minW="52">Enable / Disable: </Box>
                <Switch
                  isDisabled={isUpdating}
                  isChecked={enableDisableState || userPreferences?.two_2fa_enabled}
                  onChange={onEnableDisableStatusChange}
                  sx={getSwitchStyles(isLoading)}
                />
              </HStack>
            )}

            <HStack w="full" borderRadius="8px">
              <Box minW="52">Frequency: </Box>
              <RadioGroup onChange={onFrequencyStateChange} value={frequencyState}>
                <HStack direction="row">
                  <Radio value="1">1 Day</Radio>
                  <Radio value="7">1 Week</Radio>
                  <Radio value="30">1 Month</Radio>
                  <Radio value="365">1 Year</Radio>
                </HStack>
              </RadioGroup>
            </HStack>
          </VStack>
        </Hide>

        <Box pt={6}>
          <Button variant="data-mind" size="lg" borderRadius="full" isLoading={isUpdating} onClick={onSubmit}>
            <FormattedText label="page.settings.preferences.submit" />
          </Button>
        </Box>
      </Suspense>
    </VStack>
  );
};
