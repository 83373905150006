import { Box, HStack, Text, Flex, VStack } from '@chakra-ui/react';
import { format, subMonths } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetPotentialSavingsQuery } from 'src/app/api/businessInsightsApi';
import { DropdownOptionGroupMenu } from 'src/components/DropdownOptionGroupMenu';
import { Loading } from 'src/components/Loading';
import { getOption } from './utils';
import { MachinesFailuresTable } from './MachinesFailuresTable';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/providers/AuthProvider';
import { selectCachedUserDataById } from 'src/app/queries';
import { useSelector } from 'react-redux';
import { MANAGEMENT_ROLE } from 'src/const';
import { BI, BIDefaults } from './consts';

const chartTitles = {
  cost: 'Accumulated Monthly Cost Impact',
  downtime: 'Accumulated Monthly Downtime Impact',
};

export const MonthlyImpact = () => {
  const { siteId } = useParams();
  const [fetchPotentialSaving, { data: potentialSavingData, isLoading }] = useLazyGetPotentialSavingsQuery({});
  const [yearOptions, setYearOptions] = useState<Array<string>>([]);
  const [selectedYear, setSelectedYear] = useState<string>(BIDefaults.DEFAULT_PERIOD);
  const [selectedDataType, setSelectedDataType] = useState<string>(BIDefaults.DEFAULT_DATA_TYPE);
  const { t } = useTranslation();
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));

  type Failure = {
    display_cost: boolean;
    currency: string;
  };
  const displayCost = useMemo(() => {
    return (
      potentialSavingData?.failures &&
      Object.values(potentialSavingData.failures).some((failures: any) =>
        (failures as Failure[]).some((failure: Failure) => failure.display_cost)
      )
    );
  }, [potentialSavingData]);

  const currency = useMemo(() => {
    const failure =
      potentialSavingData?.failures &&
      Object.values(potentialSavingData.failures)
        .flat()
        .find((failure: any) => failure.display_cost);
    return failure?.currency || '$';
  }, [potentialSavingData]);

  useEffect(() => {
    if (siteId) {
      fetchPotentialSaving({ site_id: siteId }, true);
    }
  }, [siteId]);

  useEffect(() => {
    if (potentialSavingData && potentialSavingData.potential_savings) {
      const years = Object.keys(potentialSavingData.potential_savings)
        .map((key) => key.split('_')[0])
        .filter((value, index, self) => self.indexOf(value) === index);
      setYearOptions(years);
    }
  }, [potentialSavingData]);

  const handleSelectChangePeriod = (selectedValue: any) => {
    setSelectedYear(selectedValue);
  };

  const handleSelectChangeDataType = (selectedValue: any) => {
    setSelectedDataType(selectedValue === 'Cost' ? 'cost' : 'hours');
  };

  const isEmptyObject = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const filterDataForLastYear = (data: any) => {
    const currentDate = new Date();
    const oneYearAgo = subMonths(currentDate, 12); // 12 months including the current month

    const filteredData = Object.keys(data.potential_savings || {})
      .filter((key) => {
        const [year, month] = key.split('_');
        const date = new Date(Number(year), Number(month) - 1);
        return date >= oneYearAgo && date <= currentDate;
      })
      .reduce((obj: any, key: any) => {
        obj[key] = data.potential_savings[key];
        return obj;
      }, {});

    const filteredFailures = Object.keys(data.failures || {})
      .filter((key) => {
        const [year, month] = key.split('_');
        const date = new Date(Number(year), Number(month) - 1);
        return date >= oneYearAgo && date <= currentDate;
      })
      .reduce((obj: any, key: any) => {
        obj[key] = data.failures[key];
        return obj;
      }, {});

    return { potential_savings: filteredData, failures: filteredFailures };
  };

  const filterDataByYear = (data: any, year: string) => {
    const filteredData = Object.keys(data.potential_savings || {})
      .filter((key) => key.startsWith(year))
      .reduce((obj: any, key: any) => {
        obj[key] = data.potential_savings[key];
        return obj;
      }, {});

    const filteredFailures = Object.keys(data.failures || {})
      .filter((key) => key.startsWith(year))
      .reduce((obj: any, key: any) => {
        obj[key] = data.failures[key];
        return obj;
      }, {});

    return { potential_savings: filteredData, failures: filteredFailures };
  };

  const MonthlyImpactChart = ({ period, dataType, data, currency }: any) => {
    let filteredAccumulated = [];
    let filteredCurrent = [];

    if (period === BIDefaults.DEFAULT_PERIOD) {
      filteredAccumulated = Object.keys(data.potential_savings).map((key) => ({
        month: key.split('_')[1],
        year: key.split('_')[0],
        accumulated:
          dataType === BI.Cost
            ? data.potential_savings[key].accumulated.cost
            : data.potential_savings[key].accumulated.downtime,
      }));

      filteredCurrent = Object.keys(data.potential_savings).map((key) => ({
        month: key.split('_')[1],
        year: key.split('_')[0],
        current:
          dataType === BI.Cost
            ? data.potential_savings[key].current.cost
            : data.potential_savings[key].current.downtime,
      }));
    } else {
      filteredAccumulated = Object.keys(data.potential_savings)
        .filter((key) => key.startsWith(period))
        .map((key) => ({
          month: key.split('_')[1],
          year: key.split('_')[0],
          accumulated:
            dataType === BI.Cost
              ? data.potential_savings[key].accumulated.cost
              : data.potential_savings[key].accumulated.downtime,
        }));

      filteredCurrent = Object.keys(data.potential_savings)
        .filter((key) => key.startsWith(period))
        .map((key) => ({
          month: key.split('_')[1],
          year: key.split('_')[0],
          current:
            dataType === BI.Cost
              ? data.potential_savings[key].current.cost
              : data.potential_savings[key].current.downtime,
        }));
    }

    const getMonthName = (year: string, monthNumber: string) => {
      const date = new Date(`${year}-${monthNumber}-01`);
      return format(date, 'MMM yyyy');
    };

    return (
      <>
        {isLoading ? (
          <Loading w="full" />
        ) : (
          <ReactECharts
            className="accumulated-chart"
            echarts={echarts}
            option={getOption({
              dataType,
              current: filteredCurrent.map((item) => ({ ...item, monthName: getMonthName(item.year, item.month) })),
              accumulated: filteredAccumulated.map((item) => ({
                ...item,
                monthName: getMonthName(item.year, item.month),
              })),
              currency,
            })}
          />
        )}
      </>
    );
  };

  const isDataAvailable =
    potentialSavingData &&
    !isEmptyObject(potentialSavingData.potential_savings) &&
    Object.keys(potentialSavingData.potential_savings).length > 0;

  const filteredData =
    selectedYear === BIDefaults.DEFAULT_PERIOD
      ? filterDataForLastYear(potentialSavingData || {})
      : filterDataByYear(potentialSavingData || {}, selectedYear);

  const showCostOption = displayCost || userData?.role === MANAGEMENT_ROLE.DM_Admin;

  return (
    <>
      <VStack w="full" bg="white" borderRadius="md">
        <Box w="full">
          {isLoading ? (
            <Loading w="full" />
          ) : isDataAvailable ? (
            <>
              <HStack px={4} py={2} justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} fontSize="0.875rem">
                  {chartTitles[selectedDataType === BI.Cost ? BI.Cost : BI.DownTime]}
                </Text>
                <HStack spacing={4}>
                  {showCostOption && (
                    <DropdownOptionGroupMenu
                      defaultValue={BIDefaults.DEFAULT_DATA_TYPE}
                      options={['Time', 'Cost']}
                      onChange={handleSelectChangeDataType}
                    />
                  )}
                  {yearOptions.length > 0 && (
                    <DropdownOptionGroupMenu
                      defaultValue={BIDefaults.DEFAULT_PERIOD}
                      options={[BIDefaults.DEFAULT_PERIOD, ...yearOptions]}
                      onChange={handleSelectChangePeriod}
                    />
                  )}
                </HStack>
              </HStack>
              <MonthlyImpactChart
                period={selectedYear}
                dataType={selectedDataType}
                data={filteredData}
                currency={currency}
              />
            </>
          ) : (
            <Flex height="200px" alignItems="center" justifyContent="center">
              <Text fontSize="0.875rem" color="gray.500">
                Insufficient data available to generate the chart
              </Text>
            </Flex>
          )}
        </Box>
      </VStack>
      {filteredData && Object.keys(filteredData.failures).length > 0 && (
        <MachinesFailuresTable
          potentialSavingData={filteredData}
          dataType={selectedDataType}
          isLoading={isLoading}
          currency={currency}
        />
      )}
    </>
  );
};
