import React, { useState, useEffect } from 'react';
import {
  Box,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Tooltip,
  Spinner,
} from '@chakra-ui/react';
import { useLazyGetResourceQuery } from 'src/app/api/diagnosticApi';
import { MIME_TYPE } from 'src/const';
import { useTranslation } from 'react-i18next';
import { DMExpandImage } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';

export const ComponentsSidebarBottom = ({
  imageResourceId,
  isPageLoading,
  assetName,
}: {
  imageResourceId: any;
  isPageLoading?: boolean;
  assetName?: string;
}) => {
  const [fetchResource, { data, isLoading }] = useLazyGetResourceQuery();
  const [resource, setResource] = useState<string>('');
  const [resourceType, setResourceType] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  useEffect(() => {
    if (imageResourceId) {
      fetchResource({
        resource_id: imageResourceId,
      });
    }
  }, [imageResourceId, fetchResource]);

  useEffect(() => {
    if (data) {
      setResourceType(data.type);
      setResource(`data:${data.type};base64, ${data.content}`);
    }
  }, [data]);

  const renderMachineImageResource = (type: string, resource: string) => {
    // Renders a machine image with type validation
    switch (type) {
      case MIME_TYPE.Image_JPG:
      case MIME_TYPE.Image_PNG:
        return (
          <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Image
              src={resource}
              borderRadius="8px"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              onClick={onOpen}
              cursor="pointer"
            />
            <Tooltip label="Expand" placement="top" borderRadius="md">
              <DMExpandImage
                onClick={onOpen}
                style={{ position: 'absolute', bottom: 10, right: 10, cursor: 'pointer' }}
                w="32px"
                h="32px"
              />
            </Tooltip>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <HStack w="full" px={6} fontWeight={600} position="sticky" bgColor="#E6E8EC" borderTopRadius="xl">
        <Box
          pt={4}
          pb={4}
          w="full"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <FormattedText
            label="page.machines.imageModal.title"
            values={{
              machineName: assetName,
            }}
          />
        </Box>
      </HStack>

      <HStack justifyContent="center" fontWeight={600} position="sticky" bgColor="#E6E8EC" borderBottomRadius="xl">
        <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '284px', height: '11rem' }}>
          {(imageResourceId && isLoading) || isPageLoading ? (
            <Spinner size="xl" />
          ) : imageResourceId ? (
            <Box style={{ width: '100%', height: '100%', marginBottom: '16px' }}>
              {renderMachineImageResource(resourceType, resource)}
            </Box>
          ) : (
            <Box w="284px" h="159.75" bg="gray.300" borderRadius="md" mb="16px" />
          )}
        </Box>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', xl: '3xl' }} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            <FormattedText
              label="page.machines.imageModal.title"
              values={{
                machineName: assetName,
              }}
            />
          </ModalHeader>
          <ModalCloseButton top={3} right={4} onClick={onClose} />
          <ModalBody pb={{ base: 0, xl: 2 }} px={{ base: 2, xl: 2 }} pt={0} borderRadius="md">
            <HStack justifyContent="space-around" w="full">
              {(resourceType === MIME_TYPE.Image_PNG || resourceType === MIME_TYPE.Image_JPG) && (
                <Image src={resource} borderRadius="8px" maxHeight="70vh" />
              )}
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
