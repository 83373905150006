import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { MachineStatusChart } from 'src/pages/machines/statistics/machine-status-chart/MachineStatusChart';
import { MachineCurrentStatusChart } from 'src/pages/machines/charts/machine-current-status-chart/MachineCurrentStatusChart';
import { CommonFailuresTable } from 'src/pages/machines/statistics/common-failures/CommonFailuresTable';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { MonthlyImpact } from 'src/pages/machines/statistics/operational-gains/MonthlyImpact';
import { MANAGEMENT_ROLE } from 'src/const';
import { useAuth } from 'src/providers/AuthProvider';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';

interface SiteBusinessInsightsStatisticsProps {
  siteAssetsUINames?: any;
}

export const SiteBusinessInsightsStatistics: React.FC<SiteBusinessInsightsStatisticsProps> = ({
  siteAssetsUINames,
}) => {
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));

  return (
    <>
      {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
        <Box bgColor="#f0f1f2" borderRadius="xl" mb={4}>
          <HStack justifyContent="space-between" pt={3} pb={2} px={6}>
            <Box fontSize="1rem" fontWeight={600}>
              Operational Gains Impact Analysis
            </Box>
          </HStack>
          <VStack px={4} py={2} spacing={4}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <MonthlyImpact />
            </ErrorBoundary>
          </VStack>
        </Box>
      ) : null}

      <Box bgColor="#f0f1f2" borderRadius="xl">
        <HStack justifyContent="space-between" py={4} px={6}>
          <Box fontSize="1rem" fontWeight={600}>
            Site Health Overview
          </Box>
        </HStack>

        <HStack px={4} spacing={4}>
          <ErrorBoundary FallbackComponent={Fallback}>
            <MachineCurrentStatusChart />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={Fallback}>
            <MachineStatusChart />
          </ErrorBoundary>
        </HStack>
        <Box pb={1}>
          <ErrorBoundary FallbackComponent={Fallback}>
            <CommonFailuresTable siteAssetsUINames={siteAssetsUINames} />
          </ErrorBoundary>
        </Box>
      </Box>
    </>
  );
};
