import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';

export type TextProps = {
  label?: string;
  values?: Record<string, string | ReactNode | unknown>;
};

export const FormattedText = ({ label, values = {} }: React.PropsWithChildren<TextProps>) => {
  if (!label) {
    return null;
  }

  return <Trans id={label} i18nKey={label} values={{ ...values }} />;
};
