import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useLazyGetResourceQuery } from 'src/app/api/diagnosticApi';
import { SourceInfo } from 'src/types/diagnostics';
import { useTranslation } from 'react-i18next';
import { HEALTH_STATUS, SENSORS_DATA_TYPE, TOAST_STATUS } from 'src/const';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { FormattedText } from 'src/components/FormattedText';
import { renderEvidenceResource } from 'src/utils';
import { parseISO, differenceInDays, isAfter, format } from 'date-fns';
import { SENSOR_TYPE, PLOTY_DIAGNOSTICS_RANGE } from 'src/const';

interface FusionEvidenceModalProps {
  source: SourceInfo;
  cameraSource: SourceInfo | null;
  onFusionTrendsModalClose?: () => void;
  componentId?: string;
  diagnosticCreatedAt?: string;
  faultType?: string;
}

export const FusionEvidenceModal = ({
  source,
  cameraSource,
  onFusionTrendsModalClose,
  componentId,
  diagnosticCreatedAt,
  faultType,
}: FusionEvidenceModalProps) => {
  const { siteId, machineId } = useParams<string>();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [resource, setResource] = useState<string>('');
  const [resourceType, setResourceType] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fetchResource, { data, isLoading }] = useLazyGetResourceQuery();
  const isNotAvailable = source.health_status === HEALTH_STATUS.NOT_AVAILABLE;

  useEffect(() => {
    if (data) {
      setResourceType(data.type);
      setResource(`data:${data.type};base64, ${data.content}`);
    }
  }, [data]);

  useEffect(() => {
    if (isOpen && (source.evidence_resource_id || (cameraSource && cameraSource.evidence_resource_id)) && machineId) {
      const resourceId = source.evidence_resource_id || cameraSource?.evidence_resource_id;
      fetchResource({
        resource_id: resourceId!,
      })
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(source?.source_type.toLowerCase())) {
            toast({
              id: source?.source_type.toLowerCase(),
              status: TOAST_STATUS.Error,
              title: `Failed load evidence for sensor type: ${source?.source_type}`,
              description: t('general.errors.communication'),
            });
          }
          onClose();
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  }, [source, isOpen]);

  const goToSensorDataTab = (group: string, componentId?: string) => {
    onFusionTrendsModalClose && onFusionTrendsModalClose();
    navigate(
      `${generatePath(locations.protected.sites.specificComponent, {
        siteId,
        machineId,
        componentId: componentId,
      })}?tabIndex=1&group=${group}`
    );
  };

  const goToVisualAITab = (diagnosticDate: Date, group: string, componentId?: string, resource?: string) => {
    const fromDate = diagnosticDate ? format(diagnosticDate, "yyyy-MM-dd'T'00:00:00") : '';

    onFusionTrendsModalClose && onFusionTrendsModalClose();

    navigate(
      resource
        ? `${generatePath(locations.protected.sites.specificComponent, {
            siteId,
            machineId,
            componentId: componentId,
          })}?tabIndex=2&group=${group}&resourceId=${resource}&faultType=${faultType}&from=${fromDate}`
        : `${generatePath(locations.protected.sites.specificComponent, {
            siteId,
            machineId,
            componentId: componentId,
          })}?tabIndex=2&group=${group}&from=${fromDate}`
    );
  };

  const ViewEvidenceLink = () => (
    <Box onClick={onOpen} textTransform="capitalize" {...evidenceLinkProps}>
      <FormattedText label="general.view" />
    </Box>
  );

  const renderSensorDataLink = (group: string, componentId?: string) => {
    return (
      <Box
        onClick={() => goToSensorDataTab(group, componentId)}
        textTransform="capitalize"
        {...evidenceLinkProps}
        whiteSpace="nowrap"
      >
        <FormattedText label="general.sensorDataTab" />
      </Box>
    );
  };

  const renderVisualAiLink = (diagnosticDate: Date, group: string, componentId?: string, resource?: string) => {
    return (
      <Box
        onClick={() => goToVisualAITab(diagnosticDate, group, componentId, resource)}
        textTransform="capitalize"
        {...evidenceLinkProps}
        whiteSpace="nowrap"
      >
        <FormattedText label="general.visualAiTab" />
      </Box>
    );
  };

  const getEvidenceLink = (sensorType: string, health_status: string, componentId?: string) => {
    const cutoffDate = parseISO('2024-04-08');
    const diagnosticDate = diagnosticCreatedAt ? parseISO(diagnosticCreatedAt) : new Date();
    const daysDifference = differenceInDays(new Date(), diagnosticDate);

    if (['distance to roller trend', 'top size trend'].includes(sensorType.toLowerCase())) {
      if (isAfter(diagnosticDate, cutoffDate)) {
        return renderVisualAiLink(
          diagnosticDate,
          SENSORS_DATA_TYPE.VisualAI,
          componentId,
          cameraSource?.evidence_resource_id || undefined
        );
      } else {
        return <ViewEvidenceLink />;
      }
    }
    if (daysDifference <= PLOTY_DIAGNOSTICS_RANGE && sensorType.toLowerCase() === SENSOR_TYPE.Camera) {
      return renderVisualAiLink(
        diagnosticDate,
        SENSORS_DATA_TYPE.VisualAI,
        componentId,
        cameraSource?.evidence_resource_id || undefined
      );
    }
    if (health_status !== HEALTH_STATUS.HEALTHY) {
      return <ViewEvidenceLink />;
    }

    switch (sensorType.toLowerCase()) {
      case 'current':
      case 'tachometer':
      case 'oil-condition':
      case 'oil condition':
      case 'oil-temperature':
      case 'oil temperature':
      case 'oil-quality':
      case 'pressure':
      case 'temperature':
      case 'temprature':
      case 'flow':
        return renderSensorDataLink(SENSORS_DATA_TYPE.PI, componentId);
      case 'vibrations':
      case 'vibration':
        return renderSensorDataLink(SENSORS_DATA_TYPE.PI, componentId);
      default:
        return <ViewEvidenceLink />;
    }
  };

  return (
    <Box textAlign="right">
      {isNotAvailable ? null : getEvidenceLink(source.source_type, source.health_status, componentId)}

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', xl: '6xl' }} isCentered trapFocus={false}>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            {source.source_type}
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody pb={{ base: 0, xl: 6 }} px={{ base: 4, xl: 6 }} pt={{ base: 0, xl: 2 }}>
            {isLoading ? (
              <Center py={16}>
                <Spinner size="lg" />
              </Center>
            ) : (
              <>
                <Box>{source.evidence_text}</Box>
                {renderEvidenceResource(resourceType, resource)}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const evidenceLinkProps = {
  _hover: {
    textDecoration: 'underline',
  },
  color: '#7071f3',
  fontSize: '0.75rem',
  cursor: 'pointer',
};
