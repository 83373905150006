import { addDays, addMinutes, differenceInDays, subDays, subMinutes } from 'date-fns';

export const findRangeBreaks = (datesArray: Array<string>) => {
  const getTimeDifferenceInHours = (date1: any, date2: any) => {
    const diffMilliseconds = Math.abs(date2 - date1);
    return diffMilliseconds / (1000 * 60 * 60);
  };
  const dateObjects = datesArray.map((dateString) => new Date(dateString));
  const rangeBreaks = [];

  for (let i = 0; i < dateObjects.length - 1; i++) {
    const currentDate = dateObjects[i];
    const nextDate = dateObjects[i + 1];
    const timeDiff = getTimeDifferenceInHours(currentDate, nextDate);
    if (timeDiff > 2) {
      rangeBreaks.push({
        start: addMinutes(currentDate, 30).toISOString(),
        end: subMinutes(nextDate, 30).toISOString(),
        gapInHours: timeDiff,
      });
    }
  }

  return rangeBreaks;
};

export const calculateDateRange = (startDate: string, endDate: string) => {
  const diff = differenceInDays(new Date(endDate), new Date(startDate));
  if (diff < 6) {
    return [subDays(new Date(startDate), 2).toISOString(), addDays(new Date(endDate), 2).toISOString()];
  }
  return [new Date(startDate).toISOString(), new Date(endDate).toISOString()];
};

export const cameraEvidenceShape = {
  type: 'scatter',
  mode: 'markers+text',
  marker: {
    size: 12,
    color: '#01152b',
    symbol: 'circle',
  },
  text: '\u25B6',
  textposition: 'middle center',
  opacity: 0,
  showlegend: false,
  hovertemplate: `<b>Camera Evidence</b><br>` + 'Click to view<br>' + '%{x|%d.%m.%Y}<br>' + '<extra></extra>',
};