import React from 'react';
import { Box, Divider, HStack, Switch } from '@chakra-ui/react';

export const FaultSwitch = () => {
  return (
    <>
      <HStack justifyContent="space-between" w="full" py={6}>
        <Box fontSize="0.875rem">Fault type name placeholder</Box>
        <Box>
          <Switch />
        </Box>
      </HStack>
      <Divider mt={0} />
    </>
  );
};
