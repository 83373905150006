import React from 'react';
import { Box, HStack, Table, Tbody, Td, Tr, VStack } from '@chakra-ui/react';
import { DMStatusHealthyIcon } from 'src/components/Icons';
import { FeedbackThumbs } from 'src/pages/machines/components/FeedbackThumbs';
import { PrescriptiveAction } from 'src/types/diagnostics';

interface ActionsListProps {
  diagnosticId: string;
  prescriptiveActions: PrescriptiveAction[];
}

export const ActionsList = ({ diagnosticId, prescriptiveActions }: ActionsListProps) => {
  return prescriptiveActions?.length ? (
    <Box w={{ base: 'full', xl: '50%' }}>
      <HStack justifyContent="space-between" pb={2}>
        <HStack fontWeight={600} spacing={2} alignItems="center">
          <DMStatusHealthyIcon color="#021d3d" />
          <Box>Action</Box>
        </HStack>
        <Box fontSize="0.875rem">Resolved?</Box>
      </HStack>

      <VStack spacing={0}>
        {prescriptiveActions.map((item: PrescriptiveAction, index: number) => (
          <HStack
            justifyContent="space-between"
            key={index}
            fontSize="0.875rem"
            w="full"
            py={2}
            px={3}
            borderRadius="lg"
            bgColor={index % 2 === 0 ? '#F0F2F7' : undefined}
          >
            <Box>{item.description}</Box>
            <Box>
              <FeedbackThumbs
                feedback={item.feedback}
                feedbackType={'action'}
                description={item.description}
                diagnosticId={diagnosticId}
              />
            </Box>
          </HStack>
        ))}
      </VStack>
    </Box>
  ) : null;
};
