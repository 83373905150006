import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { auth } from 'src/firebase';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_GATEWAY_URL,
  timeout: 40000,
  prepareHeaders: async (headers) => {
    const accessToken = await auth.currentUser?.getIdToken();
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    } else {
      console.debug('[HEADER ERROR] Authorization header is empty. Token unavailable.');
    }
    return headers;
  },
});
