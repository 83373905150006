import { Box, BoxProps, Flex, HStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import React from 'react';
import { css } from '@emotion/react';

interface FilterButtonProps extends BoxProps {
  amount: number;
  label: string;
  status?: HEALTH_STATUS;
  active?: boolean;
  onClick?: () => void;
}

export const FilterButton = ({ amount, label, status, active, ...rest }: FilterButtonProps) => {
  return (
    <Flex
      flexDirection={{ base: 'row-reverse', xl: 'row' }}
      justifyContent={{ base: 'space-between', xl: 'flex-start' }}
      alignItems="center"
      borderRight={{ base: 0, xl: '1px solid rgba(1, 10, 9, 0.1)' }}
      gap={{ base: 0, xl: 2 }}
      h="3rem"
      px={4}
      cursor="pointer"
      css={{
        ...(active ? activeFilterButton : null),
      }}
      {...rest}
    >
      <Box fontSize="1.25rem" fontWeight={600} color={`health.${status}`}>
        {amount}
      </Box>
      <Box
        fontSize={{ base: '0.875rem', xl: '0.625rem' }}
        textTransform={{ base: 'capitalize', xl: 'uppercase' }}
        fontWeight={{ base: 600, xl: 500 }}
        letterSpacing={{ base: undefined, xl: 1.1 }}
      >
        {label}
      </Box>
    </Flex>
  );
};

const activeFilterButton = css`
  background-color: rgba(112, 113, 243, 0.12);
`;
