import React, { useState } from 'react';
import { HStack, Spinner } from '@chakra-ui/react';
import { DMThumbDownIcon, DMThumbUpIcon } from 'src/components/Icons';
import { useUpdateFeedbackMutation } from 'src/app/api/diagnosticApi';

const enum FEEDBACK_STATE {
  Resolved = 'resolved',
  Unresolved = 'unresolved',
  NoFeedback = 'no_feedback',
}

interface FeedbackThumbsProps {
  feedbackType: string;
  feedback: string;
  description: string;
  diagnosticId: string;
}

export const FeedbackThumbs = ({ feedback, feedbackType, description, diagnosticId }: FeedbackThumbsProps) => {
  const [state, setState] = useState<string>(feedback);
  const [updateFeedback, { isLoading: isUpdating }] = useUpdateFeedbackMutation();

  const onThumbClick = (feedback: string) => {
    updateFeedback({
      diagnostic_id: diagnosticId,
      feedback_type: feedbackType,
      feedback,
      description,
    });
  };

  return (
    <HStack justifyContent="flex-end">
      {isUpdating ? (
        <Spinner size="sm" />
      ) : (
        <>
          <DMThumbDownIcon
            fontSize="1.5rem"
            color={
              state === FEEDBACK_STATE.Unresolved
                ? 'health.critical'
                : state !== null
                ? 'rgba(2, 29, 61, 0.34)'
                : 'black'
            }
            onClick={() => {
              if (state === FEEDBACK_STATE.Unresolved) {
                setState(FEEDBACK_STATE.NoFeedback);
                onThumbClick(FEEDBACK_STATE.NoFeedback);
              } else {
                setState(FEEDBACK_STATE.Unresolved);
                onThumbClick(FEEDBACK_STATE.Unresolved);
              }
            }}
            cursor="pointer"
          />
          <DMThumbUpIcon
            fontSize="1.5rem"
            color={
              state === FEEDBACK_STATE.Resolved ? 'health.healthy' : state !== null ? 'rgba(2, 29, 61, 0.34)' : 'black'
            }
            onClick={() => {
              if (state === FEEDBACK_STATE.Resolved) {
                setState(FEEDBACK_STATE.NoFeedback);
                onThumbClick(FEEDBACK_STATE.NoFeedback);
              } else {
                setState(FEEDBACK_STATE.Resolved);
                onThumbClick(FEEDBACK_STATE.Resolved);
              }
            }}
            cursor="pointer"
          />
        </>
      )}
    </HStack>
  );
};
