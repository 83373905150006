import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { Asset } from 'src/types/assets';
import { Site } from 'src/types/sites';
import { AssetSensorsHealthStatus } from 'src/data/sensors';
import { DiagnosticsRequestsQueryParams } from 'src/types/diagnostics';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const diagnosticApi = createApi({
  reducerPath: 'diagnosticApi',
  baseQuery,
  tagTypes: ['Sites', 'Assets', 'OrganizationSummary', 'Diagnostics', 'Sensors', 'Feedback', 'Resource'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getSites: builder.query<Array<Site>, any>({
      query: () => {
        return { url: `diagnostic/sites_preview` };
      },
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'Sites', site_id } as const)),
        { type: 'Sites' as const, id: 'LIST' },
      ],
    }),

    getAssets: builder.query<Array<Asset>, any>({
      query: ({ site_id }) => ({
        url: `diagnostic/assets_preview?site_id=${site_id}`,
      }),
      providesTags: (result = []) => [
        ...result.map(({ asset_id }) => ({ type: 'Assets', asset_id } as const)),
        { type: 'Assets' as const, id: 'LIST' },
      ],
    }),

    getResource: builder.query<any, any>({
      query: ({ resource_id }) => ({
        url: `diagnostic/resource?resource_id=${resource_id}`,
      }),
      providesTags: () => [{ type: 'Resource' }],
    }),

    getSensors: builder.query<AssetSensorsHealthStatus, void>({
      query: () => ({ url: 'sensors_health_status' }),
      providesTags: () => [{ type: 'Sensors' }],
    }),

    getDiagnostics: builder.query<any, DiagnosticsRequestsQueryParams>({
      query: ({ site_id, assets_ids, from_date, to_date }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        if (assets_ids) {
          searchParams.append('assets_ids', assets_ids);
        }

        if (from_date) {
          searchParams.append('from_date', from_date);
        }

        if (to_date) {
          searchParams.append('to_date', to_date);
        }

        return {
          url: `diagnostic/diagnostics?${searchParams.toString()}`,
        };
      },
      transformResponse: (response: any) => {
        const transformedResponse = Object.entries(response).sort((a, b) => {
          const dateA = Date.parse(a[0]);
          const dateB = Date.parse(b[0]);

          return dateA - dateB;
        });

        return transformedResponse;
      },
      providesTags: [{ type: 'Diagnostics' }],
    }),

    updateFeedback: builder.mutation<any, any>({
      query({ diagnostic_id, feedback_type, feedback, description }) {
        const searchParams = new URLSearchParams();
        if (diagnostic_id) {
          searchParams.append('diagnostic_id', diagnostic_id);
        }
        if (feedback_type) {
          searchParams.append('feedback_type', feedback_type);
        }
        if (feedback) {
          searchParams.append('feedback', feedback);
        }
        if (description) {
          searchParams.append('description', description);
        }

        return {
          url: `diagnostic/update_feedback?${searchParams.toString()}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetAssetsQuery,
  useLazyGetSitesQuery,
  useLazyGetAssetsQuery,
  useLazyGetDiagnosticsQuery,
  useUpdateFeedbackMutation,
  useLazyGetResourceQuery,
} = diagnosticApi;
