export const filterMeasurementsByDateRange = (evidenceDates: any, measurements: any): any => {
  const evidenceDatesDt = evidenceDates.map((date: any) => new Date(date));
  const measurementsDt = measurements?.map((measurement: any) => new Date(measurement));
  const sortedDates = evidenceDatesDt.sort((a: any, b: any) => a - b);
  const results: any = {};

  for (let i = 0; i < sortedDates.length; i++) {
    const startDate = i === 0 ? new Date(0) : sortedDates[i - 1];
    const endDate = sortedDates[i];

    results[endDate.toISOString()] = measurementsDt
      ?.filter(
        (measurement: any) =>
          startDate <= measurement && measurement <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000 - 1)
      )
      ?.map((measurement: any) => measurement.toISOString());
  }

  return results;
};

export const getLatestMeasurementsForEachDate = (results: any): any[] => {
  const latestMeasurements: any[] = [];

  for (const value of Object.values(results)) {
    if (Array.isArray(value) && value.length > 0) {
      latestMeasurements.push(value[value.length - 1]);
    } else {
      latestMeasurements.push(null);
    }
  }

  return latestMeasurements;
};
