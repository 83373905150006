import { useGetAssetsQuery, useGetSitesQuery } from 'src/app/api/diagnosticApi';
import { Asset } from 'src/types/assets';
import { Site } from 'src/types/sites';

export const getSiteById = (siteId?: string) => {
  const { data } = useGetSitesQuery(
    {},
    {
      selectFromResult: ({ data = [] }) => ({
        data: data.filter((item: Site) => item.site_id === siteId)[0],
      }),
    }
  );

  return { site: data };
};

export const getAssets = (siteId?: string) => {
  const { data } = useGetAssetsQuery({ site_id: siteId });

  return { assets: data };
};

export const getAssetById = (siteId?: string, machineId?: string) => {
  const { data } = useGetAssetsQuery(
    { site_id: siteId },
    {
      selectFromResult: ({ data = [] }) => ({
        data: data.filter((item: Asset) => item.asset_id === machineId)[0],
      }),
    }
  );

  return { asset: data };
};

export const selectCachedUserDataById = (state: any, userId: string) => {
  const cache = state.userApi.queries; // Access the cache object
  const cachedData = [];

  for (const queryKey in cache) {
    const query = cache[queryKey];

    if (query.data) {
      cachedData.push(query.data);
    }
  }

  const selectedUsers = cachedData.flat().filter((item) => item.user_id === userId);

  if (selectedUsers.length) {
    return selectedUsers[0];
  } else {
    return null;
  }
};

export const selectCachedUserSiteIdsById = (state: any, userId: string) => {
  const cache = state.userApi.queries; // Access the cache object
  const cachedData = [];

  for (const queryKey in cache) {
    const query = cache[queryKey];
    if (query.data) {
      cachedData.push(query.data);
    }
  }

  const selectedUsers = cachedData.flat().filter((item) => item.user_id === userId);
  if (selectedUsers.length) {
    const user = selectedUsers[0];
    const siteIds = user.site_ids;
    return { site_ids: siteIds };
  } else {
    return null;
  }
};
