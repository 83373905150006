import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['Users', 'Preferences', 'AppPreferences', 'NotificationsDM'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getUsers: builder.query<Array<any>, any>({
      query: ({ uids }) => {
        const searchParams = new URLSearchParams();

        if (uids) {
          searchParams.append('uids', uids);
        }

        return { url: `users?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ user_id }) => ({ type: 'Users' as const, user_id })),
        { type: 'Users', id: 'LIST' },
      ],
    }),

    getPreferences: builder.query<any, any>({
      query: () => ({ url: 'users/preferences' }),
      providesTags: () => [{ type: 'Preferences' }],
      keepUnusedDataFor: 0,
    }),

    updatePreferences: builder.mutation<any, any>({
      query(body) {
        return {
          url: `users/preferences`,
          method: 'PUT',
          body,
        };
      },
    }),

    createUser: builder.mutation<any, any>({
      query(body) {
        return {
          url: `users`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Users', user_id: id }],
    }),

    updateUser: builder.mutation<any, any>({
      query(body) {
        return {
          url: `users`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Users', user_id: id }],
    }),

    deleteUser: builder.mutation<any, string>({
      query(id) {
        return {
          url: `users?uid=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Users', user_id: id }],
    }),
  }),
});

export const {
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetPreferencesQuery,
  useLazyGetPreferencesQuery,
  useUpdatePreferencesMutation,
  useLazyGetUsersQuery,
} = userApi;
