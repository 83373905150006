import React from 'react';
import { Badge, Center } from '@chakra-ui/react';
import { NotificationAlert } from 'src/data/notifications';

export const NotificationsBadge = (props: { notifications: NotificationAlert[] }) => {
  return (
    <Badge
      borderRadius="full"
      bgColor="red"
      color="white"
      w="1.5rem"
      h="1.5rem"
      pt="0.125rem"
      fontSize="0.7rem"
      position="absolute"
      left="1.3rem"
      top={0.8}
      border="2px solid #2f2f66"
    >
      <Center>{props.notifications?.length}</Center>
    </Badge>
  );
};
