import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { trimMiddle } from 'src/features/sensor-fusion/utils';
import React from 'react';

interface SensorRowProps {
  isPinned?: boolean;
  point: any;
  diffValues: any;
}

export const SensorRow = ({ point, diffValues, isPinned }: SensorRowProps) => {
  const diffValue = diffValues?.filter((item: any) => item.id === point.data.id);

  return (
    <HStack w="full" justifyContent="space-between" fontSize="0.75rem" py="0.18rem">
      <HStack spacing={1}>
        <Box w={3} h={1} bgColor={point.fullData.line.color} borderRadius="sm"></Box>
        <Box>
          {point.data.name.length > 28 ? (
            <Tooltip label={point.data.name} borderRadius="md">
              {trimMiddle(point.data.name, 28)}
            </Tooltip>
          ) : (
            point.data.name
          )}
        </Box>
        <Box>{point.data?.trend}</Box>
        <Box>{point.data?.units?.split(' ')[1]}</Box>
      </HStack>

      <HStack w="6rem" justifyContent="right" spacing="0">
        <Box fontWeight={600} w="3rem" textAlign="right">
          {point.y.toFixed(2)}
        </Box>

        {diffValues?.length || isPinned ? (
          <Box fontWeight={600} color="DMPrimaryColor" w="3rem" textAlign="right">
            {diffValue?.length ? -diffValue[0].value.toFixed(2) : diffValues?.length ? 'Not Monitored' : null}
          </Box>
        ) : null}
      </HStack>
    </HStack>
  );
};
