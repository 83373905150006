import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DMTrashIcon } from 'src/components/Icons';
import { useDeleteUserMutation } from 'src/app/api/userApi';
import { useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { TOAST_STATUS } from 'src/const';
import { useTranslation } from 'react-i18next';

interface DeleteUserDialogProps {
  userId?: string;
}

export const DeleteUserDialog = ({ userId }: DeleteUserDialogProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const onDeleteClick = async () => {
    if (userId) {
      try {
        await deleteUser(userId);
        toast({
          status: TOAST_STATUS.Success,
          title: 'User successfully deleted',
        });
        onClose();
        navigate(locations.protected.settings.userManagement);
      } catch (e) {
        toast({
          status: TOAST_STATUS.Error,
          title: `Failed to delete user`,
          description: t('general.errors.communication'),
        });
      }
    }
  };

  return (
    <>
      <Button
        variant="outline"
        size="lg"
        borderRadius="full"
        borderWidth="0.125rem"
        borderColor="#de6f62"
        color="#de6f62"
        fontSize="0.875rem"
        textTransform="uppercase"
        onClick={onOpen}
        leftIcon={<DMTrashIcon fontSize="2xl" />}
      >
        Delete user
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius="2xl">
            <AlertDialogHeader fontSize="md" fontWeight="bold" borderBottom="1px solid rgba(2, 29, 61, 0.12)">
              Delete user
            </AlertDialogHeader>
            <AlertDialogCloseButton top={3} right={4} />
            <AlertDialogBody p={6} fontWeight="semibold">
              Are you sure you want to delete?
            </AlertDialogBody>
            <AlertDialogFooter p={6}>
              <Button ref={cancelRef} onClick={onClose} variant="data-mind" size="lg" borderRadius="full">
                Cancel
              </Button>

              <Button
                size="lg"
                backgroundColor="#de6f62"
                borderRadius="full"
                borderWidth="0.125rem"
                borderColor="#de6f62"
                color="white"
                isLoading={isLoading}
                fontSize="0.875rem"
                textTransform="uppercase"
                onClick={onDeleteClick}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
