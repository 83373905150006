import React, { ReactElement, ReactNode } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';

interface FormLayoutProps {
  title: string;
  subtitle: string;
  icon?: ReactElement;
  children?: ReactNode;
}

export const FormLayout = ({ title, subtitle, icon, children }: FormLayoutProps) => {
  return (
    <VStack h="full" justifyContent="center" px={{ base: 4, xl: 0 }}>
      <Box
        maxW="26.25rem"
        minW={{ base: 'full', sm: '26.25rem' }}
        bgColor={{ base: 'white', xl: 'transparent' }}
        borderRadius={{ base: 'lg', xl: 0 }}
        px={{ base: 6, xl: 0 }}
        py={{ base: 12, xl: 0 }}
      >
        <Box
          textAlign={{
            base: 'center',
            xl: 'left',
          }}
        >
          {icon}
        </Box>

        <Box>
          <Heading
            fontSize="1.5rem"
            fontWeight={600}
            w={{
              base: 'full',
              xl: 'auto',
            }}
            textAlign={{
              base: 'center',
              xl: 'left',
            }}
          >
            <FormattedText label={title} />
          </Heading>
        </Box>

        <Box
          pt={6}
          pb={10}
          textAlign={{
            base: 'center',
            xl: 'left',
          }}
        >
          <FormattedText label={subtitle} />
        </Box>

        <Box w="full">{children}</Box>
      </Box>
    </VStack>
  );
};
