import React from 'react';
import { DMChartPieIcon } from 'src/components/Icons';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Summary } from 'src/pages/sites/components/summary/Summary';
import { Site } from 'src/types/sites';

const SummaryDrawer = ({ sites }: { sites?: Site[] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        variant="naked"
        aria-label="Sites summary"
        onClick={onOpen}
        icon={<DMChartPieIcon fontSize="1.5rem" />}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay top="3.5rem" bgColor="rgba(2, 29, 61, 0.5)" />
        <DrawerContent top="3.5rem!important" maxW="270px">
          <DrawerCloseButton top={3} right={3} />
          <DrawerBody top="3.5rem" px={1} py={4}>
            {sites ? <Summary sites={sites} /> : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SummaryDrawer;
