import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { notificationApi } from 'src/app/api/notificationApi';
import { sensorsApi } from 'src/app/api/sensorsApi';
import { statisticsApi } from 'src/app/api/statisticsApi';
import { appSlice } from 'src/app/slices/applicationSlice';
import { authApi } from 'src/app/api/authApi';
import { businessInsightsApi } from './api/businessInsightsApi';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [diagnosticApi.reducerPath]: diagnosticApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [notificationApi.reducerPath]: notificationApi.reducer,
      [sensorsApi.reducerPath]: sensorsApi.reducer,
      [statisticsApi.reducerPath]: statisticsApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [businessInsightsApi.reducerPath]: businessInsightsApi.reducer,
      application: appSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        diagnosticApi.middleware,
        userApi.middleware,
        notificationApi.middleware,
        sensorsApi.middleware,
        statisticsApi.middleware,
        authApi.middleware,
        businessInsightsApi.middleware
      ),
    ...options,
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const store = createStore();
export const rootState = store.getState();
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatch = store.dispatch;

export const firebaseUserRequestingSelector = (state: RootState) => state.application.isFirebaseRequesting;
export const searchValueSelector = (state: RootState) => state.application.searchValue;
export const notificationsFeatureFlagSelector = (state: RootState) => state.application.notificationsFeatureFlag;
export const mfaExpiredSelector = (state: RootState) => state.application.isMfaExpired;
