import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { DMEditUserIcon, DMTrashIcon, DMVerticalDotsIcon } from 'src/components/Icons';
import React from 'react';

interface UserRowMenuProps {
  onEditMenuItemClick: () => void;
  onDeleteMenuItemClick: () => void;
}

export const UserRowMenu = ({ onEditMenuItemClick, onDeleteMenuItemClick }: UserRowMenuProps) => {
  return (
    <Menu isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            w="4.5rem"
            h="4.5rem"
            borderRightRadius="md"
            bgColor={isOpen ? 'rgba(112, 113, 243, 0.12)' : undefined}
          >
            <DMVerticalDotsIcon fontSize="1.5rem" />
          </MenuButton>

          <MenuList>
            <MenuItem
              key={0}
              py={3}
              fontWeight="semibold"
              onClick={onEditMenuItemClick}
              icon={<DMEditUserIcon fontSize="2xl" />}
              {...menuItemStyle}
            >
              Edit user
            </MenuItem>

            <MenuItem
              key={1}
              py={3}
              fontWeight="semibold"
              onClick={onDeleteMenuItemClick}
              icon={<DMTrashIcon fontSize="2xl" />}
              {...menuItemStyle}
            >
              Delete user
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

const menuItemStyle = {
  _hover: {
    backgroundColor: 'rgba(112, 113, 243, 0.12)',
  },
  _focus: {
    backgroundColor: 'rgba(112, 113, 243, 0.12)',
  },
};
