import { NotificationsPreferencesEventType } from 'src/data/appPreferences';
import { useSticky } from 'src/hooks/useSticky';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { css } from '@emotion/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { colors } from 'src/theme/foundations/colors';

export const ApplicationPreferencesHeader = ({ eventType }: { eventType: NotificationsPreferencesEventType }) => {
  const [sticky] = useSticky(120);
  const parameters = eventType?.assets[0]?.parameters;

  const style = {
    ...applicationPreferencesHeaderBaseStyle,
    ...(sticky ? applicationPreferencesHeaderStickyStyle : null),
  };

  return (
    <HStack css={style} pt={10}>
      <Box minW="16rem">Site & Machine name</Box>
      <HStack w="100%" justifyContent="flex-start" spacing={4} alignItems="flex-start">
        {parameters?.map((parameter, index) => (
          <Box key={index} w="full" maxWidth="8rem" display="flex">
            <Text fontSize="0.775rem">{parameter.ui_name}</Text>
            {parameter.description ? (
              <Tooltip label={parameter.description} borderRadius="md">
                <QuestionIcon />
              </Tooltip>
            ) : null}
          </Box>
        ))}
      </HStack>
    </HStack>
  );
};

const applicationPreferencesHeaderBaseStyle = css`
  width: 100%;
  font-size: 0.725rem;
  font-weight: 600;
  padding: 0.65rem 1rem 0.65rem 1rem;
`;

const applicationPreferencesHeaderStickyStyle = css`
  position: fixed;
  top: 3.5rem;
  left: 24.8rem;
  z-index: 1;
  width: calc(100% - 24.8rem);
  font-size: 0.725rem;
  font-weight: 600;
  padding: 0.5rem 4rem;
  padding-left: 4.8rem;
  background-color: #eaebee;
`;
