import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { sub } from 'date-fns';
import { DMCalendarInfo } from 'src/components/Icons';
import DatePicker from 'react-datepicker';
import React from 'react';

interface CalendarPopoverProps {
  fromDate: Date | undefined;
  minDate: Date | undefined;
  fromDateString: string;
  updateFromDate: (date: Date | undefined) => void;
  onCloseChartDatePickerPopover: () => void;
}

export const CalendarPopover = ({
  fromDate,
  updateFromDate,
  minDate,
  fromDateString,
  onCloseChartDatePickerPopover,
}: CalendarPopoverProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const maxDate = sub(new Date(), { days: 1 });

  const formatWeekDay = (dayName: string) => dayName.substring(0, 1);

  const handleDateChange = (date: Date) => {
    updateFromDate(date);
    onClose();
    onCloseChartDatePickerPopover();
  };

  const onTriggerClick = (e: any) => {
    e.stopPropagation();
    onToggle();
  };

  const onDateChange = (e: any) => e.stopPropagation();

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="left-end">
      <InputGroup size="sm">
        <PopoverTrigger>
          <Input
            sx={{
              ':focus-visible': {
                borderColor: 'rgba(112, 113, 243, 1)',
              },
            }}
            pl={3}
            fontWeight={500}
            isReadOnly
            cursor="pointer"
            variant="flushed"
            w="full"
            size="sm"
            onClick={onTriggerClick}
            onChange={onDateChange}
            value={fromDateString}
          />
        </PopoverTrigger>
        <InputRightElement pr={3}>
          <DMCalendarInfo fontSize="1.5rem" />
        </InputRightElement>
      </InputGroup>

      <PopoverContent w="full" borderColor="rgba(177, 185, 195, 1)">
        <PopoverArrow />
        <PopoverBody p={0} w="full">
          <DatePicker
            inline
            minDate={minDate}
            maxDate={maxDate}
            selected={fromDate}
            formatWeekDay={formatWeekDay}
            onChange={handleDateChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
