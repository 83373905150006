import React, { useState, useMemo } from 'react';
import { Box, Collapse, Table, Thead, Tbody, Tr, Td, Button, Text, VStack, Flex } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/theme/foundations/colors';
import { Loading } from 'src/components/Loading';

interface MachinesFailuresTableProps {
  potentialSavingData?: any;
  dataType: string;
  isLoading: boolean;
  currency: string;
}

const cost = 'cost';
const onGoing = 'Ongoing';

export const MachinesFailuresTable: React.FC<MachinesFailuresTableProps> = ({
  potentialSavingData,
  dataType,
  isLoading,
  currency,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleShowMoreToggle = () => setShowMore(!showMore);

  const sortedFailures = useMemo(() => {
    const failuresData = potentialSavingData?.failures || {};
    const allFailures = Object.keys(failuresData)
      .flatMap((key) => failuresData[key])
      .sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
    return allFailures;
  }, [potentialSavingData]);

  const latestFailures = sortedFailures.slice(0, 10);
  const remainingFailures = sortedFailures.slice(10);

  const renderTableRows = (failures: any) => {
    return failures?.map((failure: any, index: any) => (
      <Tr key={index}>
        <Td sx={{ fontSize: '14px' }}>{failure.asset_ui_name}</Td>
        <Td sx={{ fontSize: '14px', textTransform: 'capitalize' }}>{failure.component_ui_name}</Td>
        <Td sx={{ fontSize: '14px' }}>{failure.fault_name}</Td>
        <Td sx={{ fontSize: '14px' }}> {new Date(failure.last_health_status_change).toLocaleDateString()}</Td>
        <Td sx={{ fontSize: '14px' }}>
          {failure.end_date ? new Date(failure.end_date).toLocaleDateString() : onGoing}
        </Td>
        <Td sx={{ fontSize: '14px', textTransform: 'capitalize' }}> {failure.last_health_status}</Td>
        <Td sx={{ fontSize: '14px', backgroundColor: failure.downtime === null ? 'red.200' : 'transparent' }}>
          {failure.downtime !== null && `${failure.downtime} hours`}
        </Td>
        {dataType === cost && (
          <Td sx={{ fontSize: '14px', backgroundColor: failure.savings === null ? 'red.200' : 'transparent' }}>
            <Text display="inline-block">
              {failure.savings !== null && `${currency}${failure.savings.toLocaleString()}`}
            </Text>
          </Td>
        )}
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
      </Tr>
    ));
  };

  return (
    <>
      {isLoading ? (
        <Loading w="full" />
      ) : (
        <Box position="relative" width="100%">
          <Collapse in={isOpen} animateOpacity>
            <VStack borderRadius="md" width="100%" bg={colors.DMWhite} marginBottom="29px">
              <Box width="full" p={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4} mt={4} ml={5}>
                  <Text fontWeight={600} fontSize="0.875rem">
                    {t('machineFailuresTable.title')}
                  </Text>
                </Box>
                {sortedFailures.length > 0 ? (
                  <>
                    <Table variant="simple" mt={4}>
                      <Thead>
                        <Tr>
                          <Td fontWeight={600}>Machine</Td>
                          <Td fontWeight={600}>Component</Td>
                          <Td fontWeight={600}>Failure</Td>
                          <Td fontWeight={600}>Affecting Date</Td>
                          <Td fontWeight={600}>End Date</Td>
                          <Td fontWeight={600}>Health Status</Td>
                          <Td fontWeight={600}>Estimated Downtime</Td>
                          {dataType === cost && <Td fontWeight={600}>Potential Savings</Td>}
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {renderTableRows(latestFailures)}
                        {showMore && renderTableRows(remainingFailures)}
                      </Tbody>
                    </Table>
                    {remainingFailures.length > 0 && (
                      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                        <Button
                          onClick={handleShowMoreToggle}
                          bg={colors.black}
                          color={colors.DMWhite}
                          size="xs"
                          padding="0.5rem 1rem"
                          borderRadius="md"
                          _hover={{ bg: 'gray.700' }}
                          rightIcon={showMore ? <ChevronUpIcon boxSize={4} /> : <ChevronDownIcon boxSize={4} />}
                        >
                          {showMore ? 'Show Less' : 'Show More'}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Flex height="150px" alignItems="center" justifyContent="center">
                    <Text fontSize="0.875rem" color="gray.500">
                      {t('machineFailuresTable.noData')}
                    </Text>
                  </Flex>
                )}
              </Box>
            </VStack>
          </Collapse>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            position="absolute"
            bottom={isOpen ? -5 : -5}
          >
            <Button
              onClick={handleToggle}
              bg={colors.black}
              color={colors.DMWhite}
              size="xs"
              padding="0.5rem 1rem"
              borderRadius="md"
              _hover={{ bg: 'gray.700' }}
              rightIcon={isOpen ? <ChevronUpIcon boxSize={4} /> : <ChevronDownIcon boxSize={4} />}
            >
              {isOpen ? 'Close' : 'Expand'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
