import React, { useEffect, useState } from 'react';
import { Box, Center, HStack, Spinner, useToast, VStack } from '@chakra-ui/react';
import { DMPlusCircleIcon, DMUsersIcon } from 'src/components/Icons';
import { SearchControl } from 'src/components/SearchControl';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { UserRow } from 'src/pages/settings/pages/user-management/components/UserRow';
import { useLazyGetUsersQuery } from 'src/app/api/userApi';
import { useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { MANAGEMENT_ROLE, TOAST_STATUS } from 'src/const';
import { useTranslation } from 'react-i18next';
import { UserManagementHeader } from 'src/pages/settings/pages/user-management/components/UserManagementHeader';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { searchValueSelector } from 'src/app/store';
import { analytics } from 'src/analytics';
import { setSearchValue } from 'src/app/slices/applicationSlice';

export const UserManagementPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [fetchUsers, { data: users, isLoading }] = useLazyGetUsersQuery();
  const importOrder = [MANAGEMENT_ROLE.DM_Admin, MANAGEMENT_ROLE.Admin, MANAGEMENT_ROLE.Member];
  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  const sortedUsers = users
    ? [...users].sort((a: any, b: any) => {
        if (sortByObject[a.role] > sortByObject[b.role]) return 1;
        if (sortByObject[a.role] < sortByObject[b.role]) return -1;
        if (a.email > b.email) return 1;
        if (a.email < b.email) return -1;

        return 0;
      })
    : [];

  const onCreateUserClick = () => navigate(locations.protected.settings.createUser);

  const handleSearch = (value: string) => {
    const valueInLowerCase = value.toLowerCase();
    const filteredResults =
      sortedUsers &&
      sortedUsers.filter(
        (item) =>
          String(`${item.first_name} ${item.last_name}`).toLowerCase().includes(valueInLowerCase) ||
          item.email.toLowerCase().includes(valueInLowerCase) ||
          item.role.toLowerCase().includes(valueInLowerCase)
      );
    setSearchResults(filteredResults);
  };

  useEffect(() => {
    dispatch(setSearchValue(''));

    fetchUsers({})
      .unwrap()
      .catch((error) => {
        toast({
          status: TOAST_STATUS.Error,
          title: `Failed to load user information`,
          description: t('general.errors.communication'),
        });
        Sentry.captureException(
          error?.data?.message || error?.error || error?.message || error?.originalError || error
        );
      });
  }, []);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, users]);

  useEffect(() => {
    analytics.page({
      name: 'User Management',
      title: 'DataMind Dashboard 2.0 - User Management',
    });
  }, []);

  return (
    <VStack spacing="2.5rem" pt="5rem" pr={4}>
      <HStack w="full" justifyContent="space-between">
        <HStack>
          <DMUsersIcon fontSize="1.5rem" />
          <Box fontWeight={500}>User management</Box>
        </HStack>

        <HStack>
          <SearchControl isTopFixed={false} sticky={false} />
          <ButtonNaked label="New user" leftIcon={<DMPlusCircleIcon fontSize="1.5rem" />} onClick={onCreateUserClick} />
        </HStack>
      </HStack>

      <VStack spacing={2} w="full" background="#f0f1f2" borderRadius="8px" p={2}>
        {isLoading ? (
          <Center py={16}>
            <Spinner size="lg" />
          </Center>
        ) : (
          <>
            <UserManagementHeader />
            <>{searchResults && searchResults.map((user: any, index: number) => <UserRow key={index} user={user} />)}</>
          </>
        )}
      </VStack>
    </VStack>
  );
};
