import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { colors } from '../foundations/colors';

export const getSwitchStyles = (isDisabled: boolean) => {
  const trackColor = useColorModeValue('gray.200', 'whiteAlpha.400');
  const checkedTrackColor = colors.DMPrimaryColor;
  const disabledTrackColor = useColorModeValue('gray.300', 'gray.600');

  return {
    'span.chakra-switch__track': {
      backgroundColor: isDisabled ? disabledTrackColor : trackColor,
      '&[data-checked]': {
        backgroundColor: isDisabled ? disabledTrackColor : checkedTrackColor,
      },
    },
  };
};
