import React, { Suspense, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { Box, Center, Fade, useToast, VStack } from '@chakra-ui/react';
import { HEALTH_STATES, HEALTH_STATUS, TOAST_STATUS } from 'src/const';
import { useLazyGetDiagnosticsQuery, useLazyGetResourceQuery } from 'src/app/api/diagnosticApi';
import { Toolbar } from 'src/components/Toolbar';
import { MachineFailureRow } from 'src/pages/machines/components/failure-row/MachineFailureRow';
import { getAssetById, getAssets, getSiteById } from 'src/app/queries';
import { useTranslation } from 'react-i18next';
import { searchValueSelector } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { analytics } from 'src/analytics';
import { setSearchValue } from 'src/app/slices/applicationSlice';
import { FormattedText } from 'src/components/FormattedText';
import { Header } from 'src/components/Header';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from 'src/components/Loading';

export const MachineFailuresPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { siteId, machineId } = useParams<string>();
  const { site } = getSiteById(siteId);
  const { asset } = getAssetById(siteId, machineId);
  const { assets } = getAssets(siteId);
  const [fetchResource] = useLazyGetResourceQuery();
  const [fetchDiagnostics, { data: diagnostics, isLoading }] = useLazyGetDiagnosticsQuery();
  const latestAssetDiagnostics = diagnostics && diagnostics[diagnostics.length - 1][1];
  const searchValue = useSelector(searchValueSelector);
  const [searchResults, setSearchResults] = useState<any>([]);

  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const latestAssetDiagnosticsFlatten =
    latestAssetDiagnostics &&
    latestAssetDiagnostics.assets
      .map((asset: any) => {
        return asset.components
          .map((component: any) => {
            return component.diagnostics
              .map((diagnostic: any) => {
                return {
                  component_name: component.component_name,
                  component_id: component.component_id,
                  ...diagnostic,
                };
              })
              .flat();
          })
          .flat();
      })
      .flat();

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  latestAssetDiagnosticsFlatten &&
    latestAssetDiagnosticsFlatten.sort(
      (a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status]
    );

  useEffect(() => {
    dispatch(setSearchValue(''));

    if (siteId && machineId) {
      fetchDiagnostics(
        {
          site_id: siteId,
          assets_ids: machineId,
          from_date: asset.latest_diagnostic_created_at,
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(machineId)) {
            toast({
              id: machineId,
              status: TOAST_STATUS.Error,
              title: `Failed to load information for asset: ${asset?.asset_ui_name}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }

    analytics.page({
      name: `Failures`,
      title: 'DataMind Dashboard 2.0 - Failures',
      properties: {
        siteId,
        machineId,
        healthStatus: asset.health_status,
      },
    });
  }, [siteId, machineId]);

  useEffect(() => {
    if (latestAssetDiagnosticsFlatten?.length) {
      for (const item of [...latestAssetDiagnosticsFlatten]) {
        for (const source of item.sources_info) {
          if (source.evidence_resource_id) {
            fetchResource({
              resource_id: source.evidence_resource_id,
            });
          }
        }
      }
    }
  }, [diagnostics]);

  const handleSearch = (value: string) => {
    const valueInLowerCase = value.toLowerCase();
    const filteredResults =
      latestAssetDiagnosticsFlatten &&
      latestAssetDiagnosticsFlatten.filter(
        (item: any) =>
          item.component_name.toLowerCase().includes(valueInLowerCase) ||
          item.fault_type?.toLowerCase()?.includes(valueInLowerCase)
      );
    setSearchResults(
      filteredResults?.filter(
        (item: any) => ![HEALTH_STATUS.HEALTHY, HEALTH_STATUS.NOT_AVAILABLE].includes(item.health_status)
      )
    );
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, diagnostics]);

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {asset && site ? (
            <Toolbar
              siteId={siteId}
              siteName={site.site_name}
              machineName={asset.asset_ui_name}
              machineId={asset.asset_name}
              asset={asset}
              machines={assets}
              assetHealthStatus={asset.health_status}
              latestDiagnosticDate={asset.latest_diagnostic_created_at}
              machineSensorModal={
                <MachineSensorsModal
                  sensors={asset.sensors_health_status}
                  title={asset.asset_ui_name}
                  siteId={siteId!}
                  assetId={asset.asset_id}
                />
              }
              isTopFixed
            />
          ) : null}
        </ErrorBoundary>
      </Header>

      <Box {...containerProps}>
        {!isLoading ? (
          <Fade in={!isLoading} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
            <ErrorBoundary FallbackComponent={Fallback}>
              <VStack w="full" spacing={2} bgColor="#E6E8EC" p={2} borderRadius="lg">
                {searchResults?.length ? (
                  searchResults.map((item: any, index: number) => <MachineFailureRow key={index} item={item} />)
                ) : (
                  <Center py={4}>
                    The machine and all of its components are in{' '}
                    {<FormattedText label={HEALTH_STATES[asset.health_status].title} />} state
                  </Center>
                )}
              </VStack>
            </ErrorBoundary>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const containerProps = {
  w: 'full',
  p: { base: 0, xl: 8 },
  pt: { base: 24, xl: '5.5rem' },
  pr: 1,
};
