import React from 'react';
import {
  Box,
  Hide,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Show,
  VStack,
} from '@chakra-ui/react';
import { DMArrowRightIcon, DMSensorsIcon } from 'src/components/Icons';
import { INITIAL_FILTERS, SENSOR_STATUS } from 'src/const';

interface StatusFilterProps {
  totalAssetsSensors?: Record<string, number>;
  selectedSensors: string;
  setSelectedSensors: (state: string) => void;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
}

export const SensorsFilterDropdown = ({
  totalAssetsSensors,
  selectedSensors,
  setSelectedSensors,
  setSelectedStatuses,
}: StatusFilterProps) => {
  const onChange = (value: string | string[]) => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
    setSelectedSensors(String(value));
  };

  return (
    <>
      <Show below="xl">
        <VStack spacing={0}>
          <HStack
            justifyContent="space-between"
            w="full"
            h="3rem"
            pl={3}
            pr={4}
            onClick={() => onChange('all')}
            cursor="pointer"
            bg={selectedSensors === 'all' ? 'rgba(112, 113, 243, 0.12)' : undefined}
          >
            <HStack>
              <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="black" />
              <Box fontSize="0.875rem" fontWeight={600}>
                Show all
              </Box>
            </HStack>
            <Box fontSize="1.25rem" fontWeight={600}>
              {totalAssetsSensors && totalAssetsSensors.all}
            </Box>
          </HStack>

          <HStack
            justifyContent="space-between"
            w="full"
            h="3rem"
            pl={3}
            pr={4}
            cursor="pointer"
            onClick={() => onChange(SENSOR_STATUS.OFFLINE)}
            bg={selectedSensors === SENSOR_STATUS.OFFLINE ? 'rgba(112, 113, 243, 0.12)' : undefined}
          >
            <HStack>
              <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.critical" />
              <Box fontSize="0.875rem" fontWeight={600}>
                Offline sensors
              </Box>
            </HStack>
            <Box fontSize="1.25rem" fontWeight={600}>
              {totalAssetsSensors && totalAssetsSensors.critical}
            </Box>
          </HStack>

          <HStack
            justifyContent="space-between"
            w="full"
            h="3rem"
            pl={3}
            pr={4}
            cursor="pointer"
            onClick={() => onChange(SENSOR_STATUS.ONLINE)}
            bg={selectedSensors === SENSOR_STATUS.ONLINE ? 'rgba(112, 113, 243, 0.12)' : undefined}
          >
            <HStack>
              <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.healthy" />
              <Box fontSize="0.875rem" fontWeight={600}>
                Online sensors
              </Box>
            </HStack>
            <Box fontSize="1.25rem" fontWeight={600}>
              {totalAssetsSensors && totalAssetsSensors.healthy}
            </Box>
          </HStack>
        </VStack>
      </Show>

      <Hide below="xl">
        <Menu gutter={0}>
          <MenuButton h="3rem">
            <Box display="flex" px={4} alignItems="center">
              <DMSensorsIcon fontSize="1.5rem" mr={2} />
              <Box fontSize="0.625rem" textTransform="uppercase" fontWeight={500} letterSpacing={1.1}>
                {totalAssetsSensors && totalAssetsSensors.all} Sensors
              </Box>
              <DMArrowRightIcon fontSize="1rem" />
            </Box>
          </MenuButton>

          <MenuList borderTopRadius={0} pt={0}>
            <MenuOptionGroup type="radio">
              <MenuItemOption
                h="3rem"
                _hover={{ bg: 'rgba(112, 113, 243, 0.03)' }}
                _checked={{ bg: selectedSensors === 'all' && 'rgba(112, 113, 243, 0.12)' }}
                icon={null}
                onClick={() => onChange('all')}
                isChecked={selectedSensors === 'all'}
              >
                <HStack justifyContent="space-between" w="full">
                  <HStack>
                    <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="black" />
                    <Box fontSize="0.875rem" fontWeight={500}>
                      Show all
                    </Box>
                  </HStack>
                  <Box fontSize="0.875rem" fontWeight={500}>
                    {totalAssetsSensors && totalAssetsSensors.all}
                  </Box>
                </HStack>
              </MenuItemOption>

              <MenuItemOption
                h="3rem"
                _hover={{ bg: 'rgba(112, 113, 243, 0.03)' }}
                _checked={{ bg: selectedSensors === SENSOR_STATUS.OFFLINE && 'rgba(112, 113, 243, 0.12)' }}
                icon={null}
                onClick={() => onChange(SENSOR_STATUS.OFFLINE)}
                isChecked={selectedSensors === SENSOR_STATUS.OFFLINE}
              >
                <HStack justifyContent="space-between" w="full">
                  <HStack>
                    <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.critical" />
                    <Box fontSize="0.875rem" fontWeight={500}>
                      Offline sensors
                    </Box>
                  </HStack>
                  <Box fontSize="0.875rem" fontWeight={500}>
                    {totalAssetsSensors && totalAssetsSensors.critical}
                  </Box>
                </HStack>
              </MenuItemOption>

              <MenuItemOption
                h="3rem"
                _hover={{ bg: 'rgba(112, 113, 243, 0.03)' }}
                _checked={{ bg: selectedSensors === SENSOR_STATUS.ONLINE && 'rgba(112, 113, 243, 0.12)' }}
                icon={null}
                onClick={() => onChange(SENSOR_STATUS.ONLINE)}
                isChecked={selectedSensors === SENSOR_STATUS.ONLINE}
              >
                <HStack justifyContent="space-between" w="full">
                  <HStack>
                    <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.healthy" />
                    <Box fontSize="0.875rem" fontWeight={500}>
                      Online sensors
                    </Box>
                  </HStack>
                  <Box fontSize="0.875rem" fontWeight={500}>
                    {totalAssetsSensors && totalAssetsSensors.healthy}
                  </Box>
                </HStack>
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Hide>
    </>
  );
};
