const enum FONT_WEIGHT {
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 600,
}

export const textStyles = {
  subtitle: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: '1.5rem',
  },
  subtitleBold: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '1.5rem',
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: '1.375rem',
  },
  body3: {
    fontSize: '0.625rem',
    fontWeight: FONT_WEIGHT.REGULAR,
  },
};
