import React, { useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useLazyGetAppPreferencesQuery, useUpdateAppPreferencesMutation } from 'src/app/api/notificationApi';
import { NotificationsPreferencesAsset, NotificationsPreferencesEventType } from 'src/data/appPreferences';
import { ApplicationPreferencesHeader } from 'src/pages/settings/pages/application-preferences/components/ApplicationPreferencesHeader';
import { PreferencesInputGeneric } from 'src/pages/settings/pages/application-preferences/components/PreferencesInputGeneric';
import { ApplicationPreferencesList } from 'src/pages/settings/pages/application-preferences/components/ApplicationPreferencesList';
import { ApplicationPreferencesRow } from 'src/pages/settings/pages/application-preferences/components/ApplicationPreferencesRow';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { TOAST_STATUS } from 'src/const';
import { createPayload } from 'src/pages/settings/pages/application-preferences/utils';
import { colors } from 'src/theme/foundations/colors';

export const ApplicationPreferencesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const toast = useToast();
  const [updateAppPreferences, { isLoading: isUpdatingAppPreferences }] = useUpdateAppPreferencesMutation();
  const [fetchAppPreferences, { data: appPreferences, isLoading, isFetching }] = useLazyGetAppPreferencesQuery();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  useFieldArray({
    control,
    name: 'appPreferencesForm',
  });

  const onSubmit = async (values: FieldValues) => {
    const payload = createPayload(values, appPreferences);
    const currentTabPayload = payload[tabIndex];

    try {
      await updateAppPreferences([currentTabPayload]).unwrap();
      toast({
        status: TOAST_STATUS.Success,
        title: `Application preferences updated`,
      });
    } catch (e: any) {
      toast({
        status: TOAST_STATUS.Error,
        title: e?.data?.message || 'Failed to update application preferences',
      });
    }
  };

  const onSaveClick = async () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAppPreferences({}).unwrap();
    };

    fetchData();
  }, []);

  if (!isLoading && !appPreferences?.length) {
    return <Center py={8}>No data received</Center>;
  }

  return (
    <VStack alignItems="flex-start" spacing={2} pt={20} w="99%">
      <>
        {!isLoading && appPreferences ? (
          <>
            <form style={{ width: '100%', borderRadius: '8px' }}>
              <Tabs
                borderRadius="8px"
                isFitted
                variant="data-mind"
                h="full"
                w="full"
                onChange={(index) => {
                  setTabIndex(index);
                }}
              >
                <TabList bgColor="#f0f1f2" borderRadius="2xl" w="50%">
                  {appPreferences?.map((eventType: NotificationsPreferencesEventType, index: number) => (
                    <Tab
                      key={index}
                      isDisabled={!eventType.enabled}
                      borderRadius="2xl"
                      bg="#f0f1f2"
                      _selected={{ color: 'white', bg: '#021d3d' }}
                    >
                      {eventType?.ui_name}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels bg={colors.DMGray} p={3} borderRadius="8px" mt={2}>
                  {appPreferences?.map((eventType: NotificationsPreferencesEventType, index: number) => (
                    <TabPanel p={0} key={index}>
                      <ApplicationPreferencesHeader eventType={eventType} />
                      <VStack spacing={2}>
                        <ApplicationPreferencesList
                          eventType={eventType}
                          callback={(asset: NotificationsPreferencesAsset, index) => {
                            return (
                              <ApplicationPreferencesRow
                                key={index}
                                asset={asset}
                                callback={(parameter, index) => (
                                  <Box key={index} w="full" maxWidth="8rem">
                                    <PreferencesInputGeneric
                                      parameterType={parameter.type}
                                      value={parameter.value}
                                      isDisabled={isUpdatingAppPreferences || isFetching}
                                      name={`appPreferencesForm.${eventType?.name}.${asset?.asset_name}.${parameter?.name}`}
                                      register={register}
                                      errors={errors}
                                      validationSchema={{ required: 'Field is required' }}
                                    />
                                  </Box>
                                )}
                              />
                            );
                          }}
                        />
                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Notice
                    </AlertDialogHeader>

                    <AlertDialogBody>{appPreferences[tabIndex].info}</AlertDialogBody>

                    <AlertDialogFooter>
                      <HStack>
                        <Button variant="outline" size="md" borderRadius="full" onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          variant="data-mind"
                          size="md"
                          borderRadius="full"
                          isLoading={isUpdatingAppPreferences || isFetching}
                          onClick={() => {
                            onSaveClick();
                            onClose();
                          }}
                          isDisabled={!isValid}
                        >
                          Submit
                        </Button>
                      </HStack>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>

              <Box py={8}>
                <Button
                  variant="data-mind"
                  size="lg"
                  borderRadius="full"
                  isLoading={isUpdatingAppPreferences || isFetching}
                  onClick={appPreferences[tabIndex].info ? onOpen : onSaveClick}
                  isDisabled={!isValid}
                >
                  Save changes
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <Center py={16} w="full">
            <Spinner size="lg" />
          </Center>
        )}
      </>
    </VStack>
  );
};
