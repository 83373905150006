import React from 'react';
import { format } from 'date-fns';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { Box } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';

interface LastRecordedProps {
  date: Date | string;
}

export const LastRecorded = ({ date }: LastRecordedProps) => {
  return (
    <Box fontSize="0.75rem">
      <FormattedText
        label="general.toolbar.lastRecorded"
        values={{ date: format(new Date(date), REGULAR_DATE_FORMAT) }}
      />
    </Box>
  );
};
