import React, { useEffect, useState } from 'react';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react';
import { FieldValues, useForm } from 'react-hook-form';
import { TOAST_STATUS } from 'src/const';
import { DeleteUserDialog } from 'src/pages/settings/pages/user-management/components/DeleteUserDialog';
import { ExportSelect } from 'src/components/export-drawer/components/ExportSelect';
import { FormattedText } from 'src/components/FormattedText';
import { useCreateUserMutation, useUpdateUserMutation } from 'src/app/api/userApi';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById, selectCachedUserSiteIdsById } from 'src/app/queries';
import { DMImageUploadIcon } from 'src/components/Icons';
import { convertBase64 } from 'src/pages/settings/pages/user-management/utils';
import { useAuth } from 'src/providers/AuthProvider';
import { colors } from 'src/theme/foundations/colors';

type UserProfileFormData = {
  email: string;
  phone_number: string | null;
  first_name: string;
  last_name: string;
  password: string;
  photo: string | null;
  role: string;
  confirmPassword: string;
};
export const UserProfileForm = () => {
  const { user: firebaseUser } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const { userId: userIdParam } = useParams();

  const matchCreateUserLocation = useMatch(locations.protected.settings.createUser);
  const matchAccountSettingsLocation = useMatch(locations.protected.settings.account);
  const userId = matchAccountSettingsLocation || matchCreateUserLocation ? firebaseUser?.uid : userIdParam;
  const matchAccountLocation = useMatch(generatePath(locations.protected.settings.user, { userId }));

  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const user = useSelector((state) => {
    if (matchAccountLocation || matchAccountSettingsLocation) {
      return selectCachedUserDataById(state, userId!);
    }
    if (matchCreateUserLocation) {
      return selectCachedUserSiteIdsById(state, userId!);
    }
  });
  const [avatar, setAvatar] = useState<any>(user?.photo ? `data:image/png;base64,${user?.photo}` : undefined);

  const fileInputRef = React.useRef<any>();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserProfileFormData>({
    defaultValues: {
      email: '',
      phone_number: '',
      first_name: '',
      last_name: '',
      password: '',
      role: 'member',
      ...user,
    },
  });

  const onFileChange = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const fileBase64 = await convertBase64(file);
    setAvatar(fileBase64);
  };

  useEffect(() => {
    if (avatar) {
      setValue('photo', avatar.split(',')[1]);
    }
  }, [avatar]);

  const onSubmit = async (values: FieldValues) => {
    if (matchCreateUserLocation) {
      try {
        await createUser(values).unwrap();
        toast({
          status: TOAST_STATUS.Success,
          title: `User has been created`,
        });
        navigate(locations.protected.settings.userManagement);
      } catch (e: any) {
        toast({
          status: TOAST_STATUS.Error,
          title: e?.data?.message || 'Failed to create a new user',
        });
      }
    } else {
      let updateValues = values;

      if (!values.password) {
        const { password, ...restValues } = values;
        updateValues = restValues;
      }

      try {
        await updateUser({
          ...updateValues,
          uid: userId,
        }).unwrap();
        toast({
          status: TOAST_STATUS.Success,
          title: `User has been updated`,
        });
        if (!matchAccountSettingsLocation) {
          navigate(locations.protected.settings.userManagement);
        }
      } catch (e: any) {
        toast({
          status: TOAST_STATUS.Error,
          title: e?.data?.message || 'Failed to update user',
        });
      }
    }
  };

  return (
    <>
      <Box background={colors.DMGray} w="99%" borderRadius="16px" padding="24px">
        <Flex alignItems="center">
          <Box pr={4}>
            <Avatar src={avatar || undefined} w="7rem" h="7rem">
              <AvatarBadge boxSize="2.5em" bg="#7071f3" cursor="pointer" onClick={() => fileInputRef.current.click()}>
                <DMImageUploadIcon fontSize="1.5rem" />
              </AvatarBadge>
            </Avatar>
            <input type="file" accept="image/png" ref={fileInputRef} onChange={(event) => onFileChange(event)} hidden />
          </Box>
          <Box>
            <Text fontWeight={600}>
              {user?.first_name} {user?.last_name}
            </Text>
          </Box>
        </Flex>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '99%' }}>
        <Box background={colors.DMGray} marginBottom={4} padding="16px 16px 32px 18px" borderRadius="8px">
          <VStack alignItems="flex-start" spacing={4}>
            <Box fontWeight={600}>Profile</Box>

            <HStack spacing={6} alignItems="flex-start">
              <FormControl variant="floating" id="firstname" minW="20rem" isInvalid={!!errors.first_name}>
                <Input
                  type="text"
                  variant="data-mind-settings-inputs"
                  placeholder=" "
                  {...register('first_name', { required: 'First name is required' })}
                />
                <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage>
                <FormLabel>First name</FormLabel>
              </FormControl>

              <FormControl variant="floating" id="lastname" minW="20rem" isInvalid={!!errors.last_name}>
                <Input
                  type="text"
                  variant="data-mind-settings-inputs"
                  placeholder=" "
                  {...register('last_name', { required: 'Last name is required' })}
                />
                <FormErrorMessage>{errors.last_name && errors.last_name.message}</FormErrorMessage>
                <FormLabel>Last name</FormLabel>
              </FormControl>
            </HStack>

            <HStack spacing={6} alignItems="flex-start">
              <FormControl variant="floating" id="phone" minW="20rem" isInvalid={!!errors.phone_number}>
                <Input
                  type="text"
                  variant="data-mind-auth"
                  placeholder=" "
                  {...register('phone_number', {
                    pattern: {
                      value: /^\+[1-9]\d{1,15}$/,
                      message: 'Invalid phone number',
                    },
                  })}
                />
                <FormErrorMessage>{errors.phone_number && errors.phone_number.message}</FormErrorMessage>
                <FormLabel>Phone</FormLabel>
              </FormControl>

              <FormControl variant="floating" id="email" minW="20rem" isInvalid={!!errors.email}>
                <Input
                  type="text"
                  variant="data-mind-settings-inputs"
                  placeholder=" "
                  isDisabled={!matchCreateUserLocation}
                  {...register('email', { required: 'Email address is required' })}
                />
                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                <FormLabel>Email</FormLabel>
              </FormControl>
            </HStack>

            {matchAccountSettingsLocation ? null : (
              <HStack spacing={6} w="20rem" alignItems="flex-start">
                <ExportSelect
                  placeholder="Role"
                  values={['member', 'admin']}
                  defaultValue={'member'}
                  register={register}
                  errors={errors}
                />
              </HStack>
            )}
          </VStack>
        </Box>
        <Box pt={12} borderRadius="8px" background={colors.DMGray} padding="16px 16px 32px 18px" width="100%">
          <Box fontWeight={600} pb={4}>
            Password
          </Box>

          <HStack spacing={8} w="full" alignItems="flex-start">
            <FormControl variant="floating" isInvalid={!!errors.password} w="20rem">
              <Input
                type="password"
                variant="data-mind-settings-inputs"
                placeholder=" "
                {...register('password', {
                  required: !matchCreateUserLocation ? false : 'New password is required',
                })}
              />
              <FormLabel>
                <FormattedText label="auth.resetPassword.change.form.newPassword" />
              </FormLabel>
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>

            <FormControl variant="floating" isInvalid={!!errors.confirmPassword} w="20rem">
              <Input
                type="password"
                variant="data-mind-settings-inputs"
                placeholder=" "
                {...register('confirmPassword', {
                  required: !matchCreateUserLocation ? false : 'Confirm password is required',
                  validate: (value: string) => {
                    if (watch('password') !== value) {
                      return 'Your passwords do not match';
                    }
                  },
                })}
              />
              <FormLabel>
                <FormattedText label="auth.resetPassword.change.form.confirmPassword" />
              </FormLabel>
              <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
            </FormControl>
          </HStack>
        </Box>

        <HStack spacing={4} pt={4}>
          <Button variant="data-mind" type="submit" size="lg" borderRadius="full" isLoading={isCreating || isUpdating}>
            {matchCreateUserLocation ? 'Add User' : 'Save Changes'}
          </Button>

          {!(matchCreateUserLocation || matchAccountSettingsLocation) ? <DeleteUserDialog userId={userId} /> : null}
        </HStack>
      </form>
    </>
  );
};
