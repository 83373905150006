import React from 'react';
import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { LegendContent } from 'src/components/legend/components/LegendContent';
import { DMInfoCircleIcon } from 'src/components/Icons';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { FormattedText } from 'src/components/FormattedText';

export const LegendModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack spacing={0} onClick={onOpen} cursor="pointer">
        <Tooltip label="Health trend legend" placement="right" borderRadius="md">
          <IconButton
            aria-label="Info"
            size="lg"
            variant="unstyled"
            icon={<DMInfoCircleIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
          />
        </Tooltip>

        <Show below="xl">
          <Text>Health trend legend</Text>
        </Show>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="outside" isCentered>
        <ModalOverlay />
        <ModalContent pb={4} borderRadius="lg">
          <ModalHeader fontWeight={400} fontSize="1rem" px={4}>
            <Box textStyle="subtitleBold">
              <FormattedText label="general.legend.title" />
            </Box>
          </ModalHeader>
          <ModalCloseButton top={3} right={3} />
          <ModalBody p={4}>
            <LegendContent />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
