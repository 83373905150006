import { baseQuery } from 'src/app/baseQuery';
import { dispatch } from 'src/app/store';
import { setMfaExpired } from 'src/app/slices/applicationSlice';

export const baseQueryWithStatusCheck = async (args: any, api: any, extraOptions: any) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    dispatch(setMfaExpired(true));
  }

  return result;
};
