import React, { useEffect } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Show,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { DMCloseIcon, DMHamburgerIcon } from 'src/components/Icons';
import { NavigationMenu } from 'src/components/NavigationMenu';
import { useLocation } from 'react-router-dom';
import { theme } from 'src/theme';

export const NavigationMenuMobile = () => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  useEffect(() => (isNotMobileResolution ? onClose() : void 0), [isNotMobileResolution]);

  useEffect(() => onClose(), [location]);

  return (
    <>
      <Show below="xl">
        <Box w="3.5rem" h="3.5rem" bgColor="#021d3d" onClick={onOpen}>
          <Center h="full">
            {isOpen ? (
              <DMCloseIcon color="white" fontSize="1.5rem" />
            ) : (
              <DMHamburgerIcon color="white" fontSize="1.5rem" />
            )}
          </Center>
        </Box>
      </Show>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay top="3.5rem" bgColor="rgba(2, 29, 61, 0.5)" />
        <DrawerContent top="3.5rem!important" bgColor="#021d3d" maxW="270px" borderRightRadius="2xl">
          <DrawerBody top="3.5rem" px={1} py={4}>
            <NavigationMenu />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
