import React, { useEffect } from 'react';
import { ResetPasswordForm } from 'src/pages/auth/components/ResetPasswordForm';
import { FormLayout } from 'src/pages/auth/components/FormLayout';
import { analytics } from 'src/analytics';

export const ResetPasswordPage = () => {
  useEffect(() => {
    analytics.page({
      name: 'Reset password',
      title: 'DataMind Dashboard 2.0 - Reset password',
    });
  }, []);
  return (
    <FormLayout title="auth.resetPassword.email.heading" subtitle="auth.resetPassword.email.description">
      <ResetPasswordForm />
    </FormLayout>
  );
};
