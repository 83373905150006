import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, HStack } from '@chakra-ui/react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { BarSeriesOption } from 'echarts/charts';
import { MachineStatusData, useLazyGetMachineStatusStatisticsQuery } from 'src/app/api/statisticsApi';
import {
  calculatePercentage,
  extractYears,
  filterDataBySpecificMonth,
  getOption,
  getSeries,
  groupDaysIntoColumns,
  mergeEntitiesByMonth,
} from 'src/pages/machines/statistics/machine-status-chart/utils';
import { Loading } from 'src/components/Loading';
import { MachineStatusChartTitle } from 'src/pages/machines/statistics/machine-status-chart/MachineStatusChartTitle';
import { ActiveMonthLink } from 'src/pages/machines/statistics/machine-status-chart/ActiveMonthLink';
import { DropdownOptionGroupMenu } from 'src/components/DropdownOptionGroupMenu';

export const LATEST_YEAR_OPTION = '1 year';

export const MachineStatusChart = () => {
  const { siteId } = useParams();
  const [fetchMachineStatusStatistics, { data: machineStatusData }] = useLazyGetMachineStatusStatisticsQuery({});
  const [activeMonth, setActiveMonth] = useState<string | null>(null);
  const [dataByMonth, setDataByMonth] = useState<MachineStatusData>([]);
  const [seriesByMonth, setSeriesByMonth] = useState<Array<BarSeriesOption>>([]);
  const [series, setSeries] = useState<Array<BarSeriesOption> | undefined>();
  const [dataset, updateDataset] = useState<MachineStatusData>([]);
  const [yearOptions, setYearOptions] = useState<Array<string>>([]);
  const [selectedYear, setSelectedYear] = useState<string>();

  const onChartClick = (e: { name?: string }) => {
    const { name } = e;
    if (!activeMonth && name) {
      setActiveMonth(name);
    }
  };

  const resetData = () => {
    setActiveMonth(null);
    setSeries(seriesByMonth);
    updateDataset(dataByMonth);
  };

  const prepareChartData = (selectedYear?: string) => {
    const availableYears = extractYears(machineStatusData);
    let availableSeries;

    const dataGroupedByMonth = mergeEntitiesByMonth(
      machineStatusData,
      availableYears,
      selectedYear || LATEST_YEAR_OPTION
    );

    if (dataGroupedByMonth.length) {
      const percentageByMonth = calculatePercentage(dataGroupedByMonth);
      availableSeries = getSeries(percentageByMonth, dataGroupedByMonth);
    }

    setYearOptions(availableYears.map(String));
    setDataByMonth(dataGroupedByMonth);
    setSeriesByMonth(availableSeries || []);
    setSeries(availableSeries || []);
    updateDataset(dataGroupedByMonth);
  };

  useEffect(() => (selectedYear ? prepareChartData(selectedYear) : undefined), [selectedYear]);

  useEffect(() => {
    if (machineStatusData) {
      prepareChartData();
    }
  }, [machineStatusData]);

  useEffect(() => {
    if (activeMonth && machineStatusData) {
      const filteredDataByMonth = filterDataBySpecificMonth(activeMonth, machineStatusData);
      const groupedByColumns = groupDaysIntoColumns(filteredDataByMonth);
      const percentageByColumns = calculatePercentage(groupedByColumns);
      const series = getSeries(percentageByColumns, groupedByColumns);

      setSeries(series);
      updateDataset(groupedByColumns);
    }
  }, [activeMonth]);

  useEffect(() => {
    if (machineStatusData) {
      prepareChartData();
    }
  }, [machineStatusData]);

  useEffect(() => {
    fetchMachineStatusStatistics({ site_id: siteId });
  }, [siteId]);

  return (
    <Box w="full" bg="white" borderRadius="md">
      {series ? (
        <>
          <HStack px={4} py={4} justifyContent="space-between">
            <HStack alignItems="center">
              <MachineStatusChartTitle activeMonth={activeMonth} onClick={resetData} />
              <ActiveMonthLink activeMonth={activeMonth} />
            </HStack>
            {yearOptions && !activeMonth && series.length ? (
              <DropdownOptionGroupMenu
                defaultValue={LATEST_YEAR_OPTION}
                options={[LATEST_YEAR_OPTION, ...yearOptions]}
                onChange={(value) => {
                  setSelectedYear(value as string);
                  resetData();
                }}
              />
            ) : null}
          </HStack>
          {series.length ? (
            <ReactECharts
              className="machine-status-chart"
              echarts={echarts}
              option={getOption(dataset, series, activeMonth)}
              onEvents={{
                click: onChartClick,
              }}
            />
          ) : (
            <Box w="full" py="8.6rem" px={4} textAlign="center" color="gray.500" fontSize="sm">
              Insufficient data available to generate the chart
            </Box>
          )}
        </>
      ) : (
        <Loading py="160px" />
      )}
    </Box>
  );
};
