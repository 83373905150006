import { FieldValues } from 'react-hook-form';
import { NotificationsPreferencesAsset, NotificationsPreferencesEventType } from 'src/data/appPreferences';

export const createPayload = (values: FieldValues, appPreferences: NotificationsPreferencesEventType[]) => {
  return appPreferences.map((eventType: NotificationsPreferencesEventType) => {
    const assets = eventType?.assets;
    return {
      ...eventType,
      assets: assets?.map((asset: NotificationsPreferencesAsset) => {
        return {
          ...asset,
          parameters: asset.parameters.map((parameter) => {
            return {
              ...parameter,
              value: values.appPreferencesForm[eventType?.name][asset?.asset_name][parameter?.name],
            };
          }),
        };
      }),
    };
  });
};
