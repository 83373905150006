import { HEALTH_STATUS } from 'src/const';

export const healthStatusMap: Record<number | string, string> = {
  1: HEALTH_STATUS.CRITICAL,
  2: HEALTH_STATUS.ALARM,
  3: HEALTH_STATUS.MONITOR,
  4: HEALTH_STATUS.HEALTHY,
  5: HEALTH_STATUS.NOT_AVAILABLE,
};

export const healthStatusToNumberMap: Record<string | number, number> = {
  [HEALTH_STATUS.CRITICAL]: 1,
  [HEALTH_STATUS.ALARM]: 2,
  [HEALTH_STATUS.MONITOR]: 3,
  [HEALTH_STATUS.HEALTHY]: 4,
  [HEALTH_STATUS.NOT_AVAILABLE]: 5,
};
