import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  isFirebaseRequesting: boolean;
  searchValue: string;
  notificationsFeatureFlag: boolean;
  isMfaExpired: boolean;
} = {
  isFirebaseRequesting: true,
  searchValue: '',
  notificationsFeatureFlag: false,
  isMfaExpired: false,
};

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setIsFirebaseRequesting: (state, action) => {
      state.isFirebaseRequesting = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setNotificationFeatureFlag: (state, action) => {
      state.notificationsFeatureFlag = action.payload;
    },
    setMfaExpired: (state, action) => {
      state.isMfaExpired = action.payload;
    },
  },
});

export const { setIsFirebaseRequesting, setSearchValue, setNotificationFeatureFlag, setMfaExpired } = appSlice.actions;
