import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { DMStatusNotAvailableIcon } from 'src/components/Icons';
import { FeedbackThumbs } from 'src/pages/machines/components/FeedbackThumbs';
import { PossibleCause } from 'src/types/diagnostics';

interface CausesListProps {
  diagnosticId: string;
  possibleCauses: PossibleCause[];
}

export const CausesList = ({ diagnosticId, possibleCauses }: CausesListProps) => {
  return possibleCauses?.length ? (
    <Box w={{ base: 'full', xl: '50%' }}>
      <HStack justifyContent="space-between" pb={2}>
        <HStack fontWeight={600} spacing={2} alignItems="center">
          <DMStatusNotAvailableIcon color="#021d3d" />
          <Box>Cause</Box>
        </HStack>
        <Box fontSize="0.875rem">Confirm?</Box>
      </HStack>

      <VStack spacing={0}>
        {possibleCauses.map((item: PossibleCause, index: number) => (
          <HStack
            justifyContent="space-between"
            key={index}
            fontSize="0.875rem"
            w="full"
            py={2}
            px={3}
            borderRadius="lg"
            bgColor={index % 2 === 0 ? '#F0F2F7' : undefined}
          >
            <Box>{item.description}</Box>
            <Box>
              <FeedbackThumbs
                feedback={item.feedback}
                feedbackType={'cause'}
                description={item.description}
                diagnosticId={diagnosticId}
              />
            </Box>
          </HStack>
        ))}
      </VStack>
    </Box>
  ) : null;
};
