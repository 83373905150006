import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Site } from 'src/types/sites';
import { SitesRow } from 'src/pages/sites/components/SitesRow';

type SitesListProps = {
  sites: Site[];
  activeTabIndex?: number;
};

const SitesList = ({ sites, activeTabIndex }: SitesListProps) => {
  return (
    <VStack spacing="1rem" position="relative" p={4} pt={{ base: 4, xl: 0 }} bgColor="#E6E8EC" borderBottomRadius="8px">
      {sites ? sites.map((item, index) => <SitesRow key={index} item={item} activeTabIndex={activeTabIndex} />) : null}
    </VStack>
  );
};

export default SitesList;
