import { SENSOR_TYPE } from 'src/const';
import { Annotations, Dash, Shape } from 'plotly.js';

export const noDataPlaceholderAnnotation: Partial<Annotations> = {
  text: 'No sensor selected. Please select a sensor to view data.',
  xref: 'paper',
  showarrow: false,
  arrowhead: 2,
  font: {
    color: '#021d3d',
    family: 'Montserrat',
    size: 16,
  },
  xanchor: 'center',
  yanchor: 'middle',
};

export const initialSubPlotTitleAnnotations: Partial<Annotations>[] = [
  {
    xref: 'paper',
    yref: 'paper',
    x: -0.001,
    xanchor: 'left',
    y: 0.31,
    yanchor: 'bottom',
    text: 'Current / Speed / Oil Quality',
    showarrow: false,
    font: {
      color: '#021d3d',
      family: 'Montserrat',
      size: 13,
    },
    borderpad: 4,
    bgcolor: 'rgba(2, 29, 61, 0.02)',
    bordercolor: 'rgba(2, 29, 61, 0.1)',
    borderwidth: 1,
  },

  {
    xref: 'paper',
    yref: 'paper',
    x: -0.001,
    xanchor: 'left',
    y: 0.66,
    yanchor: 'bottom',
    text: 'Temperature / Pressure',
    showarrow: false,
    font: {
      color: '#021d3d',
      family: 'Montserrat',
      size: 13,
    },
    borderpad: 4,
    bgcolor: 'rgba(2, 29, 61, 0.02)',
    bordercolor: 'rgba(2, 29, 61, 0.1)',
    borderwidth: 1,
  },

  {
    xref: 'paper',
    yref: 'paper',
    x: -0.001,
    xanchor: 'left',
    y: 1.005,
    yanchor: 'bottom',
    text: 'Vibration',
    showarrow: false,
    font: {
      color: '#021d3d',
      family: 'Montserrat',
      size: 13,
    },
    borderpad: 4,
    bgcolor: 'rgba(2, 29, 61, 0.02)',
    bordercolor: 'rgba(2, 29, 61, 0.1)',
    borderwidth: 1,
  },
];

export const getSubPlotTitleAnnotations = (
  hasVibrationSensorTraces: boolean,
  hasTemperaturePressureSensorTraces: boolean,
  hasConditionSpeedCurrentSensorTraces: boolean,
  yAxisDomains?: Array<any>
) => {
  const annotations: Partial<Annotations>[] = [];

  if (hasConditionSpeedCurrentSensorTraces) {
    annotations.push({
      ...initialSubPlotTitleAnnotations[0],
      y: yAxisDomains?.length && yAxisDomains?.length >= 1 ? yAxisDomains[0][1] : initialSubPlotTitleAnnotations[0].y,
    });
  }

  if (hasTemperaturePressureSensorTraces) {
    annotations.push({
      ...initialSubPlotTitleAnnotations[1],
      y: yAxisDomains?.length && yAxisDomains?.length >= 2 ? yAxisDomains[1][1] : initialSubPlotTitleAnnotations[1].y,
    });
  }

  if (hasVibrationSensorTraces) {
    annotations.push({
      ...initialSubPlotTitleAnnotations[2],
      y: yAxisDomains?.length && yAxisDomains?.length >= 3 ? yAxisDomains[2][1] : initialSubPlotTitleAnnotations[2].y,
    });
  }

  return annotations || [];
};

export const getTrendOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend !== undefined)
    .reduce((acc: any, current: any) => {
      if (!acc.includes(current.trend)) {
        acc.push(current.trend);
      }
      return acc;
    }, [])
    .map((name: any) => {
      return {
        name: name,
        selected: true,
      };
    });
};

export const getVibrationSensorOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend !== undefined)
    .reduce((acc: any, current: any) => {
      if (!acc.includes(current.name)) {
        acc.push(current.name);
      }
      return acc;
    }, [])
    .map((name: any) => {
      return {
        name: name,
        selected: true,
      };
    });
};

export const getRestSensorOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend === undefined)
    .map((trace: any) => {
      return {
        name: trace.name,
        selected: true,
      };
    });
};

export const updateOptions = (options: any, value: any) => {
  return options.map((option: any) => {
    if (option.name === value.name) {
      return {
        ...option,
        selected: !value.selected,
      };
    } else {
      return option;
    }
  });
};

export const mergeArrays = (firstArray: any, secondArray: any) => {
  firstArray = firstArray || [];
  secondArray = secondArray || [];

  return [...firstArray, ...secondArray];
};

export const patchTraces = (traces: any, options: any, property = 'name') => {
  return traces.map((trace: any) => {
    const item = options?.find((option: any) => option.name === trace[property]);
    return item ? { ...trace, selected: item.selected } : trace;
  });
};

export const calculateDomains = (a: boolean, b: boolean, c: boolean) => {
  const trueCount = [a, b, c].filter(Boolean).length;
  const domains = [];

  if (trueCount === 1) {
    if (a) domains.push([0, 1]);
    if (b) domains.push([0, 1]);
    if (c) domains.push([0, 1]);
  } else if (trueCount === 2) {
    if (b && c) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }

    if (a && c) {
      domains.push([0, 0.48]);
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }

    if (a && b) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
      domains.push([0, 0.48]);
    }
  } else if (trueCount === 3) {
    domains.push([0, 0.3]);
    domains.push([0.35, 0.65]);
    domains.push([0.7, 1]);
  }

  return domains;
};

export const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

export enum SENSOR_FUSION_OPTIONS_TYPE {
  Trends = 'trends',
  VibrationSensors = 'vibrationSensors',
  OperationalSensors = 'operationalSensors',
}

export const isVibrationSensor = (item: any) => {
  return [SENSOR_TYPE.Vibration, SENSOR_TYPE.Vibrations].includes(item.sensorType.toLowerCase());
};

export const isTemperaturePressureSensor = (item: any) => {
  return ['oil-temperature', 'oil temperature', 'pressure', 'temperature', 'temprature'].includes(
    item.sensorType.toLowerCase()
  );
};

export const isConditionSpeedCurrentSensor = (item: any) => {
  return ['oil-condition', 'oil condition', 'oil-quality', 'speed', 'current'].includes(item.sensorType.toLowerCase());
};

export const getTracesBySensorType = (traces: any, conditionFunc: (item: any) => boolean) => {
  return traces.filter(conditionFunc);
};

export const mergeTraces = (dataRms: any, dataPi: any) => {
  let dataPiTraces;
  let dataRmsTraces;

  if (dataPi?.length) {
    dataPiTraces = [...dataPi.map((item: any) => item.measurements[0])];
  }

  if (dataRms?.length) {
    dataRmsTraces = [
      ...dataRms
        .map((item: any) => {
          return item.measurements.map((measurement: any) => ({
            ...measurement,
            trend: item.name,
          }));
        })
        .flat(),
    ];
  }

  return mergeArrays(dataPiTraces, dataRmsTraces)
    .map((item: any) => ({
      ...item,
      id: generateUniqueId(),
    }))
    .map((item: any) => {
      if (isVibrationSensor(item)) {
        return {
          ...item,
          selected: true,
          yaxis: 'y3',
        };
      }

      if (isTemperaturePressureSensor(item)) {
        return {
          ...item,
          selected: true,
          yaxis: 'y2',
        };
      }

      if (isConditionSpeedCurrentSensor(item)) {
        return {
          ...item,
          selected: true,
          yaxis: 'y1',
        };
      }
    })
    .filter((item: any) => item !== undefined);
};

export const trimMiddle = (string: string, maxLength: number) => {
  const halfLength = Math.floor((maxLength - 3) / 2);

  if (string.length <= maxLength) {
    return string;
  }

  return string.substring(0, halfLength) + '...' + string.substring(string.length - halfLength);
};

export const patchTracesByTrend = (trendOptions: any, patchedTraces: any) => {
  let result;

  if (trendOptions[0].selected && trendOptions[1].selected) {
    result = patchedTraces;
  } else if (!trendOptions[0].selected && trendOptions[1].selected) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend && trace.trend === trendOptions[0].name) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (trendOptions[0].selected && !trendOptions[1].selected) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend && trace.trend === trendOptions[1].name) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (!(trendOptions[0].selected && trendOptions[1].selected)) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (!(trendOptions[0].selected && !trendOptions[1].selected)) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  }

  return result;
};

export function findDifferences(array1: any, array2: any) {
  const map1 = new Map();
  array1.forEach((item: any) => {
    map1.set(item.id, item.value);
  });

  const result: any[] = [];

  array2.forEach((item: any) => {
    if (map1.has(item.id)) {
      const diff = item.value - map1.get(item.id);
      result.push({ id: item.id, value: diff });
    } else {
      return;
    }
  });

  return result;
}

export const getGrayBackgroundShape = (yAxisDomains: Array<any>): Partial<Shape> => {
  return {
    type: 'rect',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: (() => {
      if (yAxisDomains.length === 2 || yAxisDomains.length === 3) {
        return yAxisDomains[1][0];
      } else {
        return 0;
      }
    })(),
    x1: 1,
    y1: (() => {
      if (yAxisDomains.length === 2 || yAxisDomains.length === 3) {
        return yAxisDomains[1][1];
      } else {
        return 0;
      }
    })(),
    fillcolor: 'rgba(2, 29, 61, 0.03)',
    line: {
      width: 0,
    },
  };
};

export const getSelectionLineShape = (selection: any, dash: Dash): Partial<Shape> => {
  return {
    type: 'line',
    x0: selection[0].x,
    x1: selection[0].x,
    y0: 0,
    y1: 1,
    xref: 'x',
    yref: 'paper',
    line: {
      color: 'rgba(2, 29, 61, 1)',
      width: 3,
      dash,
    },
  };
};
