import { Box, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useLazyGetCommonFailuresStatisticsQuery } from 'src/app/api/statisticsApi';
import { useParams } from 'react-router-dom';
import { DropdownOptionGroupMenu } from 'src/components/DropdownOptionGroupMenu';
import { StatsTable } from './StatsTable';
import { Loading } from 'src/components/Loading';
import { getFormattedDateRange } from 'src/utils';

interface CommonFailuresTableProps {
  siteAssetsUINames?: any;
}

export const CommonFailuresTable: React.FC<CommonFailuresTableProps> = ({ siteAssetsUINames }) => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const [fetchCommonFailuresData, { data: commonFailuresData, isLoading }] = useLazyGetCommonFailuresStatisticsQuery(
    {}
  );

  const initialTimeRange = Object?.keys(commonFailuresData || {})[3] || '365';
  const [selectedRange, setSelectedRange] = useState(initialTimeRange);
  const [selectedMachine, setSelectedMachine] = useState('All assets');
  const [formattedDateRange, setFormattedDateRange] = useState(getFormattedDateRange(initialTimeRange));

  const failures = commonFailuresData?.[selectedRange] || [];
  const [filteredFailures, setFilteredFailures] = useState(failures);

  useEffect(() => {
    if (siteId && selectedRange) {
      fetchCommonFailuresData({ site_id: siteId });
    }
  }, [siteId, selectedRange]);

  useEffect(() => {
    if (selectedMachine === 'All assets') {
      setFilteredFailures(failures); // Show all failures when 'All Machines' is selected
    } else {
      const filtered = failures.filter((failure: any) => failure.asset_ui_name === selectedMachine);
      setFilteredFailures(filtered);
    }
  }, [selectedMachine, failures]);

  useEffect(() => {
    if (selectedRange) {
      const formattedDate = getFormattedDateRange(selectedRange);
      setFormattedDateRange(formattedDate);
    }
  }, [selectedRange]);

  const handleSelectChange = (selectedValue: any) => {
    const numberOfDays = parseInt(selectedValue, 10);
    const formattedDate = getFormattedDateRange(numberOfDays);

    setSelectedRange(selectedValue);
    setFormattedDateRange(formattedDate);
  };

  const handleSelectMachineChange = (selectedValue: any) => {
    setSelectedMachine(selectedValue);
  };

  return (
    <>
      <Box bg="#fffefe" width="50%" m={4} p={4} borderRadius="md" alignItems="center">
        <HStack justifyContent="space-between" width="100%">
          <HStack fontWeight={600} fontSize="0.875rem" spacing={3}>
            <Box px={4}>
              <Box> {t('statistics.commonFailures.title')}</Box>
              <Text fontSize="0.7rem" fontWeight={500}>
                {formattedDateRange}
              </Text>
            </Box>
          </HStack>

          <HStack spacing={4}>
            <DropdownOptionGroupMenu
              defaultValue={selectedMachine}
              options={['All assets', ...(siteAssetsUINames || [])]}
              onChange={handleSelectMachineChange}
            />
            <DropdownOptionGroupMenu
              defaultValue={initialTimeRange}
              options={Object.keys(commonFailuresData || {})}
              onChange={handleSelectChange}
            />
          </HStack>
        </HStack>

        {isLoading ? <Loading /> : <StatsTable failures={filteredFailures} />}
      </Box>
    </>
  );
};
