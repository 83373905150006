import { Box, Hide, HStack, Show, VStack } from '@chakra-ui/react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { css } from '@emotion/react';
import { AppLogo, RazorLabsLogo } from 'src/components/Logo';
import { VideoBackground } from 'src/layouts/components/VideoBackground';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';

export function AuthLayout() {
  const location = useLocation();
  const { user } = useAuth();

  if (
    user &&
    ![locations.public.login, locations.public.secondFactorMethod, locations.public.secondFactorVerification].includes(
      location.pathname as string
    )
  ) {
    return <Navigate to={locations.protected.sites.index} state={{ from: location }} replace />;
  }

  return (
    <HStack css={style} spacing={0}>
      <Box w={{ base: 'full', xl: '50%' }} h="full">
        <Box position="absolute" top={{ base: 0, xl: '60px' }} left={{ base: 0, xl: '70px' }}>
          <Hide below="xl">
            <AppLogo size="184px" />
          </Hide>

          <Show below="xl">
            <VideoBackground />
          </Show>
        </Box>

        <Outlet />
      </Box>

      <Hide below="xl">
        <Box w="50%" h="full">
          <VideoBackground />
          <VStack h="100%" justifyContent="center">
            <RazorLabsLogo />
          </VStack>
        </Box>
      </Hide>
    </HStack>
  );
}

const style = css`
  width: 100%;
  height: 100vh;
`;
