import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { DMArrowDownIcon, DMArrowUpIcon, DMCalendarInfo } from 'src/components/Icons';
import { format, sub } from 'date-fns';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { CalendarPopover } from 'src/features/visual-ai/components/CalendarPopover';

interface ChartDatePickerProps extends BoxProps {
  isInline?: boolean | undefined;
  fromDate: Date | undefined;
  toDate: Date;
  minDate: Date | undefined;
  updateFromDate: (date: Date | undefined) => void;
  isFetching: boolean;
  buttonSize?: string;
}

export const ChartDatePicker = ({
  isInline = false,
  fromDate,
  toDate,
  minDate,
  updateFromDate,
  isFetching,
  buttonSize = 'lg',
  ...rest
}: ChartDatePickerProps) => {
  const fromDateString = fromDate
    ? format(fromDate, REGULAR_DATE_FORMAT)
    : format(sub(new Date(), { days: 20 }), REGULAR_DATE_FORMAT);
  const toDateString = format(toDate, REGULAR_DATE_FORMAT);

  return (
    <Box position="absolute" right="0" top="-4.2rem" zIndex={1} className="visual-ai-datepicker" {...rest}>
      <Popover>
        {({ isOpen, onClose: onCloseChartDatePickerPopover }) => (
          <>
            <PopoverTrigger>
              <Button
                px={4}
                size={buttonSize}
                bgColor={isInline ? 'transparent' : isOpen ? 'rgba(112, 113, 243, 1)' : 'white'}
                color={isInline ? undefined : isOpen ? 'white' : 'rgb(26, 32, 44)'}
                fontSize="0.875rem"
                border={isInline ? undefined : isOpen ? '1px solid transparent' : '1px solid rgba(177, 185, 195, 1)'}
                leftIcon={<DMCalendarInfo fontSize="1.5rem" />}
                rightIcon={isOpen ? <DMArrowUpIcon fontSize="1.5rem" /> : <DMArrowDownIcon fontSize="1.5rem" />}
                isLoading={isInline ? undefined : isFetching}
                isDisabled={isFetching}
                _hover={
                  isInline
                    ? {
                        bgColor: 'transparent',
                      }
                    : {
                        bgColor: 'rgba(112, 113, 243, 0.9)',
                        color: 'white',
                        border: '1px solid rgba(112, 113, 243, 1)',
                      }
                }
              >
                {fromDateString} - {toDateString}
              </Button>
            </PopoverTrigger>

            <PopoverContent w="full" borderColor="rgba(177, 185, 195, 1)">
              <PopoverArrow />
              <PopoverBody p={0} w="full">
                <HStack justifyContent="space-between" p={4} fontSize="0.875rem" fontWeight="600">
                  <Box>Filter dates</Box>
                  <Button
                    variant="unstyled"
                    color="rgba(112, 113, 243, 1)"
                    fontSize="0.875rem"
                    size="xs"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    onClick={() => updateFromDate(sub(new Date(), { months: 2 }))}
                  >
                    Reset
                  </Button>
                </HStack>

                <Box py={2} pb={8}>
                  <VStack px={4} spacing={4}>
                    <Box w="full">
                      <Box fontSize="0.75rem" pl={3}>
                        From date
                      </Box>
                      <CalendarPopover
                        minDate={minDate}
                        fromDate={fromDate}
                        updateFromDate={updateFromDate}
                        fromDateString={fromDateString}
                        onCloseChartDatePickerPopover={onCloseChartDatePickerPopover}
                      />
                    </Box>

                    <Box w="full">
                      <Box fontSize="0.75rem" pl={3}>
                        To date
                      </Box>
                      <InputGroup size="sm">
                        <Input variant="flushed" size="sm" fontWeight={500} pl={3} isDisabled value={toDateString} />
                        <InputRightElement pr={3}>
                          <DMCalendarInfo fontSize="1.5rem" />
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                  </VStack>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
