import React from 'react';
import { Box, Button, VStack } from '@chakra-ui/react';
import { DMTuneIcon, DMUserIcon, DMUsersIcon } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';
import { NavLink } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { css } from '@emotion/react';
import { useAuth } from 'src/providers/AuthProvider';
import { MANAGEMENT_ROLE } from 'src/const';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';
import { notificationsFeatureFlagSelector } from 'src/app/store';
import { colors } from 'src/theme/foundations/colors';

export function SettingsSidebar() {
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));
  const notificationsFeatureFlag = useSelector(notificationsFeatureFlagSelector);

  return (
    <VStack w="21.25rem" p={6} spacing={6} position="fixed" top="3.5rem" height="100%" alignItems="flex-start">
      <VStack w="full" h="100%" background={colors.DMGray} p={2} borderRadius="8px" marginBottom="64px">
        <Box w="full">
          <NavLink to={locations.protected.settings.account}>
            {({ isActive }) => (
              <Button
                leftIcon={<DMUserIcon fontSize="1.5rem" />}
                css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
                {...sidebarButtonProps}
                background={colors.DMWhite}
              >
                <FormattedText label="page.settings.sidebar.accountSettings" />
              </Button>
            )}
          </NavLink>
        </Box>

        {[MANAGEMENT_ROLE.DM_Admin, MANAGEMENT_ROLE.Admin].includes(userData?.role) ? (
          <Box w="full">
            <NavLink to={locations.protected.settings.userManagement}>
              {({ isActive }) => (
                <Button
                  leftIcon={<DMUsersIcon fontSize="1.5rem" />}
                  css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
                  {...sidebarButtonProps}
                  background={colors.DMWhite}
                >
                  <FormattedText label="page.settings.sidebar.userManagement" />
                </Button>
              )}
            </NavLink>
          </Box>
        ) : null}

        {[MANAGEMENT_ROLE.DM_Admin].includes(userData?.role) ? (
          <Box w="full">
            <NavLink to={locations.protected.settings.preferences}>
              {({ isActive }) => (
                <Button
                  leftIcon={<DMTuneIcon fontSize="1.5rem" />}
                  css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
                  {...sidebarButtonProps}
                  background={colors.DMWhite}
                >
                  <FormattedText label="page.settings.sidebar.preferences" />
                </Button>
              )}
            </NavLink>
          </Box>
        ) : null}

        {[MANAGEMENT_ROLE.DM_Admin, MANAGEMENT_ROLE.Admin].includes(userData?.role) && notificationsFeatureFlag ? (
          <Box w="full">
            <NavLink to={locations.protected.settings.applicationPreferences}>
              {({ isActive }) => (
                <Button
                  leftIcon={<DMTuneIcon fontSize="1.5rem" />}
                  css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
                  {...sidebarButtonProps}
                  background={colors.DMWhite}
                >
                  <FormattedText label="page.settings.sidebar.applicationPreferences" />
                </Button>
              )}
            </NavLink>
          </Box>
        ) : null}
      </VStack>
    </VStack>
  );
}

const sidebarButtonProps = {
  variant: 'outline',
  size: 'lg',
  borderRadius: 'lg',
};

const sidebarButtonStyle = css`
  width: 100%;
  font-size: 0.875rem;
  justify-content: flex-start;
`;

const sidebarButtonActiveStyle = css`
  width: 100%;
  font-size: 0.875rem;
  justify-content: flex-start;
  border: 2px solid #7071f3;
`;
