import React from 'react';
import { Box } from '@chakra-ui/react';

type SummaryChartTotalProps = {
  total: number;
};

export const SummaryChartTotal = ({ total }: SummaryChartTotalProps) => (
  <Box fontWeight={600} textAlign="center" position="absolute" top="3.7rem" left={{ base: '5.5rem', xl: '7.5rem' }}>
    <Box fontSize="14px" fontWeight={500} color="#01152B">
      Total
    </Box>
    <Box fontSize="2rem">{total}</Box>
  </Box>
);
