import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import EvidenceSidebarItem from 'src/features/visual-ai/components/EvidenceSidebarItem';
import { FAILURE_NAME } from 'src/const';

interface ComponentEvidenceSidebarProps {
  latestDiagnostics: any;
  activeEvidenceResourceId: string | undefined;
  updateActiveEvidenceResourceId: (id: string) => void;
  setIsLeftRollerPlotVisible: any;
  setIsRightRollerPlotVisible: any;
}

export const EvidenceSidebar = ({
  latestDiagnostics,
  activeEvidenceResourceId,
  updateActiveEvidenceResourceId,
  setIsLeftRollerPlotVisible,
  setIsRightRollerPlotVisible,
}: ComponentEvidenceSidebarProps) => {
  const onEvidenceSidebarItemClick = (diagnostic: any) => {
    const evidenceResourceId = diagnostic.sources_info[0].evidence_resource_id;
    const selectedDiagnostic = latestDiagnostics.find(
      (entry: any) => entry.sources_info[0]?.evidence_resource_id === evidenceResourceId
    );

    if (selectedDiagnostic.fault_type.toLowerCase() === FAILURE_NAME.BeltShift && !activeEvidenceResourceId) {
      setIsLeftRollerPlotVisible(true);
      setIsRightRollerPlotVisible(false);
    }

    updateActiveEvidenceResourceId(evidenceResourceId);

    if (selectedDiagnostic.fault_type.toLowerCase().includes(FAILURE_NAME.BeltShiftLHS)) {
      setIsLeftRollerPlotVisible(true);
      setIsRightRollerPlotVisible(false);

      return;
    }

    if (selectedDiagnostic.fault_type.toLowerCase().includes(FAILURE_NAME.BeltShiftRHS)) {
      setIsLeftRollerPlotVisible(false);
      setIsRightRollerPlotVisible(true);

      return;
    }
  };

  return (
    <Box
      bgColor="#E6E8EC"
      p={2}
      pt={0}
      borderRadius="2xl"
      w="full"
      maxW="17.75rem"
      minW="15.75rem"
      h="calc(100vh - 200px)"
      overflowY="auto"
    >
      <HStack py={4} pb={3} px={4} justifyContent="space-between">
        <Box fontWeight={600}>Component Evidences</Box>
      </HStack>

      <VStack w="full">
        {latestDiagnostics.length ? (
          latestDiagnostics.map((diagnostic: any, index: number) => (
            <EvidenceSidebarItem
              key={index}
              diagnostic={diagnostic}
              isSelected={activeEvidenceResourceId === diagnostic.sources_info[0].evidence_resource_id}
              onClick={() => onEvidenceSidebarItemClick(diagnostic)}
            />
          ))
        ) : (
          <Box bgColor="white" fontSize="0.875rem" p={2} borderRadius="lg" w="full" textAlign="center" py={4}>
            No evidence in the time range
          </Box>
        )}
      </VStack>
    </Box>
  );
};
