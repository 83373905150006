import React from 'react';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Hide,
  HStack,
  Show,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { SensorsFilterDropdown } from 'src/components/SensorsFilterDropdown';
import { FormattedText } from 'src/components/FormattedText';
import { EnergySaving } from 'src/components/filter-bar/components/EnergySaving';
import { Downtime } from 'src/components/filter-bar/components/Downtime';
import { StatusFilter } from 'src/components/filter-bar/components/StatusFilter';
import { useMatch } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { DMFiltersIcon } from 'src/components/Icons';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { LastRecorded } from 'src/components/LastRecorded';
import { FilterMenu } from 'src/components/FilterMenu';

interface FilterBarProps {
  amountByStatus?: Record<string, number>;
  selectedStatuses: Record<string, boolean>;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
  totalAssetsSensors?: Record<string, number>;
  selectedSensors: string;
  setSelectedSensors: (state: string) => void;
  asset?: any;
}

export const FilterBar = ({
  amountByStatus,
  selectedStatuses,
  setSelectedStatuses,
  totalAssetsSensors,
  selectedSensors,
  setSelectedSensors,
  asset,
}: FilterBarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const matchFailuresLocation = useMatch(locations.protected.sites.failures);
  const matchComponentsLocation = useMatch(locations.protected.sites.components);
  const status = Object.entries(selectedStatuses).filter((item) => item[1])[0];
  const isDiagnosticsLocation = matchFailuresLocation || matchComponentsLocation;

  return (
    <HStack pl={{ base: 4, xl: '7.5rem' }} pr={{ base: 4, xl: 16 }} css={filterBarStyle}>
      <HStack w="full">
        <Show below="xl">
          <HStack justifyContent="space-between" w="full">
            <ButtonNaked
              label={status && amountByStatus && `Show ${status[0]} (${amountByStatus[status[0]]})`}
              leftIcon={<DMFiltersIcon fontSize="1.5rem" />}
              px={0}
              fontWeight={600}
              onClick={onOpen}
            />
            {asset ? <LastRecorded date={asset.latest_diagnostic_created_at} /> : null}
          </HStack>

          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay top="3.5rem" bgColor="rgba(2, 29, 61, 0.5)" />
            <DrawerContent top="3.5rem!important" maxW="270px">
              <DrawerCloseButton top={3} right={3} />
              <DrawerHeader p={4}>
                <HStack>
                  <DMFiltersIcon fontSize="1.5rem" />
                  <Box fontSize="1rem">Filter by</Box>
                </HStack>
              </DrawerHeader>

              <DrawerBody top="3.5rem" px={0} py={4}>
                <StatusFilter
                  assetsAmountByStatus={amountByStatus}
                  selectedStatuses={selectedStatuses}
                  setSelectedStatuses={setSelectedStatuses}
                  setSelectedSensors={setSelectedSensors}
                />
                {matchFailuresLocation || matchComponentsLocation ? null : (
                  <>
                    <Divider py={2} mb={4} />
                    <SensorsFilterDropdown
                      totalAssetsSensors={totalAssetsSensors}
                      selectedSensors={selectedSensors}
                      setSelectedSensors={setSelectedSensors}
                      setSelectedStatuses={setSelectedStatuses}
                    />
                  </>
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>

        <Hide below="xl">
          <Hide>
            <Box fontSize="0.75rem" pr={4}>
              <FormattedText label="general.filterBar.title" />
            </Box>
            <HStack spacing={0}>
              <StatusFilter
                assetsAmountByStatus={amountByStatus}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                setSelectedSensors={setSelectedSensors}
              />

              {isDiagnosticsLocation ? null : (
                <SensorsFilterDropdown
                  totalAssetsSensors={totalAssetsSensors}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  setSelectedStatuses={setSelectedStatuses}
                />
              )}
            </HStack>
          </Hide>
        </Hide>
      </HStack>

      <Hide>
        <HStack spacing={6}>
          <EnergySaving />
          <Downtime />
        </HStack>
      </Hide>
    </HStack>
  );
};

const filterBarStyle = css`
  position: fixed;
  top: 7.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  height: 3rem;
  background-color: #fff;

  border-bottom: 1px solid rgba(1, 10, 9, 0.1);
`;
