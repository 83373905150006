import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Flex,
  Image,
  Hide,
} from '@chakra-ui/react';
import { HEALTH_STATUS, INITIAL_FILTERS, SENSOR_STATUS, STATUS_CHANGED } from 'src/const';
import { DMFiltersIcon, DMNewSensorsIcon } from 'src/components/Icons';
import { locations } from 'src/app/locations';
import { useMatch, useParams } from 'react-router-dom';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { colors } from 'src/theme/foundations/colors';

interface FilterMenuProps {
  assetsAmountByStatus?: Record<string, number>;
  selectedStatuses: Record<string, boolean>;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
  setSelectedSensors: (state: string) => void;
  isDiagnosticsLocation: any;
  totalAssetsSensors?: Record<string, number>;
  assetsAmountByStatusChanged?: Record<string, number>;
  selectedStatusChanged: any;
  setSelectedStatusChanged: any;
  getComponentSensorTabIndex: any;
  matchSpecificComponentLocation?: any;
  selectedComponent?: any;
  setSelectedComponent?: any;
  components?: any;
  setComponents?: any;
  componentId?: any;
  assetsAmountByCamera?: any;
}

const AmountBadge = ({ value = 0 }: { value: number | undefined }) => (
  <Flex
    color="#12FFE2"
    backgroundColor="rgba(1, 21, 43, 1)"
    p={2}
    borderRadius="8px"
    h="25px"
    alignItems="center"
    fontWeight={600}
  >
    {value}
  </Flex>
);

export const FilterMenu = ({
  assetsAmountByStatus,
  selectedStatuses,
  setSelectedStatuses,
  setSelectedSensors,
  totalAssetsSensors,
  isDiagnosticsLocation,
  assetsAmountByStatusChanged,
  selectedStatusChanged,
  setSelectedStatusChanged,
  getComponentSensorTabIndex,
  matchSpecificComponentLocation,
  selectedComponent,
  setSelectedComponent,
  components,
  setComponents,
  assetsAmountByCamera,
}: FilterMenuProps) => {
  const [activeMachineStatus, setActiveMachineStatus] = useState<string>('all');
  const [activeSensorsStatus, setActiveSensorsStatus] = useState<string>('all');
  const [activeChangedStatus, setActiveChangedStatus] = useState<string>('all');
  const [activeNotMonitoredStatus, setActiveNotMonitoredStatus] = useState<string>('all');

  const matchMachinesLocation = useMatch(locations.protected.sites.machines);
  const matchComponentsLocation = useMatch(locations.protected.sites.components);

  const SensorTabIndex = '1';

  const onFilterMachineStatusChange = (value: string | string[]) => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      [value as string]: true,
    });

    setActiveMachineStatus(value as string);
    setActiveSensorsStatus('all');
    setActiveChangedStatus('all');
    setActiveNotMonitoredStatus('all');
  };

  const onFilterSensorsStatusChange = (value: string | string[]) => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
    setSelectedStatusChanged({
      ...STATUS_CHANGED,
    });
    setSelectedSensors(value as string);

    setActiveSensorsStatus(value as string);
    setActiveChangedStatus('all');
    setActiveMachineStatus('all');
    setActiveNotMonitoredStatus('all');
  };

  const onFilterStatusChanged = (value: string | string[]) => {
    setSelectedSensors('all');
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
    setActiveChangedStatus(value as string);
    setActiveSensorsStatus('all');
    setActiveMachineStatus('all');
    setSelectedStatusChanged({
      ...STATUS_CHANGED,
      [value as string]: true,
    });
    setActiveNotMonitoredStatus('all');
  };

  const onFilterNotMonitored = (value: string | string[]) => {
    setSelectedSensors('all');
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
    setActiveChangedStatus(value as string);
    setActiveSensorsStatus('all');
    setActiveMachineStatus('all');
    setSelectedStatusChanged({
      ...STATUS_CHANGED,
      [value as string]: true,
    });
    setActiveNotMonitoredStatus(value as string);
  };

  const filterButtonLabel = () => {
    if (activeMachineStatus !== 'all' && activeSensorsStatus === 'all' && activeChangedStatus === 'all') {
      return `Filter By: ${activeMachineStatus === 'na' ? 'Not monitored' : activeMachineStatus}`;
    } else if (activeNotMonitoredStatus === 'not_monitored') {
      return 'Filter By: Not Monitored';
    } else if (activeChangedStatus !== 'all') {
      return 'Filter By: Status Change';
    } else if (activeSensorsStatus !== 'all') {
      return `Filter By: ${activeSensorsStatus}`;
    } else if (activeMachineStatus !== 'all') {
      return 'Filter By';
    } else {
      return 'Filter';
    }
  };

  const filterStatusTitle = () => {
    if (matchMachinesLocation) {
      return 'Asset Health Status';
    }

    if (matchComponentsLocation) {
      return 'Component Health Status';
    }
  };

  const filterConditionsTitle = () => {
    if (matchMachinesLocation) {
      return 'Asset Conditions';
    }

    if (matchComponentsLocation) {
      return 'Component Conditions';
    }
  };

  useEffect(() => {
    if (!selectedComponent) {
      setActiveSensorsStatus('all');
      setActiveMachineStatus('all');
      setActiveChangedStatus('all');
      setActiveNotMonitoredStatus('all');

      setSelectedStatuses({
        ...INITIAL_FILTERS,
        all: true,
      });
    } else {
      setActiveMachineStatus((value) => {
        if (value !== 'all') {
          setSelectedStatuses({
            ...INITIAL_FILTERS,
            all: true,
          });
          return 'all';
        }
        return value;
      });
      setActiveChangedStatus((value) => {
        if (value !== 'all') {
          setSelectedStatuses({
            ...INITIAL_FILTERS,
            all: true,
          });
        }
        return value;
      });
    }
  }, [matchSpecificComponentLocation, selectedComponent]);

  useEffect(() => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
    setSelectedStatusChanged({
      ...STATUS_CHANGED,
      all: true,
    });
  }, []);

  return (
    <>
      {getComponentSensorTabIndex !== SensorTabIndex ? (
        <Menu isLazy offset={[0, 4]}>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                size="sm"
                borderRadius="lg"
                variant="unstyled"
                px={1}
                textTransform="capitalize"
                bgColor={isOpen ? colors.DMPrimaryColor : undefined}
                color={isOpen ? colors.DMWhite : 'black'}
              >
                <HStack ml={1} mr={1}>
                  <DMFiltersIcon fontSize="1.5rem" />
                  <Box fontSize="0.875rem" fontWeight={500}>
                    {filterButtonLabel()}
                  </Box>
                </HStack>
              </MenuButton>

              <MenuList minWidth="18.75rem" borderColor="#B1B9C3">
                {assetsAmountByStatus ? (
                  <MenuOptionGroup
                    defaultValue="all"
                    title={filterStatusTitle()}
                    type="radio"
                    value={activeMachineStatus}
                    onChange={onFilterMachineStatusChange}
                    {...menuOptionGroupProps}
                  >
                    <MenuItemOption value="all" {...menuItemOptionProps}>
                      <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                        <Box fontWeight={600}>Show All</Box>
                        <AmountBadge value={assetsAmountByStatus.all} />
                      </HStack>
                    </MenuItemOption>

                    <MenuItemOption
                      value={HEALTH_STATUS.CRITICAL}
                      isDisabled={assetsAmountByStatus[HEALTH_STATUS.CRITICAL] === 0}
                      {...menuItemOptionProps}
                    >
                      <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                        <Box color={`health.${HEALTH_STATUS.CRITICAL}`}>Critical</Box>
                        <AmountBadge value={assetsAmountByStatus[HEALTH_STATUS.CRITICAL]} />
                      </HStack>
                    </MenuItemOption>

                    <MenuItemOption
                      value={HEALTH_STATUS.ALARM}
                      isDisabled={assetsAmountByStatus[HEALTH_STATUS.ALARM] === 0}
                      {...menuItemOptionProps}
                    >
                      <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                        <Box color={`health.${HEALTH_STATUS.ALARM}`}>Alarm</Box>
                        <AmountBadge value={assetsAmountByStatus[HEALTH_STATUS.ALARM]} />
                      </HStack>
                    </MenuItemOption>

                    <MenuItemOption
                      value={HEALTH_STATUS.MONITOR}
                      isDisabled={assetsAmountByStatus[HEALTH_STATUS.MONITOR] === 0}
                      {...menuItemOptionProps}
                    >
                      <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                        <Box color={`health.${HEALTH_STATUS.MONITOR}`}>Monitor</Box>
                        <AmountBadge value={assetsAmountByStatus[HEALTH_STATUS.MONITOR]} />
                      </HStack>
                    </MenuItemOption>

                    <MenuItemOption
                      value={HEALTH_STATUS.HEALTHY}
                      isDisabled={assetsAmountByStatus[HEALTH_STATUS.HEALTHY] === 0}
                      {...menuItemOptionProps}
                    >
                      <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                        <Box color={`health.${HEALTH_STATUS.HEALTHY}`}>Healthy</Box>
                        <AmountBadge value={assetsAmountByStatus[HEALTH_STATUS.HEALTHY]} />
                      </HStack>
                    </MenuItemOption>

                    {isDiagnosticsLocation ? (
                      <MenuItemOption
                        value={HEALTH_STATUS.NOT_AVAILABLE}
                        isDisabled={assetsAmountByStatus[HEALTH_STATUS.NOT_AVAILABLE] === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontSize="0.875rem" fontWeight={500}>
                          <Box color={`health.${HEALTH_STATUS.NOT_AVAILABLE}`}>Not Monitored</Box>
                          <AmountBadge value={assetsAmountByStatus[HEALTH_STATUS.NOT_AVAILABLE]} />
                        </HStack>
                      </MenuItemOption>
                    ) : null}
                  </MenuOptionGroup>
                ) : null}

                {assetsAmountByStatusChanged ? (
                  <>
                    <MenuDivider {...menuDividerProps} />
                    <MenuOptionGroup
                      value={activeChangedStatus}
                      defaultValue="all"
                      title={filterConditionsTitle()}
                      type="radio"
                      onChange={onFilterStatusChanged}
                      {...menuOptionGroupProps}
                    >
                      <MenuItemOption value="all" {...menuItemOptionProps}>
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <Box fontWeight={600}>Show All</Box>
                          <AmountBadge value={assetsAmountByStatusChanged.all} />
                        </HStack>
                      </MenuItemOption>

                      <MenuItemOption
                        value="health_status_changed"
                        isDisabled={assetsAmountByStatusChanged.changed === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <Box>Status Change</Box>
                          <AmountBadge value={assetsAmountByStatusChanged.changed} />
                        </HStack>
                      </MenuItemOption>

                      {isDiagnosticsLocation ? null : (
                        <MenuOptionGroup
                          value={activeNotMonitoredStatus}
                          defaultValue="all"
                          type="radio"
                          onChange={onFilterNotMonitored}
                        >
                          <MenuItemOption
                            value="not_monitored"
                            isDisabled={assetsAmountByStatusChanged?.not_monitored === 0}
                            {...menuItemOptionProps}
                          >
                            <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                              <Box>Not Monitored</Box>
                              <AmountBadge value={assetsAmountByStatusChanged?.not_monitored} />
                            </HStack>
                          </MenuItemOption>
                        </MenuOptionGroup>
                      )}
                    </MenuOptionGroup>
                  </>
                ) : null}

                {isDiagnosticsLocation ? (
                  <Hide>
                    <MenuDivider {...menuDividerProps} />
                    <MenuOptionGroup
                      defaultValue="all"
                      title="Sensors"
                      type="radio"
                      value={activeSensorsStatus}
                      onChange={onFilterSensorsStatusChange}
                      {...menuOptionGroupProps}
                    >
                      <MenuItemOption value="all" {...menuItemOptionProps}>
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="black" />
                            <Box fontWeight={600}>Show All</Box>
                          </HStack>
                          {!isDiagnosticsLocation ? <AmountBadge value={totalAssetsSensors?.all} /> : null}
                        </HStack>
                      </MenuItemOption>

                      {!isDiagnosticsLocation && (
                        <>
                          <MenuItemOption
                            value={SENSOR_STATUS.OFFLINE}
                            isDisabled={totalAssetsSensors?.critical === 0}
                            {...menuItemOptionProps}
                          >
                            <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                              <HStack>
                                <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="health.critical" />
                                <Box>Offline Sensors</Box>
                              </HStack>
                              <AmountBadge value={totalAssetsSensors?.critical} />
                            </HStack>
                          </MenuItemOption>

                          <MenuItemOption
                            value={SENSOR_STATUS.ONLINE}
                            isDisabled={totalAssetsSensors?.healthy === 0}
                            {...menuItemOptionProps}
                          >
                            <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                              <HStack>
                                <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="health.healthy" />
                                <Box>Online Sensors</Box>
                              </HStack>
                              <AmountBadge value={totalAssetsSensors?.healthy} />
                            </HStack>
                          </MenuItemOption>
                        </>
                      )}

                      <MenuItemOption
                        value="camera"
                        isDisabled={assetsAmountByCamera?.all === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <Image src={evidencePlayIcon} alt="Evidence Play Icon" fontSize="1.5rem" ml={1} />
                            <Box>Camera</Box>
                          </HStack>
                          <AmountBadge value={assetsAmountByCamera?.all} />
                        </HStack>
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </Hide>
                ) : (
                  <>
                    <MenuDivider {...menuDividerProps} />
                    <MenuOptionGroup
                      defaultValue="all"
                      title="Sensors"
                      type="radio"
                      value={activeSensorsStatus}
                      onChange={onFilterSensorsStatusChange}
                      {...menuOptionGroupProps}
                    >
                      <MenuItemOption value="all" {...menuItemOptionProps}>
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="black" />
                            <Box fontWeight={600}>Show All</Box>
                          </HStack>
                          <AmountBadge value={totalAssetsSensors?.all} />
                        </HStack>
                      </MenuItemOption>

                      <MenuItemOption
                        value={SENSOR_STATUS.OFFLINE}
                        isDisabled={totalAssetsSensors?.critical === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="health.critical" />
                            <Box>Offline Sensors</Box>
                          </HStack>
                          <AmountBadge value={totalAssetsSensors?.critical} />
                        </HStack>
                      </MenuItemOption>

                      <MenuItemOption
                        value={SENSOR_STATUS.ONLINE}
                        isDisabled={totalAssetsSensors?.healthy === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <DMNewSensorsIcon fontSize="1.5rem" ml={1} color="health.healthy" />
                            <Box>Online Sensors</Box>
                          </HStack>
                          <AmountBadge value={totalAssetsSensors?.healthy} />
                        </HStack>
                      </MenuItemOption>

                      <MenuItemOption
                        value="camera"
                        isDisabled={assetsAmountByCamera?.all === 0}
                        {...menuItemOptionProps}
                      >
                        <HStack justifyContent="space-between" fontWeight={500} fontSize="0.875rem">
                          <HStack>
                            <Image src={evidencePlayIcon} alt="Evidence Play Icon" fontSize="1.5rem" ml={1} />
                            <Box>Camera</Box>
                          </HStack>
                          <AmountBadge value={assetsAmountByCamera?.all} />
                        </HStack>
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      ) : null}
    </>
  );
};

const menuOptionGroupProps = {
  p: 4,
  pt: 2,
  m: 0,
  color: '#55687D',
};

const menuDividerProps = {
  borderColor: '#B1B9C3',
  opacity: 1,
};

const menuItemOptionProps = {
  h: '3rem',
  pr: 3,
  pl: 5,
  _hover: { bg: 'rgba(112, 113, 243, 0.03)' },
  sx: {
    '.chakra-menu__icon-wrapper': {
      mr: 4,
    },
  },
};
