import { colors } from 'src/theme/foundations/colors';
import { textStyles } from 'src/theme/foundations/textStyles';
import { typography } from 'src/theme/foundations/typography';
import { breakpoints } from 'src/theme/foundations/breakpoints';

export const foundations = {
  colors,
  breakpoints,
  textStyles,
  ...typography,
};
