import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

export type PotentialSavingsData = Array<Array<string | Array<Record<string, string>>>>;

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const businessInsightsApi = createApi({
  reducerPath: 'businessInsightsApi',
  baseQuery,
  tagTypes: ['PotentialSavings'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getPotentialSavings: builder.query<any, { site_id?: string }>({
      query: ({ site_id }) => {
        const searchParams = new URLSearchParams();
        if (site_id) {
          searchParams.append('site_id', site_id);
        }
        return { url: `statistics/potential_savings?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'PotentialSavings' }],
    }),
  }),
});

export const { useLazyGetPotentialSavingsQuery } = businessInsightsApi;
