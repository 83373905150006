import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { format } from 'date-fns';
import { DD_MM_DATE_FORMAT, FUSION_TRENDS_TYPE, HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { healthStatusMap, healthStatusToNumberMap } from '../const';
import { Box, HStack } from '@chakra-ui/react';
import { colors } from 'src/theme/foundations/colors';

const SelectedDateHeader = ({ tooltip }: { tooltip: any }) => {
  return (
    <HStack
      fontSize="12px"
      fontWeight={600}
      spacing={1}
      textTransform="capitalize"
      color="white"
      bg={tooltip && tooltip[1] === 5 ? '#01152B' : tooltip && `health.${healthStatusMap[tooltip[1]]}`}
      borderRadius="full"
      pl={2}
      pr={2}
    >
      <Box>{tooltip && (healthStatusMap[tooltip[1]] === 'na' ? 'Not Monitored' : healthStatusMap[tooltip[1]])}</Box>{' '}
      <Box>{tooltip && format(new Date(tooltip[0]), REGULAR_DATE_FORMAT)}</Box>
    </HStack>
  );
};

export default SelectedDateHeader;

interface ChartProps {
  type: FUSION_TRENDS_TYPE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPointClick: (e: any) => void;
  diagnostics?: any[];
  componentId?: string;
  selectedComponent?: any;
  activeChartPoint?: any;
}

export const Chart = ({
  type,
  onPointClick,
  componentId,
  diagnostics,
  selectedComponent,
  activeChartPoint,
}: ChartProps) => {
  const [chartData, setChartData] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [chartDataNAOnly, setChartDataNAOnly] = useState<any>([]);
  const [chartDataRest, setChartDataRest] = useState<any>([]);

  const getChartData = () => {
    const clonedDiagnostics = diagnostics && [...diagnostics];

    const componentDiagnostics =
      clonedDiagnostics &&
      clonedDiagnostics.map((item) => {
        return [
          item[0],
          [
            ...item[1].assets
              .map((asset: any) => {
                return asset.components
                  .filter((component: any) => {
                    if (type === FUSION_TRENDS_TYPE.Component && selectedComponent) {
                      if (component.component_id === selectedComponent.component_id) {
                        return component;
                      }
                    } else {
                      return component;
                    }
                  })
                  .map((component: any) => {
                    return component.diagnostics
                      .map((diagnostic: any) => {
                        return {
                          component_name: component.component_name,
                          component_id: component.component_id,
                          component_health_status: component.health_status,
                          ...diagnostic,
                        };
                      })
                      .flat();
                  })
                  .flat();
              })
              .flat(),
          ],
        ];
      });

    if (type === FUSION_TRENDS_TYPE.Component) {
      return componentDiagnostics
        ? componentDiagnostics.map((item) => {
            return {
              name: format(new Date(item[0]), 'yyyy-MM-dd'),
              value: [item[0], healthStatusToNumberMap[item[1][0]?.component_health_status]],
              clicked: false,
            };
          })
        : [];
    }

    return clonedDiagnostics
      ? clonedDiagnostics.map((item) => {
          return {
            name: format(new Date(item[0]), 'yyyy-MM-dd'),
            value: [item[0], healthStatusToNumberMap[item[1].assets[0].health_status]],
            clicked: false,
          };
        })
      : [];
  };

  const commonOptions = {
    height: '90px',
    grid: { top: 18, right: 16, bottom: 16, left: 24 },
    animation: false,
    toolbox: {
      left: 'right',
      itemSize: 16,
      top: -4,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
          emphasis: {
            iconStyle: {
              borderColor: '#7071f3',
            },
          },
          brushStyle: {
            borderWidth: 1,
            borderColor: '#7071f3',
          },
        },
        restore: {
          emphasis: {
            iconStyle: {
              borderColor: '#7071f3',
            },
          },
        },
      },
    },
    dataZoom: [
      {
        type: 'inside',
        throttle: 50,
      },
    ],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 11,
        rotate: 45,
        showMaxLabel: true,
        formatter: function (value: string) {
          return format(new Date(value), DD_MM_DATE_FORMAT);
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 6,
      axisLine: true,
      axisLabel: true,
      splitLine: {
        show: false,
      },
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gt: 5,
          lte: 6,
          color: colors.health.notAvailable,
        },
        {
          gt: 4,
          lte: 5,
          color: colors.health.notAvailable,
        },
        {
          gt: 3,
          lte: 4,
          color: colors.health.healthy,
        },
        {
          gt: 2,
          lte: 3,
          color: colors.health.monitor,
        },
        {
          gt: 1,
          lte: 2,
          color: colors.health.alarm,
        },
        {
          gt: 0,
          lte: 1,
          color: colors.health.critical,
        },
      ],
    },
  };

  useEffect(() => {
    const dataNA = chartData.map((item: any) => {
      const status = item.value[1];

      if (status !== healthStatusToNumberMap[HEALTH_STATUS.NOT_AVAILABLE]) {
        return {
          ...item,
          value: [item.value[0], null],
        };
      }

      return item;
    });

    const dataRest = chartData.filter((item: any) => {
      const status = item.value[1];

      if (status !== healthStatusToNumberMap[HEALTH_STATUS.NOT_AVAILABLE]) {
        return item;
      }
    });

    setChartDataNAOnly(dataNA);
    setChartDataRest(dataRest);
  }, [chartData]);

  useEffect(() => {
    setChartOptions({
      ...commonOptions,
      series: [
        {
          type: 'scatter',
          smooth: true,
          data: chartDataNAOnly,
          symbol: 'circle',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          symbolSize: 12,
        },
        {
          type: 'line',
          smooth: false,
          data: chartDataRest,
          symbol: 'circle',
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          symbolSize: 12,
          lineStyle: {
            color: '#7071f3',
            width: 2,
          },
          areaStyle: {
            color: 'rgba(112, 113, 243, 0.12)',
          },
        },
      ],
    });
  }, [chartDataNAOnly, chartDataRest]);

  useEffect(() => {
    setChartData([...getChartData()]);
  }, [diagnostics, selectedComponent]);

  useEffect(() => {
    if (chartData.length) {
      const latest = chartData[chartData.length - 1].value;
      const isActiveChartPointDateExists = Boolean(
        chartData.filter((item: any) => item.name === format(new Date(activeChartPoint[0]), "yyyy-MM-dd'")).length
      );
      if (activeChartPoint && isActiveChartPointDateExists) {
        onPointClick(activeChartPoint);
      } else {
        onPointClick(latest);
      }
    }
  }, [chartData, selectedComponent]);

  const reactECharts = useMemo(
    () => (
      <ReactECharts
        className="fusion-trend-chart"
        echarts={echarts}
        option={chartOptions}
        onEvents={{
          click: (e: any) => {
            const {
              data: { value },
            } = e;
            onPointClick(value);
          },
        }}
      />
    ),
    [selectedComponent, chartOptions]
  );

  return <Box h="9rem">{reactECharts}</Box>;
};
