import React from 'react';
import { AlertRow } from 'src/components/AlertRow';
import { Box } from '@chakra-ui/react';
import { NotificationAlert } from 'src/data/notifications';

export const AlertsList = ({ notifications }: { notifications: NotificationAlert[] }) => {
  return (
    <Box w="full" pb="12rem">
      {notifications
        .sort((a, b) => {
          if (a.created_at > b.created_at) return -1;
          if (a.created_at < b.created_at) return 1;

          return 0;
        })
        .map((item, index) => (
          <AlertRow notification={item} key={index} />
        ))}
    </Box>
  );
};
