import React from 'react';
import { Box, Divider, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/theme/foundations/colors';
interface StatsTableProps {
  failures: any;
}

export const StatsTable: React.FC<StatsTableProps> = ({ failures }) => {
  const { t } = useTranslation();

  const groupedByFaultName = failures.reduce((acc: any, curr: any) => {
    const key = curr.fault_name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({
      asset_ui_name: curr.asset_ui_name,
      critical_days: curr.critical_days,
      alarm_days: curr.alarm_days,
    });
    return acc;
  }, {});

  const sortedKeys = Object.keys(groupedByFaultName).sort((a, b) => {
    // Compare by length
    const lengthDifference = groupedByFaultName[b].length - groupedByFaultName[a].length;
    if (lengthDifference !== 0) {
      return lengthDifference;
    }

    // Calculate average critical days for comparison
    const averageCriticalDaysA =
      groupedByFaultName[a].reduce((sum: any, item: any) => sum + item.critical_days, 0) / groupedByFaultName[a].length;
    const averageCriticalDaysB =
      groupedByFaultName[b].reduce((sum: any, item: any) => sum + item.critical_days, 0) / groupedByFaultName[b].length;
    if (averageCriticalDaysB !== averageCriticalDaysA) {
      return averageCriticalDaysB - averageCriticalDaysA;
    }

    // Calculate average alarm days for comparison
    const averageAlarmDaysA =
      groupedByFaultName[a].reduce((sum: any, item: any) => sum + item.alarm_days, 0) / groupedByFaultName[a].length;
    const averageAlarmDaysB =
      groupedByFaultName[b].reduce((sum: any, item: any) => sum + item.alarm_days, 0) / groupedByFaultName[b].length;
    if (averageAlarmDaysB !== averageAlarmDaysA) {
      return averageAlarmDaysB - averageAlarmDaysA;
    }

    // If lengths, average critical days, and average alarm days are equal, sort alphabetically
    return a.localeCompare(b);
  });

  const sortedData: any = {};
  sortedKeys.forEach((key) => {
    sortedData[key] = groupedByFaultName[key];
  });

  const failureNames = Object.keys(sortedData);

  return (
    <Flex alignItems="center" justifyContent="center" mt={5}>
      <Box w="full" height="13rem">
        <HStack justifyContent="space-between" px={4} pb={3}>
          <Box fontSize="sm" fontWeight={600} width="15%">
            Failures
          </Box>
          <Box fontSize="sm" fontWeight={600} width="15%">
            Occurrences
          </Box>
          <Box fontSize="sm" fontWeight={600} width="20%">
            <Text as="span" color={colors.health.alarm} fontWeight={700}>
              Alarm
            </Text>{' '}
            Avg. Time
          </Box>
          <Box fontSize="sm" fontWeight={600} width="23%">
            <Text as="span" color={colors.health.critical} fontWeight={700}>
              Critical
            </Text>{' '}
            Avg. Time
          </Box>
        </HStack>

        {failureNames.length > 0 ? (
          <Box overflowY="auto" h="11rem">
            {failureNames.map((failureName, index) => {
              const failures = groupedByFaultName[failureName];
              const totalFailures = failures.length;
              const averageAlarmDays =
                failures.reduce((sum: any, { alarm_days }: any) => sum + alarm_days, 0) / totalFailures;
              const averageCriticalDays =
                failures.reduce((sum: any, { critical_days }: any) => sum + critical_days, 0) / totalFailures;
              const uniqueMachineNames = Array.from(
                new Set(failures.map((failure: { asset_ui_name: any }) => failure.asset_ui_name))
              );

              return (
                <Box key={index}>
                  <HStack justifyContent="space-between" py={2} px={2}>
                    <Tooltip
                      label={
                        <Box>
                          {uniqueMachineNames.map((machineName: any, index: number) => (
                            <Text key={index}>{machineName}</Text>
                          ))}
                        </Box>
                      }
                      borderRadius="md"
                      placement="right"
                      aria-label="Machines list"
                      bg={colors.DMWhite}
                      color={colors.black}
                      fontSize="xs"
                    >
                      <Box fontSize="sm" width="20%" _hover={{ bg: 'gray.100', borderRadius: '8px' }} px={2} py={1}>
                        {failureName}
                      </Box>
                    </Tooltip>
                    <Box fontSize="sm" fontWeight={600} width="20%">
                      {totalFailures}
                    </Box>
                    <Box fontSize="sm" fontWeight={600} width="23%">
                      {Math.round(averageAlarmDays)}
                      <Text as="span" fontSize="xs" ml={1}>
                        days
                      </Text>
                    </Box>
                    <Box fontSize="sm" fontWeight={600} width="15%">
                      {Math.round(averageCriticalDays)}
                      <Text as="span" fontSize="xs" ml={1}>
                        days
                      </Text>
                    </Box>
                  </HStack>
                  {index !== failureNames.length - 1 ? <Divider /> : null}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Text mt={3} fontSize="sm" color="gray.500" textAlign="center">
            {t('statistics.commonFailures.noFailures')}
          </Text>
        )}
      </Box>
    </Flex>
  );
};
