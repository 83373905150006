import React from 'react';
import { format } from 'date-fns';
import {
  Box,
  Button,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { AssetSensorsHealthStatus } from 'src/types/assets';
import { getSensorsOverallStatusUpdated, SENSORS_OVERALL_STATUS, sortSensors } from 'src/pages/machines/utils';
import { DMArrowRightIcon, DMSensorsIcon } from 'src/components/Icons';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { motion } from 'framer-motion';
import { analytics } from 'src/analytics';

const TableHead = () => (
  <Thead>
    <Tr css={tableHeadRowStyle}>
      <Th>Sensor name</Th>
      <Th>Health status</Th>
      <Th>Last report</Th>
      <Th>Type</Th>
      <Th>Location</Th>
    </Tr>
  </Thead>
);

interface MachineSensorsModalProps {
  sensors: AssetSensorsHealthStatus[];
  title?: string;
  siteId: string;
  assetId: string;
  onlyIcon?: boolean;
}

export const MachineSensorsModal = ({ sensors = [], title, siteId, assetId, onlyIcon }: MachineSensorsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sortedSensors = sortSensors(sensors);
  const sensorsOverallStatus = getSensorsOverallStatusUpdated(sensors);

  const onMachineSensorsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onOpen();
    analytics.track('Sensors modal opened', {
      properties: {
        siteId,
        machineId: assetId,
        sensorsOverallStatus,
      },
    });
  };

  return (
    <>
      <Button variant="unstyled" display="flex" onClick={onMachineSensorsClick} size="xs" outline={0}>
        <Box as={motion.div} animation={animation}>
          <DMSensorsIcon
            fontSize="1.5rem"
            mr={onlyIcon ? 0 : 3}
            color={
              sensorsOverallStatus === SENSORS_OVERALL_STATUS.Critical
                ? 'health.critical'
                : sensorsOverallStatus === SENSORS_OVERALL_STATUS.Healthy || SENSORS_OVERALL_STATUS.Warning
                ? 'health.healthy'
                : undefined
            }
          />
          {sensorsOverallStatus === SENSORS_OVERALL_STATUS.Warning ? (
            <Box
              color="health.critical"
              position="absolute"
              top="0.1rem"
              left="1.2rem"
              px="1px"
              fontWeight={700}
              fontSize="1.1rem"
              bgColor="white"
              borderRadius="full"
              lineHeight={1}
            >
              !
            </Box>
          ) : null}
        </Box>
        {!onlyIcon ? (
          <>
            <Box fontSize="0.75rem">{sensors?.length} Sensors</Box>
            <DMArrowRightIcon fontSize="1rem" />
          </>
        ) : null}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', xl: '6xl' }} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl" top="56px">
          <ModalHeader fontWeight={400} fontSize="1rem">
            {title} sensors
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody pb={{ base: 0, xl: 6 }} px={{ base: 0, xl: 6 }} pt={{ base: 0, xl: 2 }}>
            <Table>
              <TableHead />
              <Tbody fontSize="0.875rem">
                {sortedSensors &&
                  sortedSensors.map((item, index) => (
                    <Tr key={index} css={tableBodyRowStyle}>
                      <Td maxW="24rem" fontWeight={600}>
                        {item.sensor_name}
                      </Td>
                      <Td>
                        <HealthStatusIndicator type={item.health_status} />
                      </Td>
                      <Td>
                        {item.last_uptime
                          ? format(new Date(item.last_uptime), REGULAR_DATE_FORMAT)
                          : 'No data received'}
                      </Td>
                      <Td>{item.sensor_type}</Td>
                      <Td>{item.located_at}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const animationKeyframes = keyframes`
  0% { opacity: 1; }
  25% { opacity: 0.3; }
  50% { opacity: 1; }
  75% { opacity: 0.3; }
  100% { opacity: 1; }
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;

const tableHeadRowStyle = {
  borderRadius: '1rem',
  backgroundColor: 'rgba(1, 10, 9, 0.12)',
  th: {
    padding: '0.5rem 1rem 0.5rem 1rem',
    fontSize: '0.625rem',
  },
};

const tableBodyRowStyle = {
  td: {
    padding: '1.5rem 1rem 1.5rem 1rem',
  },
};
