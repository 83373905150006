import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Circle,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { NotificationAlert } from 'src/data/notifications';
import { HEALTH_STATES, MIME_TYPE, TOAST_STATUS } from 'src/const';
import { useLazyGetResourceQuery } from 'src/app/api/diagnosticApi';
import { useTranslation } from 'react-i18next';

const renderAttachment = (type: string, attachment: string) => {
  switch (type) {
    case MIME_TYPE.Image_JPG:
      return <Image src={`data:${type};base64, ${attachment}`} />;
    case MIME_TYPE.Image_PNG:
      return <Image src={`data:${type};base64, ${attachment}`} />;
    case MIME_TYPE.Video_MP4:
      return (
        <video controls autoPlay loop>
          <source type={type} src={`data:${type};base64, ${attachment}`} />
        </video>
      );
    default:
      return null;
  }
};

function GalleryActiveAttachment(props: { fetching: boolean; resources: any[]; activeResource: any }) {
  return (
    <>
      {!props.fetching && props.resources.length ? (
        <Box>
          {renderAttachment(
            props.resources[props.activeResource]?.type,
            props.resources[props.activeResource]?.content
          )}
        </Box>
      ) : (
        <Center py={12}>
          <Spinner size="lg" />
        </Center>
      )}
    </>
  );
}

const NotificationModal = ({
  isOpen,
  onClose,
  notification,
}: {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  notification: NotificationAlert;
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const state = HEALTH_STATES[notification.severity];
  const [fetchResource, { data, isLoading, isFetching }] = useLazyGetResourceQuery();
  const [resources, setResources] = useState<any[]>([]);
  const [activeResource, setActiveResource] = useState<any>(0);

  useEffect(() => {
    const array: any[] = [];

    if (isOpen) {
      notification.attachments.forEach((attachment) => {
        const promise = fetchResource(
          {
            resource_id: attachment.content,
          },
          false
        )
          .unwrap()
          .catch(() => {
            toast({
              status: TOAST_STATUS.Error,
              title: `Failed load notification attachment`,
              description: t('general.errors.communication'),
            });
          });

        array.push(promise);
      });

      const resourcesArray: any[] = [];

      array.forEach(async (promise) => {
        const result = await promise;
        resourcesArray.push(result);
      });
      setResources(resourcesArray);
    }
  }, [isOpen, notification]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="outside" isCentered>
        <ModalOverlay />
        <ModalContent pb={4} borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            <HStack>
              <Circle size="2.5rem" bgColor="gray.100">
                <Icon as={state?.icon} fontSize="1rem" color={`health.${notification?.severity}`} />
              </Circle>
              <Box fontWeight={600}>
                {notification.asset_ui_name}
                {notification.component_name ? ` / ${notification.component_name}` : null} -{' '}
                {notification.event_ui_name}
              </Box>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={6} py={2}>
            <Box pb={4}>{notification?.content}</Box>

            {notification.attachments.length ? (
              <GalleryActiveAttachment fetching={isFetching} resources={resources} activeResource={activeResource} />
            ) : null}

            {!isFetching ? (
              <HStack spacing={2}>
                {resources.length &&
                  resources.map((resource, index) => {
                    return (
                      <Box
                        key={index}
                        maxW="100px"
                        my={2}
                        p={1}
                        border={index === activeResource ? '2px solid black' : undefined}
                        cursor="pointer"
                        onClick={() => {
                          setActiveResource(index);
                        }}
                      >
                        {renderAttachment(resource.type, resource.content)}
                      </Box>
                    );
                  })}
              </HStack>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationModal;
