import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DATE_TIME_AM_PM_FORMAT } from 'src/const';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';

interface LastUpdateProps {
  siteId: string | undefined;
  asset: any;
  componentLastUptime: any;
  filterFunction: any;
}

export const LastUpdate = ({ siteId, asset, componentLastUptime, filterFunction }: LastUpdateProps) => {
  return (
    <HStack alignItems="center">
      <Box fontSize="0.85rem" fontWeight={600}>
        {componentLastUptime
          ? 'Last update: ' +
            format(
              new Date(zonedTimeToUtc(componentLastUptime, Intl.DateTimeFormat().resolvedOptions().timeZone)),
              DATE_TIME_AM_PM_FORMAT
            )
          : null}
      </Box>
      {asset && componentLastUptime ? (
        <MachineSensorsModal
          sensors={asset.sensors_health_status?.filter(filterFunction)}
          title={asset.asset_ui_name}
          siteId={siteId!}
          assetId={asset.asset_id}
          onlyIcon
        />
      ) : null}
    </HStack>
  );
};
