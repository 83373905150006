import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout } from 'src/layouts/AppLayout';
import { AuthLayout } from 'src/layouts/AuthLayout';
import { locations } from 'src/app/locations';
import {
  ChangePasswordPage,
  LoginPage,
  PasswordChangedPage,
  ResetLinkSentPage,
  ResetPasswordPage,
} from 'src/pages/auth';
import { SitesPages } from 'src/pages/sites/SitesPage';
import { MachinesPage } from 'src/pages/machines/MachinesPage';
import { MachineFailuresPage } from 'src/pages/machines/MachineFailuresPage';
import { SettingsLayout } from 'src/pages/settings/SettingsLayout';
import { AccountSettingsPage } from 'src/pages/settings/pages/account-settings/AccountSettingsPage';
import { UserManagementPage } from 'src/pages/settings/pages/user-management/UserManagementPage';
import { PreferencesPage } from 'src/pages/settings/pages/preferences/PreferencesPage';
import { NoMatchPage } from 'src/pages/NoMatchPage';
import { UserPage } from 'src/pages/settings/pages/user-management/components/UserPage';
import CreateUserPage from 'src/pages/settings/pages/user-management/CreateUserPage';
import { MachineComponentsPage } from 'src/pages/machines/MachineComponentsPage';
import { ApplicationPreferencesPage } from 'src/pages/settings/pages/application-preferences/ApplicationPreferencesPage';
import { CodeVerificationPage } from 'src/pages/auth/CodeVerificationPage';
import { MethodVerificationPage } from 'src/pages/auth/MethodVerificationPage';

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={locations.public.login} element={<LoginPage />} />
        <Route path={locations.public.resetPassword} element={<ResetPasswordPage />} />
        <Route path={locations.public.sent} element={<ResetLinkSentPage />} />
        <Route path={locations.public.change} element={<ChangePasswordPage />} />
        <Route path={locations.public.changed} element={<PasswordChangedPage />} />
        <Route path={locations.public.secondFactorMethod} element={<MethodVerificationPage />} />
        <Route path={locations.public.secondFactorVerification} element={<CodeVerificationPage />} />
      </Route>

      <Route path={locations.base} element={<AppLayout />}>
        <Route index element={<Navigate to={locations.protected.sites.index} />} />
        <Route path={locations.protected.sites.index} element={<SitesPages />} />
        <Route path={locations.protected.sites.machines} element={<MachinesPage />} />
        <Route path={locations.protected.sites.failures} element={<MachineFailuresPage />} />
        <Route path={locations.protected.sites.components} element={<MachineComponentsPage />} />
        <Route path={locations.protected.sites.specificComponent} element={<MachineComponentsPage />} />

        <Route path={locations.protected.settings.index} element={<SettingsLayout />}>
          <Route index element={<Navigate to={locations.protected.settings.account} />} />
          <Route path={locations.protected.settings.account} element={<AccountSettingsPage />} />
          <Route path={locations.protected.settings.userManagement} element={<UserManagementPage />} />
          <Route path={locations.protected.settings.createUser} element={<CreateUserPage />} />
          <Route path={locations.protected.settings.user} element={<UserPage />} />
          <Route path={locations.protected.settings.preferences} element={<PreferencesPage />} />
          <Route path={locations.protected.settings.applicationPreferences} element={<ApplicationPreferencesPage />} />
        </Route>
      </Route>

      <Route path={locations.fallback} element={<NoMatchPage />} />
    </Routes>
  );
};
