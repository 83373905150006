import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const Loading = ({ ...rest }) => {
  return (
    <Center py={16} {...rest}>
      <Spinner size="lg" />
    </Center>
  );
};
