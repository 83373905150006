import { Layout, Shape } from 'plotly.js';
import { MAX_POSSIBLE_DATE_ISO, MIN_POSSIBLE_DATE_ISO } from 'src/const';
import { RockSizeLevel } from 'src/app/types/sensors';
import { HealthStatus } from 'src/types/assets';

const baseShape: Partial<Shape> & { showlegend: boolean } = {
  type: 'rect',
  x0: MIN_POSSIBLE_DATE_ISO,
  x1: MAX_POSSIBLE_DATE_ISO,
  layer: 'below',
  showlegend: false,
};

export const baseLayout: Partial<Layout> | any = {
  xaxis: {
    tickfont: {
      size: 10,
      family: 'Montserrat',
    },
    showgrid: true,
    zeroline: true,
    showline: true,
    mirror: 'ticks',
    gridcolor: 'rgba(2, 29, 61, 0.08)',
    gridwidth: 1,
    linecolor: 'rgba(2, 29, 61, 0)',
    linewidth: 1,
    type: 'date',
    fixedrange: false,
    dash: 'dash',
  },
  yaxis: {
    tickfont: {
      size: 12,
      family: 'Montserrat',
    },
    title: {
      text: 'Rock size in mm',
      font: {
        size: 12,
        family: 'Montserrat',
      },
    },
    gridcolor: 'rgba(2, 29, 61, 0.08)',
    gridwidth: 1,
    linecolor: 'rgba(2, 29, 61, 0.0)',
    linewidth: 1,
    lineDash: 'dash',
    mirror: 'ticks',
    showgrid: true,
    zeroline: false,
    showline: true,
    range: [0, 20],
    autotick: true,
    autorange: false,
  },
  margin: {
    t: 8,
    b: 36,
    l: 48,
    r: 8,
  },
  autosize: true,
  height: 420,
  showlegend: false,
  legend: { orientation: 'h', xanchor: 'center', x: 0.5 },
};

export const getLayout = (
  dateRange: any,
  rangeBreaks: Array<any>,
  levels: Record<HealthStatus, RockSizeLevel>,
  maxValue: number
) => {
  const max = Math.max(maxValue, levels.critical.start);
  const upperRangeValue = max + (max / 100) * 30;

  return {
    ...baseLayout,
    xaxis: {
      ...baseLayout.xaxis,
      range: [
        new Date(new Date(dateRange[0]).getTime() - 2 * 3600000).toISOString(),
        new Date(new Date(dateRange[1]).getTime() + 2 * 3600000).toISOString(),
      ],
      rangebreaks: rangeBreaks.map((item: any) => {
        return {
          bounds: [item.start, item.end],
        };
      }),
    },
    yaxis: {
      ...baseLayout.yaxis,
      range: [0, upperRangeValue],
    },
    shapes: [
      {
        ...baseShape,
        y0: levels.healthy.start,
        y1: levels.healthy.end,
        fillcolor: 'rgba(88, 166, 92, 0.2)',
        line: {
          color: 'rgba(88, 166, 92, 0.4)',
          width: 1,
        },
        name: 'Rock size, Healthy threshold',
      },
      {
        ...baseShape,
        y0: levels.monitor.start,
        y1: levels.monitor.end,
        fillcolor: 'rgba(241, 191, 66, 0.2)',
        line: {
          color: 'rgba(241, 191, 66, 0.4)',
          width: 1,
        },
        name: 'Rock size, Monitor threshold',
      },
      {
        ...baseShape,
        y0: levels.alarm.start,
        y1: levels.alarm.end,
        fillcolor: 'rgba(255, 137, 27, 0.2)',
        line: {
          color: 'rgba(255, 137, 27, 0.4)',
          width: 1,
        },
        name: 'Rock size, Alarm threshold',
      },
      {
        ...baseShape,
        y0: levels.critical.start,
        y1: levels.critical.start + upperRangeValue,
        fillcolor: 'rgba(222, 111, 98, 0.2)',
        line: {
          color: 'rgba(222, 111, 98, 0.4)',
          width: 1,
        },
        name: 'Rock size, Critical threshold',
      },
    ],
  };
};
