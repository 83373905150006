import {
  Box,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { DMZoomInIcon } from 'src/components/Icons';
import pumpImage from 'src/assets/images/pump-image.png';

export const ComponentPreviewModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        borderRadius="md"
        position="relative"
        _hover={{
          cursor: 'pointer',
          '& > div': {
            display: 'block',
            opacity: 0.9,
          },
        }}
        onClick={onOpen}
      >
        <Image src={pumpImage} borderRadius="md" w="3.5rem" />
        <Box position="absolute" top={0} left={0} display="none">
          <Center w="3.5rem" h="3.5rem" bg="#7071f3" color="white" borderRadius="md">
            <DMZoomInIcon fontSize="1.5rem" />
          </Center>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={400} fontSize="1rem">
            Pump
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody>
            <Center>
              <Image src={pumpImage} />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
