import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import googleAnalytics from '@analytics/google-analytics';

export const analytics = Analytics({
  app: 'data-mind',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY as string,
    }),
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID as string],
    }),
  ],
});
