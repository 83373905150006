import { EChartsOption } from 'echarts-for-react/src/types';

const option: EChartsOption = {
  animation: false,
  legend: {
    icon: 'circle',
    orient: 'horizontal',
    bottom: 16,
    selectedMode: false,
    itemGap: 12,
    itemWidth: 8,
    itemHeight: 8,
  },
  textStyle: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    color: '#021d3d',
  },
  grid: {
    height: 220,
    left: 16,
    right: 16,
    top: 8,
    bottom: 0,
  },
  yAxis: {
    type: 'value',
    show: false,
    min: 0.5,
    max: 100,
  },
  xAxis: {
    axisTick: {
      show: false,
    },
    type: 'category',
    axisLine: {
      lineStyle: {
        color: 'rgba(2, 29, 61, 0.32)',
      },
    },
  },
};

export { option };
