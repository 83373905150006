import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardBody, HStack, Hide, Center } from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import { Annotations, Data, Layout, Shape } from 'plotly.js';
import { Loading } from 'src/components/Loading';
import {
  calculateDomains,
  getGrayBackgroundShape,
  getRestSensorOptions,
  getSelectionLineShape,
  getSubPlotTitleAnnotations,
  getTracesBySensorType,
  getTrendOptions,
  getVibrationSensorOptions,
  isConditionSpeedCurrentSensor,
  isTemperaturePressureSensor,
  isVibrationSensor,
  mergeArrays,
  mergeTraces,
  noDataPlaceholderAnnotation,
  patchTraces,
  patchTracesByTrend,
} from 'src/features/sensor-fusion/utils';
import { config, layoutInitialConfig } from 'src/features/sensor-fusion/config';
import TrendsSensorsFilterPopover from 'src/features/sensor-fusion/TrendsSensorsFilterPopover';
import useScreenSize from 'src/hooks/useScreenSize';
import { debounce } from 'src/utils';
import { ChartDatePicker } from 'src/features/visual-ai/components/ChartDatePicker';
import { add, format, isAfter, sub } from 'date-fns';
import { SensorFusionLegend } from 'src/features/sensor-fusion/SensorFusionLegend';
import { useLazyGetSensorsPiTagsQuery, useLazyGetSensorsRMSQuery } from 'src/app/api/sensorsApi';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import { diagnosticsDateFormat, SENSORS_DATA_TYPE, MIN_DATE_PICKER_SENSOR_FUSION } from 'src/const';

interface SensorFusionPlotProps {
  title: string;
  component: any;
  revision: number;
  selectedComponent: any;
  catchErrorHandler: (toastId: string, sensorsDataType: SENSORS_DATA_TYPE, toastTitle: string) => (error: any) => void;
}

const SensorFusionPlot = ({
  title,
  component,
  revision,
  selectedComponent,
  catchErrorHandler,
}: SensorFusionPlotProps) => {
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const { siteId, machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const [layout, setLayout] = useState<Partial<Layout>>();
  const [traces, setTraces] = useState<Array<Data>>([]);
  const [visibleTraces, setVisibleTraces] = useState<Array<Data>>([]);
  const [currentSelection, setCurrentSelection] = useState<Array<any>>();
  const [pinSelection, setPinSelection] = useState<Array<any>>();

  const [trendOptions, setTrendOptions] = useState<Array<any>>();
  const [vibrationSensorOptions, setVibrationSensorOptions] = useState<Array<any>>();
  const [operationalSensorOptions, setOperationalSensorOptions] = useState<Array<any>>();

  const [yAxisDomains, setYAxisDomains] = useState<number[][]>();

  const [searchParams] = useSearchParams();
  const fromSearchParam = searchParams.get('from');

  const [fromDate, setFromDate] = useState<Date | undefined>(
    fromSearchParam ? sub(new Date(fromSearchParam), { months: 1 }) : sub(new Date(), { months: 2 })
  );
  const [toDate, setToDate] = useState<Date>(new Date());
  const [minDate] = useState<Date>(new Date(MIN_DATE_PICKER_SENSOR_FUSION));

  const [
    fetchSensorsRMS,
    {
      data: sensorsDataRMS = [],
      isLoading: isSensorsDataRMSLoading,
      isFetching: isSensorsDataRMSFetching,
      isError: isSensorsDataRMSError,
    },
  ] = useLazyGetSensorsRMSQuery({});

  const [
    fetchSensorsPiTags,
    { data: piTagsData, isLoading: isLoadingPiTags, isFetching: isFetchingPiTags, isError: isPiTagsError },
  ] = useLazyGetSensorsPiTagsQuery({});

  const fetchData = (queryParams: Record<string, string | undefined>) => {
    fetchSensorsRMS({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.RMS, t('plots.rms.error')));

    fetchSensorsPiTags({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.PI, t('plots.pi.error')));
  };

  const queryParams: Record<string, string> = {
    site_id: siteId as string,
    asset_id: asset?.asset_id,
    asset_name: asset?.asset_name,
    component_id: component.component_id,
    component_name: component.component_name,
  };

  useEffect(() => {
    fetchData(queryParams);
  }, [component, machineId]);

  useEffect(() => {
    fetchData({
      ...queryParams,
      to_date: toDate ? format(toDate, diagnosticsDateFormat) : undefined,
      from_date: fromDate ? format(fromDate, diagnosticsDateFormat) : undefined,
    });

    setPinSelection(undefined);
    setCurrentSelection(undefined);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (fromDate) {
      const todayDate = new Date();
      const newToDate = add(fromDate, { months: 2 });

      if (isAfter(newToDate, todayDate)) {
        setToDate(todayDate);
      } else {
        setToDate(newToDate);
      }
    }
  }, [fromDate]);

  const boxRef: any = useRef(null);

  if (boxRef && boxRef.current) {
    const box = boxRef.current;
    let drag = false;

    box.addEventListener('mousedown', () => (drag = false));
    box.addEventListener('mousemove', () => (drag = true));
    box.addEventListener('mouseup', (e: any) => {
      if (drag) {
        e.stopPropagation();
      } else {
        return;
      }
    });
  }

  const handleMouseMove = (event: any) => {
    const boxRect = boxRef.current.getBoundingClientRect();
    const x = event.clientX - boxRect.left;
    const y = event.clientY - boxRect.top;

    boxRef.current.style.setProperty('--x', `${x}px`);
    boxRef.current.style.setProperty('--y', `${y - 8}px`);
  };

  let doubleClickOccurred = false;

  const handleSingleClick = debounce((data: Plotly.PlotMouseEvent) => {
    if (!doubleClickOccurred) {
      if (data.event.button === 0) {
        if (data.event.shiftKey) {
          setPinSelection(data.points);
        } else {
          setCurrentSelection(data.points);
        }
      }

      if (data.event.button === 2) {
        setPinSelection(data.points);
      }
    }
    doubleClickOccurred = false;
  }, 200);

  const handleDoubleClick = () => {
    doubleClickOccurred = true;
    clearTimeout((handleSingleClick as any).timeout);
  };

  const onUnpinSelection = () => setPinSelection(undefined);

  const initializeLayout = (options?: Record<any, any>) => {
    const mergedTraces = mergeTraces(sensorsDataRMS, piTagsData);

    const vibrationSensorTraces = getTracesBySensorType(mergedTraces, isVibrationSensor);
    const temperaturePressureSensorTraces = getTracesBySensorType(mergedTraces, isTemperaturePressureSensor);
    const conditionSpeedCurrentSensorTraces = getTracesBySensorType(mergedTraces, isConditionSpeedCurrentSensor);

    const trendOptionsMerged = getTrendOptions(mergedTraces).map((option: any) => {
      const previousValue = trendOptions?.find((item: any) => option.name === item.name);

      return previousValue
        ? previousValue
        : {
            ...option,
            selected: option.name === 'Velocity RMS',
          };
    });

    setTrendOptions(trendOptionsMerged);

    const vibrationSensorOptionsMerged = getVibrationSensorOptions(mergedTraces).map((option: any) => {
      const previousValue = vibrationSensorOptions?.find((item: any) => option.name === item.name);

      return previousValue ? previousValue : option;
    });
    setVibrationSensorOptions(vibrationSensorOptionsMerged);

    const operationalSensorOptionsMerged = getRestSensorOptions(mergedTraces).map((option: any) => {
      const previousValue = operationalSensorOptions?.find((item: any) => option.name === item.name);

      return previousValue ? previousValue : option;
    });
    setOperationalSensorOptions(operationalSensorOptionsMerged);

    const patchedTraces = patchTraces(
      mergedTraces,
      mergeArrays(vibrationSensorOptionsMerged, operationalSensorOptionsMerged)
    );

    const result = trendOptionsMerged.length
      ? patchTracesByTrend(trendOptionsMerged, patchedTraces)
      : [...patchedTraces];

    setTraces(result);
    setVisibleTraces(result);

    const yAxisDomainsValue = calculateDomains(
      vibrationSensorTraces.length,
      temperaturePressureSensorTraces.length,
      conditionSpeedCurrentSensorTraces.length
    );

    setYAxisDomains(yAxisDomainsValue);

    setLayout({
      ...layoutInitialConfig,
      height: options?.screenSize.height - 278,
      yaxis: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[0] || [],
      },
      yaxis2: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[1] || [],
      },
      yaxis3: {
        ...layoutInitialConfig.yaxis,
        domain: isFetchingPiTags ? [0.7, 1] : yAxisDomainsValue[2] || [],
      },
      shapes: [getGrayBackgroundShape(yAxisDomainsValue)],
    });
  };

  useEffect(() => {
    if (trendOptions?.length) {
      const patchedTraces = patchTraces(traces, vibrationSensorOptions);
      const result = patchTracesByTrend(trendOptions, patchedTraces);

      setTraces(result);
    }
  }, [vibrationSensorOptions, trendOptions]);

  useEffect(() => {
    const patchedTraces = patchTraces(traces, operationalSensorOptions);
    setTraces(patchedTraces);
  }, [operationalSensorOptions]);

  useEffect(
    () =>
      initializeLayout({
        screenSize: screenSize,
      }),
    [piTagsData, sensorsDataRMS, revision, screenSize]
  );

  useEffect(() => {
    const shapes: Partial<Shape>[] = [];

    if (yAxisDomains) {
      shapes.push(getGrayBackgroundShape(yAxisDomains));
    }

    if (pinSelection?.length) {
      shapes.push(getSelectionLineShape(pinSelection, 'dash'));
    }

    if (currentSelection?.length) {
      shapes.push(getSelectionLineShape(currentSelection, 'solid'));
    }

    setLayout({
      ...layout,
      shapes,
    });
  }, [pinSelection, currentSelection]);

  useEffect(() => {
    const selected = traces.filter((trace: any) => trace.selected);

    setVisibleTraces(selected);
  }, [traces]);

  useEffect(() => {
    if (visibleTraces.length) {
      const visibleTopPlotSensors = visibleTraces.filter(isVibrationSensor);
      const visibleMiddlePlotSensors = visibleTraces.filter(isTemperaturePressureSensor);
      const visibleBottomPlotSensors = visibleTraces.filter(isConditionSpeedCurrentSensor);

      const vibrationSensorTraces = getTracesBySensorType(traces, isVibrationSensor);
      const temperaturePressureSensorTraces = getTracesBySensorType(traces, isTemperaturePressureSensor);
      const conditionSpeedCurrentSensorTraces = getTracesBySensorType(traces, isConditionSpeedCurrentSensor);

      const subPlotTitleAnnotations = getSubPlotTitleAnnotations(
        Boolean(vibrationSensorTraces.length),
        Boolean(temperaturePressureSensorTraces.length),
        Boolean(conditionSpeedCurrentSensorTraces.length),
        yAxisDomains
      );

      const annotations: Partial<Annotations>[] = [...subPlotTitleAnnotations];

      if (vibrationSensorTraces?.length && !visibleTopPlotSensors.length) {
        annotations.push({
          ...noDataPlaceholderAnnotation,
          yref: 'y3',
        });
      }

      if (temperaturePressureSensorTraces?.length && !visibleMiddlePlotSensors.length) {
        annotations.push({
          ...noDataPlaceholderAnnotation,
          yref: 'y2',
        });
      }

      if (conditionSpeedCurrentSensorTraces?.length && !visibleBottomPlotSensors.length) {
        annotations.push({
          ...noDataPlaceholderAnnotation,
          yref: 'y',
        });
      }

      setLayout({
        ...layout,
        annotations: annotations,
      });
    }
  }, [visibleTraces]);

  useEffect(() => {
    setCurrentSelection(undefined);
    setPinSelection(undefined);

    fetchData({
      ...queryParams,
      to_date: toDate ? format(toDate, diagnosticsDateFormat) : undefined,
      from_date: fromDate ? format(fromDate, diagnosticsDateFormat) : undefined,
    });
  }, [selectedComponent]);

  useEffect(() => {
    return () => {
      setTraces([]);
      setVisibleTraces([]);
    };
  }, []);

  const updateFromDate = (date: Date | undefined) => {
    setFromDate(date);
  };

  return (
    <HStack w="full" spacing={4} alignItems="flex-start">
      <Box bgColor="#E6E8EC" w="full" p={2} pt={0} borderRadius="2xl">
        <HStack py={2} px={4} justifyContent="space-between">
          <HStack>
            <Box fontWeight={600}>{title}</Box>
          </HStack>

          <HStack>
            <ChartDatePicker
              isInline
              buttonSize="xs"
              fromDate={fromDate}
              toDate={toDate}
              minDate={minDate}
              updateFromDate={updateFromDate}
              isFetching={isSensorsDataRMSFetching || isFetchingPiTags}
              position="relative"
              top={0}
            />

            <TrendsSensorsFilterPopover
              traces={traces}
              trendOptions={trendOptions}
              vibrationSensorOptions={vibrationSensorOptions}
              operationalSensorOptions={operationalSensorOptions}
              setTrendOptions={setTrendOptions}
              setVibrationSensorOptions={setVibrationSensorOptions}
              setOperationalSensorOptions={setOperationalSensorOptions}
              isFetching={isFetchingPiTags || isSensorsDataRMSFetching}
            />
          </HStack>
        </HStack>

        <Card w="full" boxShadow={0}>
          <CardBody p={1} position="relative" opacity={isSensorsDataRMSFetching || isFetchingPiTags ? 0.5 : 1}>
            <Box
              ref={boxRef}
              height="calc(100vh - 278px)"
              className="tooltip-box"
              onMouseMove={handleMouseMove}
              onContextMenu={(e) => e.preventDefault()}
              position="relative"
            >
              {!isSensorsDataRMSFetching &&
              !isFetchingPiTags &&
              !isSensorsDataRMSLoading &&
              !isLoadingPiTags &&
              !traces.length ? (
                <Center py="calc(50vh - 150px)">No data available for selected time range</Center>
              ) : !isSensorsDataRMSFetching && !isSensorsDataRMSLoading && layout ? (
                <Plot
                  useResizeHandler
                  revision={revision}
                  data={JSON.parse(JSON.stringify(visibleTraces))}
                  layout={layout}
                  style={{ width: 'auto' }}
                  config={config}
                  onDoubleClick={handleDoubleClick}
                  onClick={handleSingleClick}
                />
              ) : (
                <Loading py="calc(50vh - 139px)" />
              )}

              {(isLoadingPiTags || isFetchingPiTags) && !isSensorsDataRMSFetching ? (
                <Loading position="absolute" w="100%" top="calc(50vh - 250px)" />
              ) : null}
            </Box>
          </CardBody>
        </Card>
      </Box>

      <SensorFusionLegend
        currentSelection={currentSelection}
        pinSelection={pinSelection}
        onUnpinSelection={onUnpinSelection}
      />
    </HStack>
  );
};

export default SensorFusionPlot;
