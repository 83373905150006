import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Hide,
  HStack,
  Radio,
  RadioGroup,
  Show,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AppLogo } from 'src/components/Logo';
import { FormattedText } from 'src/components/FormattedText';
import { analytics } from 'src/analytics';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { useAuth } from 'src/providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { useLazyGetStatusQuery, useSendCodeMutation } from 'src/app/api/authApi';
import { TOAST_STATUS } from 'src/const';
import * as Sentry from '@sentry/react';

export const MethodVerificationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { signOut } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sendVerificationCode, { isLoading: isSendingCode }] = useSendCodeMutation();
  const [fetchAuthStatus, { data: authStatus }] = useLazyGetStatusQuery();
  const [selectedMethod, setSelectedMethod] = useState<string>('email');

  useEffect(() => {
    analytics.page({
      name: 'Code Verification',
      title: 'DataMind Dashboard 2.0 - Code Verification',
    });
  }, []);

  const onMethodChange = (value: string) => {
    setSelectedMethod(value);
    setSearchParams({ method: value });
  };

  const onBack = async () => {
    await signOut();
    dispatch(diagnosticApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    navigate(locations.public.login);
  };

  const onNext = async () => {
    await sendVerificationCode({
      method: selectedMethod,
    })
      .unwrap()
      .then(() => {
        toast({
          status: TOAST_STATUS.Success,
          title: 'Your OTP code has been sent!',
          description: 'You’re just one step away from completing the process.',
        });
        navigate(`${locations.public.secondFactorVerification}?method=${searchParams.get('method')}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast({
          status: TOAST_STATUS.Error,
          title: 'OTP Sending Failed',
          description:
            'An error occurred while sending the verification code. Please try again or check your network connection.',
        });
      });
  };

  useEffect(() => {
    fetchAuthStatus({});
    setSearchParams({ method: 'email' });
  }, []);

  return (
    <VStack h="full" justifyContent="center" px={[4, 4, 4, 4, 4, 0]}>
      <Box
        maxW="26.25rem"
        bgColor={{ base: 'white', xl: 'transparent' }}
        borderRadius={{ base: 'lg', xl: 0 }}
        px={{ base: 6, xl: 0 }}
        py={{ base: 12, xl: 0 }}
      >
        <Show below="xl">
          <Center w="full" mb={8}>
            <AppLogo size="184px" />
          </Center>
        </Show>

        <HStack spacing={3}>
          <Heading
            fontSize="1.5rem"
            fontWeight={600}
            textAlign={{ base: 'center', xl: 'left' }}
            w={{ base: 'full', xl: 'auto' }}
          >
            <Show below="xl">
              <FormattedText label="auth.login.headingMobile" />
            </Show>

            <Hide below="xl">
              <FormattedText label="auth.login.heading" />
            </Hide>
          </Heading>

          <Hide below="xl">
            <Box ml={-1} mt="-2px">
              <AppLogo size="164px" />
            </Box>
          </Hide>
        </HStack>

        <Box pb={10} pt={{ base: 2, xl: 6 }} textAlign={{ base: 'center', xl: 'left' }}>
          <FormattedText label="auth.2fa.method.description" />
        </Box>

        <HStack w="full" justifyContent="center" pb={8}>
          {authStatus ? (
            <RadioGroup size="md" value={selectedMethod} onChange={onMethodChange}>
              <Stack direction="row" spacing={12}>
                <Radio value="email" isDisabled={!authStatus.methods.email} colorScheme="DMRadioColorScheme">
                  Email
                </Radio>
                <Radio value="sms" isDisabled={!authStatus.methods.sms} colorScheme="DMRadioColorScheme">
                  SMS
                </Radio>
              </Stack>
            </RadioGroup>
          ) : null}
        </HStack>

        <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mt={8} justifyContent="space-between">
          <Box w="full" textAlign="center">
            <Button variant="data-mind-naked" onClick={onBack}>
              <FormattedText label="auth.2fa.method.back" />
            </Button>
          </Box>

          <Box w="full" textAlign="center" mb={[5, 5, 0]}>
            <Button
              w={['80%', '80%', 'full']}
              variant="data-mind"
              size="lg"
              borderRadius="full"
              onClick={onNext}
              isLoading={isSendingCode}
            >
              <FormattedText label="auth.2fa.method.next" />
            </Button>
          </Box>
        </Flex>
      </Box>
    </VStack>
  );
};
