import React, { useState } from 'react';
import { Box, Button, Circle, Collapse, Divider, HStack, Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { DATE_TIME_FORMAT, HEALTH_STATES } from 'src/const';
import { DMTrashIcon } from 'src/components/Icons';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { NotificationAlert } from 'src/data/notifications';
import {
  useClearNotificationsMutation,
  useLazyGetNotificationsQuery,
  useReadNotificationsMutation,
} from 'src/app/api/notificationApi';
import NotificationModal from 'src/components/NotificationModal';

export const AlertRow = ({ notification }: { notification: NotificationAlert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const state = HEALTH_STATES[notification.severity];

  const [clearNotificationsApi] = useClearNotificationsMutation();
  const [readNotifications] = useReadNotificationsMutation();
  const [fetchNotifications] = useLazyGetNotificationsQuery();

  const [isRemoveClicked, setRemoveClicked] = useState(false);
  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);

  const onNotificationClear = async () => {
    const id = notification.notification_id;

    await clearNotificationsApi([id]);
    setDialogOpened(false);

    await fetchNotifications({});
    setRemoveClicked(false);
  };

  const onNotificationClick = async () => {
    onOpen();
    await readNotifications([notification.notification_id]);
    await fetchNotifications({});
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setDialogOpened(false);
  };

  const onDeleteSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setRemoveClicked(true);
    await onNotificationClear();
  };

  const onClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setDialogOpened(true);
  };

  return (
    <>
      <Collapse in={!isRemoveClicked} animateOpacity>
        <Box
          css={alertRowStyle}
          px={6}
          onClick={onNotificationClick}
          bgColor={!notification.read ? 'rgba(112, 113, 243, 0.06)' : undefined}
        >
          {isDialogOpened ? (
            <Box px={0} py={3} textAlign="center">
              <Box fontSize="0.875rem" pb="0.4375rem">
                Are you sure you want to delete?
              </Box>
              <HStack justifyContent="center">
                <Button size="xs" onClick={onCancel}>
                  Cancel
                </Button>
                <Button size="xs" colorScheme="red" onClick={onDeleteSubmit}>
                  Delete
                </Button>
              </HStack>
            </Box>
          ) : (
            <HStack justifyContent="space-between">
              <HStack>
                <Box>
                  <Circle size="2.5rem" bgColor="gray.100">
                    <Icon as={state.icon} fontSize="1rem" color={`health.${notification.severity}`} />
                  </Circle>
                </Box>
                <Box fontSize="0.75rem" py="1.25rem">
                  <Box fontWeight={600}>
                    {notification.asset_ui_name}
                    {notification.component_name ? ` / ${notification.component_name}` : null} –{' '}
                    {notification.event_ui_name}
                  </Box>
                  <Box>
                    {notification.site_name} {format(new Date(notification.created_at), DATE_TIME_FORMAT)}
                  </Box>
                </Box>
              </HStack>
              <Box className="clear-button">
                <IconButton
                  aria-label="Clear alert"
                  colorScheme="red"
                  variant="outline"
                  size="sm"
                  onClick={onClearClick}
                  icon={<DMTrashIcon fontSize={16} />}
                />
              </Box>
            </HStack>
          )}
        </Box>
        <Divider />
      </Collapse>
      <NotificationModal isOpen={isOpen} onClose={onClose} notification={notification} />
    </>
  );
};

const alertRowStyle = css`
  justify-content: space-between;
  .clear-button {
    visibility: hidden;
  }
  :hover {
    cursor: pointer;
    background-color: #f7f7f7;

    .clear-button {
      visibility: visible;
    }
  }
`;
