import React from 'react';
import { Fade, Hide, TabPanel } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import SitesHeader from 'src/pages/sites/components/SitesHeader';
import SitesList from 'src/pages/sites/components/SitesList';
import Fallback from 'src/components/Fallback';
import { Loading } from 'src/components/Loading';

interface SiteTabContentProps {
  isLoading: boolean;
  isError: boolean;
  searchResults: any;
  activeTabIndex: number;
  tabPanelStyle: any;
}

export const SiteTabContent = ({
  isLoading,
  isError,
  searchResults,
  activeTabIndex,
  tabPanelStyle,
}: SiteTabContentProps) => (
  <TabPanel css={tabPanelStyle}>
    {!isLoading && searchResults ? (
      <Fade in={!isLoading || !activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
        <Hide below="xl">
          <SitesHeader activeTabIndex={activeTabIndex} />
        </Hide>

        <ErrorBoundary FallbackComponent={Fallback}>
          <SitesList sites={searchResults} activeTabIndex={activeTabIndex} />
        </ErrorBoundary>
      </Fade>
    ) : !isError ? (
      <Loading />
    ) : null}
  </TabPanel>
);