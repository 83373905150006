import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useLazyGetNotificationsDMQuery, useUpdateNotificationsDMMutation } from 'src/app/api/notificationApi';
import { DeliveryMethods } from 'src/data/deliveryMethods';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TOAST_STATUS } from 'src/const';
import { colors } from 'src/theme/foundations/colors';
import { getSwitchStyles } from 'src/theme/components/toggle';

export const UserAlertMethod = () => {
  const { userId } = useParams();
  const toast = useToast();

  const [updateDeliveryMethods, { isLoading: isUpdatingDeliveryMethods }] = useUpdateNotificationsDMMutation();
  const [fetchDeliveryMethods, { data: deliveryMethods, isLoading }] = useLazyGetNotificationsDMQuery();

  const { control, register, handleSubmit } = useForm({});
  useFieldArray({
    control,
    name: 'userDeliveryMethodsForm',
  });

  const onSubmit = async (values: FieldValues) => {
    const payload = deliveryMethods.map((deliveryMethod: DeliveryMethods) => {
      const deliveryMethodsFormValues = values.userDeliveryMethodsForm[deliveryMethod.event_type];
      return {
        ...deliveryMethod,
        delivery_methods: Object.entries(deliveryMethodsFormValues).map((item) => {
          return {
            name: item[0],
            enabled: item[1],
          };
        }),
      };
    });

    try {
      await updateDeliveryMethods({ payload }).unwrap();
      toast({
        status: TOAST_STATUS.Success,
        title: `Alert method preferences updated`,
      });
    } catch (e: any) {
      toast({
        status: TOAST_STATUS.Error,
        title: e?.data?.message || 'Failed to update alert method preferences',
      });
    }
  };

  function getUniqueDeliveryMethods(array: any[]) {
    const uniqueDeliveryMethods: any[] = [];
    const deliveryMethodNames: any[] = [];

    array.forEach((obj) => {
      obj.delivery_methods.forEach((deliveryMethod: any) => {
        const { name, enabled } = deliveryMethod;
        const key = `${name}`;

        if (!deliveryMethodNames.includes(key)) {
          deliveryMethodNames.push(key);
          uniqueDeliveryMethods.push({ name, enabled });
        }
      });
    });

    return uniqueDeliveryMethods;
  }

  const uniqueDeliveryMethods: any[] = deliveryMethods ? getUniqueDeliveryMethods(deliveryMethods) : [];

  useEffect(() => {
    if (userId) {
      fetchDeliveryMethods({ uids: userId });
    }
  }, []);

  return (
    <>
      <Box bgColor={colors.DMGray} p={3} height="full" borderRadius="8px">
        {!isLoading && deliveryMethods ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TableContainer>
              <Table variant="simple" w="full">
                <Thead>
                  <Flex w="100%" p={2} fontWeight={600} fontSize="14px">
                    <Flex w="30%">Application</Flex>
                    {uniqueDeliveryMethods?.map((value, index: number) => (
                      <Flex key={index} w="10%">
                        {value.name}
                      </Flex>
                    ))}
                  </Flex>
                </Thead>
                <Tbody>
                  {deliveryMethods?.map((deliveryMethod: DeliveryMethods, index: number) => {
                    const enrichedDeliveryMethods = deliveryMethod.delivery_methods.map((value) => {
                      return {
                        ...value,
                        show: true,
                      };
                    });
                    const result = Array.from(
                      [...uniqueDeliveryMethods, ...enrichedDeliveryMethods]
                        .reduce((m, o) => m.set(o.name, o), new Map())
                        .values()
                    );

                    return (
                      <Table key={index} marginTop={2}>
                        <Tr bg="white">
                          <Td fontWeight={600} p={5} borderLeftRadius="8px" w="29%">
                            {deliveryMethod?.event_ui_name}
                          </Td>
                          {result?.map((value: any, index: number) => {
                            if (!value?.show) {
                              return <Td key={index}>&nbsp;</Td>;
                            } else {
                              const tdStyle = {
                                ...(index === result.length - 1 && { borderRightRadius: '8px', width: '60%' }),
                              };

                              return (
                                <Td key={index} sx={tdStyle}>
                                  <Switch
                                    sx={getSwitchStyles(isUpdatingDeliveryMethods)}
                                    size="sm"
                                    isDisabled={isUpdatingDeliveryMethods}
                                    defaultChecked={value.enabled}
                                    {...register(
                                      `userDeliveryMethodsForm.${deliveryMethod?.event_type}.${value?.name}`
                                    )}
                                  />
                                </Td>
                              );
                            }
                          })}
                        </Tr>
                      </Table>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            <Box py={5} ml="1rem">
              <Button
                type="submit"
                variant="data-mind"
                size="lg"
                borderRadius="full"
                isLoading={isUpdatingDeliveryMethods}
              >
                Save changes
              </Button>
            </Box>
          </form>
        ) : (
          <Center py={16} w="full">
            <Spinner size="lg" />
          </Center>
        )}
      </Box>
    </>
  );
};
