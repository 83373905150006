import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, HStack, Spinner, Tooltip } from '@chakra-ui/react';
import { useLazyGetResourceQuery } from 'src/app/api/diagnosticApi';
import ReactPlayer from 'react-player';
import { DMChevronLeftIcon, DMChevronRightIcon, DMCloseThinIcon } from 'src/components/Icons';

type Resource = {
  content: string;
  type: string;
};

interface EvidenceVideoProps {
  resourceId: string | undefined;
  onClose: () => void;
  onPrev: () => void;
  onNext: () => void;
  isRockOrBeltTitle: string | null;
}

export const EvidenceVideo = ({ resourceId, onClose, onPrev, onNext, isRockOrBeltTitle }: EvidenceVideoProps) => {
  const [fetchResource, { data: resourceData, isLoading: isLoadingResource, isFetching: isFetchingResource }] =
    useLazyGetResourceQuery();
  const [resource, setResource] = useState<Resource>();
  const [playCount, setPlayCount] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef: React.MutableRefObject<any> = useRef(null);

  const onReactPlayerEnded = () => {
    if (playCount < 1) {
      setPlayCount(playCount + 1);
      setIsPlaying(true);
      playerRef.current.seekTo(0);
    } else {
      setPlayCount(playCount + 1);
      setIsPlaying(false);
    }
  };

  const getValidBase64String = (resource: Resource) => {
    const { content, type } = resource;
    return `data:${type};base64,${content}`;
  };

  const resourceBase64String = resource ? getValidBase64String(resource) : undefined;

  const onCloseButtonClick = () => {
    setResource(undefined);
    onClose();
  };

  useEffect(() => {
    if (resourceId) {
      fetchResource({ resource_id: resourceId }, true);
      setResource(undefined);
      setPlayCount(0);
      setIsPlaying(true);
    }
  }, [resourceId]);

  useEffect(() => {
    if (resourceData) {
      setResource(resourceData);
    }
  }, [resourceData]);
  useEffect(() => {
    return () => {
      setResource(undefined);
    };
  }, []);

  return (
    <Box bgColor="#E6E8EC" p={2} pt={0} borderRadius="2xl" w="full">
      <HStack pt={4} pb={3} px={4} justifyContent="space-between">
        <Box fontWeight={600}>{isRockOrBeltTitle} Evidence</Box>
        <HStack spacing={6}>
          <HStack spacing={4}>
            <Tooltip label="Previous video" borderRadius="md" placement="top">
              <Button variant="naked" size="xs" px={0} onClick={onPrev}>
                <DMChevronLeftIcon fontSize="1.5rem" />
              </Button>
            </Tooltip>

            <Tooltip label="Next video" borderRadius="md" placement="top">
              <Button variant="naked" size="xs" px={0} onClick={onNext}>
                <DMChevronRightIcon fontSize="1.5rem" />
              </Button>
            </Tooltip>
          </HStack>

          <Tooltip label="Close" borderRadius="md" placement="top">
            <Button variant="naked" size="xs" px={0} onClick={onCloseButtonClick}>
              <DMCloseThinIcon fontSize="1.5rem" />
            </Button>
          </Tooltip>
        </HStack>
      </HStack>

      <Box w="full" className="evidence-player-wrapper">
        {isLoadingResource || isFetchingResource ? (
          <Center
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            borderRadius="12px"
            bg="rgba(255, 255, 255, 0.7)"
            zIndex="1"
          >
            <Spinner size="xl" />
          </Center>
        ) : (
          <ReactPlayer
            className="evidence-player"
            url={resourceBase64String}
            ref={playerRef}
            playing={isPlaying}
            onEnded={onReactPlayerEnded}
            controls
            width="100%"
            height="100%"
          />
        )}
      </Box>
    </Box>
  );
};
