import React from 'react';
import { VStack } from '@chakra-ui/react';
import { SummaryDataType } from 'src/data/summary';
import { SummaryLegendRow } from 'src/pages/sites/components/summary/components/SummaryLegendRow';

type SummaryLegendProps = {
  data: SummaryDataType[];
};

export const SummaryLegend = ({ data }: SummaryLegendProps) => {
  return (
    <VStack spacing={1.5}>
      {data.map((item: SummaryDataType, index: number) => {
        const isLastRow = index === data.length - 1;
        return <SummaryLegendRow key={index} row={item} isLastRow={isLastRow} />;
      })}
    </VStack>
  );
};
