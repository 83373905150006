import { Box, Center, Hide, Spinner } from '@chakra-ui/react';
import React from 'react';
import { ComponentsSidebarContent } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarContent';
import { ComponentsSidebarHead } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarHead';
import { ShowAllComponentSidebarItem } from './ShowAllComponentSidebarItem';
import { ComponentsSidebarBottom } from './ComponentsSidebarBottom';

export const ComponentsSidebar = (props: {
  isLoading?: boolean;
  asset?: any;
  selectedComponent?: any;
  onAllComponentsClick?: () => void;
  components: any;
  element: (component: any, index: number) => JSX.Element;
}) => {
  const { asset_ui_name: assetName } = props.asset || {};

  return (
    <Box>
      <Box
        h={{
          base: 0,
          xl: 'calc(100vh - 29.1rem)',
          '1.5xl': 'calc(100vh - 29.1rem)',
        }}
        borderRadius="lg"
        minW="19.75rem"
        w="19.75rem"
        bg="#E6E8EC"
        overflowY="auto"
        borderBottom="0.5rem solid #E6E8EC"
        borderTop="0.5rem solid #E6E8EC"
        display="flex"
        flexDirection="column"
        borderBottomRadius="xl"
        mb={4}
      >
        <Hide below="xl">
          <ComponentsSidebarHead components={props.components} assetName={assetName} />
        </Hide>

        <Box p={2}>
          <Hide below="xl">
            <ShowAllComponentSidebarItem
              onClick={props.onAllComponentsClick!}
              component={props.components}
              selectedComponent={props.selectedComponent}
            />
          </Hide>
        </Box>
        {props.isLoading ? (
          <Center pt={5}>
            <Spinner size="lg" />
          </Center>
        ) : (
          <Box flex="1">
            <ComponentsSidebarContent components={props.components} element={props.element} />
          </Box>
        )}
      </Box>

      <Box maxWidth="19.75rem" overflowY="auto">
        <ComponentsSidebarBottom
          imageResourceId={props?.asset?.asset_image_resource_id}
          isPageLoading={props?.isLoading}
          assetName={assetName}
        />
      </Box>
    </Box>
  );
};
