import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

type MachineStatusResponse = Record<string, Record<string, string>>;
export type MachineStatusData = Array<Array<string | Array<Record<string, string>>>>;

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery,
  tagTypes: ['MachineStatusStatistics', 'CommonFailuresStatistics'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getMachineStatusStatistics: builder.query<MachineStatusData, Record<string, string | undefined>>({
      query: ({ site_id }) => {
        const searchParams = new URLSearchParams();
        if (site_id) {
          searchParams.append('site_id', site_id);
        }
        return { url: `statistics/machine_status?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'MachineStatusStatistics' }],
      transformResponse: (response: MachineStatusResponse) => {
        return Object.entries(response).map((item) => {
          return [item[0], Object.entries(item[1]).map(([key, value]) => ({ [key]: value }))];
        });
      },
    }),
    getCommonFailuresStatistics: builder.query<any, Record<string, string | undefined>>({
      query: ({ site_id }) => {
        const searchParams = new URLSearchParams();
        if (site_id) {
          searchParams.append('site_id', site_id);
        }
        return { url: `statistics/common_failures?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'CommonFailuresStatistics' }],
    }),
  }),
});

export const { useLazyGetMachineStatusStatisticsQuery, useLazyGetCommonFailuresStatisticsQuery } = statisticsApi;
