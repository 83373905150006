import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { FaultSwitch } from 'src/pages/settings/pages/user-management/components/FaultSwitch';

export const UserFaultsPermissions = () => {
  return (
    <Box>
      <Box fontWeight={600} mb={3}>
        Fault type
      </Box>
      <VStack alignItems="flex-start" spacing={0}>
        {Array(12)
          .fill(0)
          .map((item, index) => (
            <FaultSwitch key={index} />
          ))}
      </VStack>
    </Box>
  );
};
