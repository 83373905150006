import React from 'react';
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';

interface ButtonNakedProps extends ButtonProps {
  label?: string;
}

export const ButtonNaked = ({ label, ...rest }: ButtonNakedProps) => {
  const button = (
    <Button
      variant="naked"
      size="sm"
      fontWeight={500}
      fontSize="0.75rem"
      letterSpacing={1.1}
      textTransform="uppercase"
      {...rest}
    >
      {label}
    </Button>
  );

  if (label) {
    return button;
  } else {
    return (
      <Tooltip label="Machine fusion trend" borderRadius="md">
        {button}
      </Tooltip>
    );
  }
};
