import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

export const ScreenSpinner = () => {
  return (
    <Center h="100vh">
      <Spinner thickness="0.25rem" speed="0.65s" emptyColor="gray.200" color="#7071f3" size="xl" />
    </Center>
  );
};
