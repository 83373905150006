import React from 'react';
import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { SENSOR_FUSION_OPTIONS_TYPE, updateOptions } from 'src/features/sensor-fusion/utils';

interface TrendsSensorsOptionsListProps {
  type: string;
  title: string;
  options: any;
  setTrendOptions: (options: any) => void;
  setVibrationSensorOptions: (options: any) => void;
  setOperationalSensorOptions: (options: any) => void;
}

export const TrendsSensorsOptionsList = ({
  type,
  title,
  options,
  setTrendOptions,
  setVibrationSensorOptions,
  setOperationalSensorOptions,
}: TrendsSensorsOptionsListProps) => {
  const onCheckboxChange = (value: any) => {
    const updatedOptions = updateOptions(options, value);

    if (type === SENSOR_FUSION_OPTIONS_TYPE.Trends) {
      setTrendOptions(updatedOptions);
    }

    if (type === SENSOR_FUSION_OPTIONS_TYPE.VibrationSensors) {
      setVibrationSensorOptions(updatedOptions);
    }

    if (type === SENSOR_FUSION_OPTIONS_TYPE.OperationalSensors) {
      setOperationalSensorOptions(updatedOptions);
    }
  };

  return (
    <Box w="full">
      <Box fontSize="1rem" fontWeight="600" p={2} pt={3}>
        <HStack w="full" justifyContent="space-between">
          <Box>{title}</Box>
          <Box fontWeight={500}>{options.length}</Box>
        </HStack>
      </Box>

      <Box bgColor="white" borderRadius="lg" maxH="24rem" overflowY="auto">
        {options.map((option: any, index: number) => (
          <Box
            w="full"
            key={index}
            py={3}
            borderBottom={index < options.length - 1 ? '1px solid rgba(2, 29, 61, 0.12)' : undefined}
          >
            <Box px={3}>
              <Checkbox
                colorScheme="DMCheckboxColorScheme"
                isChecked={option?.selected}
                onChange={() => onCheckboxChange(option)}
              >
                <Box>
                  <Box fontSize="0.875rem" fontWeight="600">
                    {option.name}
                  </Box>
                </Box>
              </Checkbox>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
