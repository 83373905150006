import React from 'react';
import { Box, Divider, HStack } from '@chakra-ui/react';
import { SummaryDataType } from 'src/data/summary';

type SummaryLegendRowProps = {
  row: SummaryDataType;
  isLastRow: boolean;
};

export const SummaryLegendRow = ({ row, isLastRow }: SummaryLegendRowProps) => (
  <>
    <HStack justifyContent="space-between" w="full" px={3} py={2} height="40px">
      <Box textTransform="capitalize" fontSize="0.75rem" fontWeight={500}>
        {row.label}
      </Box>
      <Box fontSize="16px" fontWeight={600} color={row.color}>
        {row.value}
      </Box>
    </HStack>
    {isLastRow ? null : <Divider borderColor="#B1B9C3" />}
  </>
);
