import React, { useEffect } from 'react';
import { FormLayout } from 'src/pages/auth/components/FormLayout';
import { ChangePasswordForm } from 'src/pages/auth/components/ChangePasswordForm';
import { analytics } from 'src/analytics';

export const ChangePasswordPage = () => {
  useEffect(() => {
    analytics.page({
      name: 'Change password',
      title: 'DataMind Dashboard 2.0 - Change password',
    });
  }, []);

  return (
    <FormLayout title="auth.resetPassword.change.heading" subtitle="auth.resetPassword.change.description">
      <ChangePasswordForm />
    </FormLayout>
  );
};
