import React, { Dispatch, SetStateAction } from 'react';
import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { SENSORS_DATA_TYPE } from 'src/const';
import { FormattedText } from 'src/components/FormattedText';

interface SensorsDataRadioGroupProps {
  activeTabIndex: number;
  activeSensorsDataType: string;
  setActiveSensorsDataType: Dispatch<SetStateAction<string>>;
  isComponentHasVibrationSensors: boolean;
  isComponentHasCameraSensors: boolean;
  isMachineHasOtherSensors: boolean;
}
export const SensorsDataRadioGroup = ({
  activeTabIndex,
  activeSensorsDataType,
  setActiveSensorsDataType,
  isComponentHasVibrationSensors,
  isComponentHasCameraSensors,
  isMachineHasOtherSensors,
}: SensorsDataRadioGroupProps) => {
  const onRadioChange = (value: string) => setActiveSensorsDataType(value);

  return (
    <Box px={4}>
      {activeTabIndex === 1 ? (
        <RadioGroup value={activeSensorsDataType} onChange={onRadioChange}>
          <Stack
            direction="row"
            spacing={{
              base: 6,
              xl: 3,
              '1.5xl': 6,
            }}
            fontWeight={600}
          >
            <Radio
              value={SENSORS_DATA_TYPE.PI}
              {...radioProps}
              isDisabled={!isMachineHasOtherSensors && !isComponentHasVibrationSensors}
            >
              <FormattedText label="sensorData.radioGroup.pi" />
            </Radio>
            <Radio value={SENSORS_DATA_TYPE.FFT} {...radioProps} isDisabled={!isComponentHasVibrationSensors}>
              <FormattedText label="sensorData.radioGroup.fft" />
            </Radio>
          </Stack>
        </RadioGroup>
      ) : null}
    </Box>
  );
};

const squareProps = {
  p: 2.5,
  borderWidth: '1px',
  borderRadius: 'md',
};

const radioProps = {
  size: 'sm',
  fontSize: {
    base: '0.875rem',
    xl: '0.75rem',
    '1.5xl': '0.875rem',
  },
  _checked: {
    bgColor: '#7071F3',
  },
  _hover: {
    bgColor: 'transparent',
  },
  _focus: {
    boxShadow: 'none',
  },
};
