import React from 'react';
import { Hide, HStack, IconButton, Show, Text, useMediaQuery, VStack, Box, Tooltip } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SIDEBAR_MENU_BUTTON_ACTIVE_STYLE, SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMLogoutIcon, DMSettingsIcon, DMSitesIcon, DMUserGuideIcon } from 'src/components/Icons';
import { LegendPopover } from 'src/components/legend/LegendPopover';
import Notifications from 'src/components/Notifications';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { theme } from 'src/theme';
import { LegendModal } from 'src/components/legend/LegendModal';
import { analytics } from 'src/analytics';
import { UserMenu } from 'src/components/UserMenu';
import { UserGuide } from 'src/components/UserGuide';

export const NavigationMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);
  const onLogoutClick = async () => {
    try {
      await signOut();

      navigate(locations.public.login);
      dispatch(diagnosticApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      analytics.track('User logged out');
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <VStack
      justifyContent="space-between"
      h="full"
      spacing={0}
      color="white"
      alignItems={{ base: 'flex-start', xl: 'center' }}
      py={4}
    >
      <VStack spacing={3} alignItems="flex-start">
        <NavLink to="/sites">
          {({ isActive }) => (
            <HStack spacing={0}>
              <Tooltip label="Sites" placement="right" borderRadius="md">
                <IconButton
                  aria-label="Sites"
                  size="lg"
                  css={isActive ? SIDEBAR_MENU_BUTTON_ACTIVE_STYLE : undefined}
                  icon={<DMSitesIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
                  variant="unstyled"
                />
              </Tooltip>
              <Show below="xl">
                <Text pl={2}>Sites</Text>
              </Show>
            </HStack>
          )}
        </NavLink>

        {isNotMobileResolution ? <LegendPopover /> : <LegendModal />}
        {isNotMobileResolution ? <Notifications /> : null}

        <Box>
          <UserGuide />
        </Box>

        <NavLink
          to="settings"
          onClick={(e) => {
            if (!isNotMobileResolution) {
              e.preventDefault();
            }
          }}
        >
          {({ isActive }) => (
            <HStack spacing={0}>
              <Tooltip label="Settings" placement="right" borderRadius="md">
                <IconButton
                  aria-label="Settings"
                  size="lg"
                  css={isActive ? SIDEBAR_MENU_BUTTON_ACTIVE_STYLE : undefined}
                  icon={<DMSettingsIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
                  variant="unstyled"
                  isDisabled={!isNotMobileResolution}
                />
              </Tooltip>
              <Show below="xl">
                <Text opacity={0.4}>Settings</Text>
              </Show>
            </HStack>
          )}
        </NavLink>
      </VStack>

      <VStack spacing={0}>
        <VStack spacing={3} alignItems="flex-start">
          <HStack spacing={0} onClick={onLogoutClick} cursor="pointer" pb={3}>
            <Tooltip label="Logout" placement="right" borderRadius="md">
              <IconButton
                aria-label="Logout"
                size="lg"
                icon={<DMLogoutIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
                variant="unstyled"
              />
            </Tooltip>
            <Show below="xl">
              <Text>Logout</Text>
            </Show>
          </HStack>
        </VStack>
        <VStack spacing={3} alignItems="flex-start">
          <Hide below="xl">
            <HStack justifyContent="center" w="full">
              <UserMenu />
            </HStack>
          </Hide>
        </VStack>
      </VStack>
    </VStack>
  );
};
