import React from 'react';
import { VStack } from '@chakra-ui/react';
import { UserProfileForm } from 'src/pages/settings/pages/user-management/components/UserProfileForm';

export const UserProfilePage = () => {
  return (
    <VStack alignItems="flex-start" spacing={4}>
      <UserProfileForm />
    </VStack>
  );
};
