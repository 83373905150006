import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/app/baseQuery';

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['AuthStatus'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getStatus: builder.query<any, any>({
      query: () => {
        return { url: `auth/mfa/status` };
      },
      providesTags: [{ type: 'AuthStatus' }],
    }),
    sendCode: builder.mutation<any, any>({
      query: (body) => {
        return { url: `auth/mfa/send_code`, method: 'POST', body: body };
      },
    }),
    validateCode: builder.mutation<any, any>({
      query: (body) => {
        return { url: `auth/mfa/validate_code`, method: 'POST', body: body };
      },
    }),
  }),
});

export const { useLazyGetStatusQuery, useSendCodeMutation, useValidateCodeMutation } = authApi;
